import {Injectable} from '@angular/core';
import {AppHttp} from './app-http.service';
import {Events} from './events.service';
import {Browser} from './browser.service';
import {NavCtrlService} from './nav-controller.service';
import {AppConfig} from './app-config.service';

/*
* 项目页面跳转配置
*
* 跳转分为3个类型：
*   1、跳转站内页面，直接跳转，不需要传额外参数
*   2、跳转外站（浏览器打开）
*   3、跳转站内页面，需要带参数
*
* */

@Injectable()
export class PageRouterService {
  constructor(public events: Events,
              public http: AppHttp,
              public browser: Browser,
              public appConfig: AppConfig, ) {
  }

  // 路由配置
  public routerConfig = {
    innerGo: {
      1: {title: '推荐-足球首页', stateGo: 'PlanListPage', param: {qiuFlag: 1}},
      41: {title: '推荐-篮球首页', stateGo: 'PlanListPage', param: {qiuFlag: 2}},

      4: {title: '推荐-足球单关', stateGo: 'PlanListPage', param: {type: 'dg', qiuFlag: 1}},
      2: {title: '推荐-足球亚盘', stateGo: 'PlanListPage', param: {type: 'yp', qiuFlag: 1}},
      3: {title: '推荐-足球大小球', stateGo: 'PlanListPage', param: {type: 'dxq', qiuFlag: 1}},
      69: {title: '推荐大厅-足球-2串1', stateGo: 'PlanListPage', param: {type: '2c1', qiuFlag: 1}},

      55: {title: '推荐-篮球胜负4.0.0', stateGo: 'PlanListPage', param: {type: 'sf', qiuFlag: 2}},
      56: {title: '推荐-篮球让分胜负4.0.0', stateGo: 'PlanListPage', param: {type: 'rfsf', qiuFlag: 2}},
      57: {title: '推荐-篮球胜分差4.0.0', stateGo: 'PlanListPage', param: {type: 'sfc', qiuFlag: 2}},
      58: {title: '推荐-篮球大小分4.0.0', stateGo: 'PlanListPage', param: {type: 'dxf', qiuFlag: 2}},
      70: {title: '推荐大厅-篮球-2串1-4.5.0', stateGo: 'PlanListPage', param: {type: '2c1', qiuFlag: 2}},

      6: {title: '实验室推荐-全部推荐-足球g', stateGo: 'ExpertLabPage', param: {qiuFlag: 1, type: 'list'}},
      42: {title: '实验室推荐-全部推荐-篮球g 3.1.0', stateGo: 'ExpertLabPage', param: {qiuFlag: 2, type: 'list'}},
      7: {title: '实验室推荐-准确率排行-足球g', stateGo: 'ExpertLabPage', param: {qiuFlag: 1, type: 'rank'}},
      43: {title: '实验室推荐-准确率排行-篮球g 3.1.0', stateGo: 'ExpertLabPage', param: {qiuFlag: 2, type: 'rank'}},

      11: {title: '名人专家-足球', stateGo: 'ExpertListTabPage', param: {qiuFlag: 1, tab: 'mr', type: '4'}},
      12: {title: '战绩红人-足球', stateGo: 'ExpertListTabPage', param: {qiuFlag: 1, tab: 'hr', type: '4'}},
      49: {title: '名人专家-篮球4.0.0', stateGo: 'ExpertListTabPage', param: {qiuFlag: 2, tab: 'mr', type: '4'}},
      50: {title: '战绩红人-篮球4.0.0', stateGo: 'ExpertListTabPage', param: {qiuFlag: 2, tab: 'hr', type: '4'}},

      14: {title: '比分即时-足球', stateGo: 'ZqMatchListPage'},
      44: {title: '比分即时-篮球 3.1.0', stateGo: 'BkMatchListPage'}, // 首页tab
      18: {title: '资料库-足球', stateGo: 'DataTabFtPage'},
      45: {title: '资料库-篮球 3.1.0', stateGo: 'DataTabBkPage'},


      35: {title: '充值', stateGo: 'ChargePage'},
      36: {title: '解读列表-全部', stateGo: 'JieduListPage', param: {tab: 'all'}},
      38: {title: '解读列表-竞彩足球', stateGo: 'JieduListPage', param: {tab: 'zq'}},
      48: {title: '解读列表-竞彩篮球g-3.1.0', stateGo: 'JieduListPage', param: {tab: 'lq'}},
      39: {title: '解读列表-胜负彩', stateGo: 'JieduListPage', param: {tab: 'sfc'}},
      40: {title: '解读列表-任9', stateGo: 'JieduListPage', param: {tab: 'r9'}},
      98: {title: '解读列表-北单', stateGo: 'JieduListPage', param: {tab: 'bd'}},

      60: {title: '消息频道-首页4.0.0', stateGo: 'MsgCenterPage', param: {}},
      61: {title: '消息频道-活rm 动专区4.0.0', stateGo: 'ActivityAreaPage'},
      62: {title: '消息频道-系统通知4.0.0', stateGo: 'MsgSystemPage'},
      63: {title: '消息频道-官方推荐4.0.0', stateGo: 'OfficialPlanPage'},
      64: {title: '消息频道-系统公告4.0.0', stateGo: 'MsgNoticePage'},

      85: {title: '历史同赔', stateGo: 'SameOddsPage'},
      86: {title: '数据-凯利指数', stateGo: 'KellyPage'},
      87: {title: '数据-离散指数', stateGo: 'DispersedPage'},
      88: {title: '数据-必发盈亏', stateGo: 'BetfairPage'},
      89: {title: '数据-投注比例', stateGo: 'ProportionPage'},
      95: {title: '专家套餐', stateGo: 'SetMealList'},
      96: {title: '特殊数据-凯利指数-篮球', stateGo: 'KellyBk'},
      97: {title: '特殊数据-离散指数-篮球', stateGo: 'DispersedBk'},
      99: {title: '免费专区-足球', stateGo: 'PlanSpecial', param: {type: '1'}},
      100: {title: '免费专区-篮球', stateGo: 'PlanSpecial', param: {type: '2'}},
      101: {title: '首页-直播', stateGo: 'HomeLive'},
        102: {title: '首页-会员-红单会员', stateGo: 'HomeVip',param:{type:3}},
        118: {title: '首页-会员-盈球会员', stateGo: 'HomeVip',param:{type:4}},
      103: {title: '会员-订购', stateGo: 'VipPurchase'},
      104: {title: '我的会员', stateGo: 'VipPage'},
      105: {title: '数据研究所列表', stateGo: 'SpecialDataIndexPage'},
      106: {title: '专家风风向标列表', stateGo: 'WindMarkListPage'},
      109: {title: 'vip体验活动页', stateGo: 'HdVipTry'},
      110: {title: '我的优惠券', stateGo: 'CouponListPage'},
      112: {title: '砍价活动详情页', stateGo: 'HdVipBargain'},
      113: {title: '申请合伙人', stateGo: 'ApplyProxyDistribution'},
      114: {title: '我的专家套餐',stateGo:'SetMealRecord'},
        115: {title: '包月订购页-足球', stateGo: 'ExpertPushIndexPage',param:{qiuFlag:1}},
        117: {title: '包月订购页-篮球', stateGo: 'ExpertPushIndexPage',param:{qiuFlag:2}},
        116: {title: '我的包月专家', stateGo: 'MyExpertMonth'},
        119: {title: '专家套餐-胜负彩', stateGo: 'SetMealList', param: {scope: 2}},
      120: {title: '添加客服微信指引页', stateGo: 'CustomerHelpPage', param: {from: 'ad'}},

    },
    responseGo: {
      3: {title: '跳响应推荐', stateGo: 'PlanDetailPage', paramName: 'id'},
      4: {title: '跳响应专家', stateGo: 'ExpertHomePage', paramName: 'expertId'},
        44: {title: '跳响应专家', stateGo: 'ExpertHomePage'},
      5: {title: '跳响应足球赛事详情', stateGo: 'MatchDetailFootball', paramName: 'fxId', param: {tab: 5}},
      14: {title: '跳响应篮球赛事详情-3.1.0', stateGo: 'BkMatchDetailPage', paramName: 'fxId'},
      6: {title: '跳响应帖子', stateGo: 'SnsPostDetailPage', paramName: 'id'},
      8: {title: '跳响应解读', stateGo: 'JieduDetailPage', paramName: 'id'},
      9: {title: '跳专题模板', stateGo: 'SpecialPage', paramName: 'id', params: 'param'},
      10: {title: '跳足球联赛页', stateGo: 'LeagueDataPage', paramName: 'leagueId'},
      15: {title: '跳篮球联赛页', stateGo: 'LeagueTabsPage', paramName: 'leagueId', param: {tab: '1'}},
      12: {title: '资讯详情页', stateGo: 'ExpertReplayPage', paramName: 'id'},

      20: {title: '资讯频道首页', stateGo: 'InformationPage', paramName: 'id'},
      13: {title: '资讯频道首页', stateGo: 'InformationPage', paramName: 'typeId'},
      21: {title: '盈利计划', stateGo: 'ProfitListPage'},

      66: {title: '我的粉丝', stateGo: 'MyFollowPage', param: {tab: 'me'}},
      40: {title: '跳优惠券', stateGo: 'CouponListPage'},
        43: {title: '专家包月订购页', stateGo: 'ExpertPushOrderPage', paramName: 'expertId'},
        //后端会返回一个json字符串，包含需要跳转的页面和参数，如 {\"page\":\"LeagueDataPage\",\"params\":{\"leagueId\":51,\"tab\":2}}
        46: {title: '自定义跳转', stateGo: '', paramName: ''},
    }
  };

  /**
   * 广告位配置的跳转
   * @param responseType  跳转类型  1跳无额外参数内页 2跳外部链接 其他为跳有参数内页
   * @param innerId   内页id
   * @param extra  额外带的参数  可能为对象，也可能为基本类型
   * @param params  额外带的参数  仅跳专题模板使用  页面需要显示header 标题
   */
  stateGo(navCtrl: NavCtrlService, responseType, innerId, extra, params?) {
    if (!responseType || responseType == 0) { return; }
    if (responseType == 1) {
      // 跳转内页
      if (!this.routerConfig.innerGo[innerId] || !this.routerConfig.innerGo[innerId].stateGo) { return; }
      const routerGo = this.routerConfig.innerGo[innerId];
      navCtrl.push(routerGo.stateGo, routerGo.param);
    } else if (responseType == 2) {
      // 浏览器打开
      const hasAgentId = extra.indexOf('agentId') >= 0;
      const hasQuestionMark = extra.indexOf('?') >= 0;
      if (!hasAgentId) {
        extra += hasQuestionMark ? '&' : '?';
        extra += 'agentId=' + this.appConfig.agentId;
      }
      location.href = extra;
    } else if(responseType == 46) {
        //自定义页面跳转，extra直接传的json串

        try{

            let json = JSON.parse(extra);

            let page = json.page;
            let params = json.params;

            page && navCtrl.push(page,params);

        }catch (e){
            console.log(e);
        }

    }else {
      // 跳有参数的内页
      if (!this.routerConfig.responseGo[responseType] || !this.routerConfig.responseGo[responseType].stateGo) { return; }
      let paramName = {};
      const routerGo = this.routerConfig.responseGo[responseType];
      if (routerGo.param) {
        paramName = routerGo.param;
        paramName[routerGo.paramName] = extra;
        if(params)
          paramName[routerGo.params] = JSON.stringify(params);
      } else if (routerGo.paramName) {
        paramName[routerGo.paramName] = extra;
        if(params){
            paramName[routerGo.params] = JSON.stringify(params);
        }
      } else if (extra) {
        paramName = JSON.parse(extra);
      }
      navCtrl.push(routerGo.stateGo, paramName);
    }
  }

  followSnsMsgGo(navCtrl: NavCtrlService, msgType, id) {
    if (msgType == 1) {
      // 帖子
      navCtrl.push('SnsPostDetailPage', {id});
    } else if (msgType == 6) {
      // 文章
      navCtrl.push('ExpertArticlePage', {id});
    } else if (msgType == 7) {
      // 解读
      navCtrl.push('JieduDetailPage', {id});
    } else if (msgType == 9) {
      // 话题
      navCtrl.push('SnsTopicDetailPage', {id});
    } else if (msgType == 10) {
      // 资讯
      navCtrl.push('ExpertReplayPage', {id});
    }
  }


}
