import {CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot} from "@angular/router";
import {Injectable} from "@angular/core";
import {AppConfig} from "./app-config.service";
import {LoginCacheService} from "../template/account/login/login-cache.service";

@Injectable()
export class AuthGuard implements CanActivate {


  constructor(
    private router: Router,
    public appConfig:AppConfig,
    public loginCache: LoginCacheService,
  ) {

  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this.loginCache.isLogin()) {
      return true;
    }
    /*if(state.url=='/user/center'){
      sessionStorage.setItem("wechatLoginBackUrl",window.location.protocol+'//'+window.location.hostname+"/#/user/center");
    }*/

    //如果有自动跳转app的标志，不限制登录
    if(route.queryParamMap.get("toApp")) {
      return true;
    }

    this.loginCache.goLoginPage(state.url);
    return false;
  }
}
