import {
    Component, ComponentFactory, ComponentFactoryResolver, Injector, ViewChild,
    ViewContainerRef
} from '@angular/core';
import {Router} from '@angular/router';
import {AlertService} from "../services/alert.service";
// import {lazy_load_prefix, lazyLoadRoutes} from "../app/lazy-modal-config.model";

@Component({
  selector:'view-model',
  template: `
    <style>
      .container{
        position:relative;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0);
      }
      .content{
        -webkit-animation-name: superScaleIn;
        animation-name: superScaleIn;
        -webkit-animation-duration: .2s;
        animation-duration: .2s;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
      }
      @keyframes superScaleIn{from{transform:scale(1.2);opacity:0}to{transform:scale(1);opacity:1}}
    </style>
    <div class="container content" >

      <ng-template #modelContainer></ng-template>


    </div>`,
  styles: [ ':host {height:100%;width:100%;z-index:1000;bottom:0;position:absolute }' ]
})
export class ViewModalComponent{

  @ViewChild("modelContainer", { read: ViewContainerRef, static:true }) container: ViewContainerRef;

  componentRef;   //实例

  get lazyLoadKey() {
    return this.alertService.lazyLoadKey;
  }

  constructor(public router: Router,
              public alertService:AlertService,
              private resolver: ComponentFactoryResolver,
              // private moduleLoader: NgModuleFactoryLoader,
              // private injector:Injector,
  ) {

  }

  ngOnInit(){

    let component = this.alertService.content;

/*    try {
      //先尝试直接创建组件
      this.createComponent(component);
    }catch (e) {
      console.log(e);
      //如果组件没有声明，则加载
      this.createLazyModule(component);
    }*/

    /*if(this.lazyLoadKey) {
      this.createLazyModule(component);
    }else {*/
      this.createComponent(component);
    // }

  }

  //加载延迟加载的模块
  /*createLazyModule(component) {

    //从配置中找到路径
    let rou = lazyLoadRoutes.find((route)=>{
      return route.path == lazy_load_prefix+this.lazyLoadKey;
    });

    if(!rou) {
      console.error("未找到对应的模块路径！");
      this.close();
      return;
    }

    let fa = this.moduleLoader.load(rou.loadChildren+'');

    fa.then((fact)=>{

      const factory = fact.create(this.injector);
      const comFactory = factory.componentFactoryResolver.resolveComponentFactory(component);
      this.componentRef = this.container.createComponent(comFactory);

    });

  }*/

  //生成组件
  createComponent(component) {

    this.container.clear();
    const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(component);

    this.componentRef = this.container.createComponent(factory);

  }

  ngOnDestroy() {
    this.componentRef && this.componentRef.destroy();
  }

  close(params?){
    this.alertService.hideModal2(params);
  }
}
