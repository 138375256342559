import {NgModule} from '@angular/core';
import {TxStatisticsDirective} from './tx-statistics.directive';

@NgModule({
    declarations: [
        TxStatisticsDirective,
    ],

    imports: [

    ],
    exports: [
        TxStatisticsDirective,
    ]
})

export class TxStatisticsModule {
}
