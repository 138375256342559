import {NgModule} from '@angular/core';
import {CaptchaValidateComponent} from "./captcha-validate.component";
import {CommonModule} from "@angular/common";
import {CaptchaValidateService} from "./captcha-validate.service";
import {CaptchaValidateDirective} from "./captcha-validate.directive";

@NgModule({
    declarations: [
        CaptchaValidateComponent,
        CaptchaValidateDirective,
    ],
    imports:[
        CommonModule,
    ],
    entryComponents: [
        CaptchaValidateComponent,
    ],
    providers:[
        CaptchaValidateService,
    ],
    exports:[
        CaptchaValidateDirective,
    ]
})
export class CaptchaValidateModule {
}
