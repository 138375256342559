import {Injectable} from '@angular/core';
import {AppHttp} from './app-http.service';
import {BaseType} from '../model/const-config.model';
import {StorageService} from './storage.service';
import {LoginCacheService} from '../template/account/login/login-cache.service';
import {tryCatch} from "rxjs/internal-compatibility";

/**
 * 页面资源配置，对应后端常量 com.aicai.jcob.client.common.constant.InnerResourceType
 */
export const InnerResourceType = {
    zlqtj: new BaseType(923155847,"足篮球推荐详情"),
    zlqjd: new BaseType(923174851,"足篮球解读详情"),
    zjjdlm: new BaseType(923175157,"专家解读/推荐栏目"),
    jsbf: new BaseType(923155608,"即时比分栏目"),
    sjgj: new BaseType(923155710,"数据工具栏目"),
    bsqb: new BaseType(923155729,"比赛情报(资讯)栏目"),
};

/**
 * 盈球数据统计service
 */
@Injectable()
export class JcobStatisticsService {

	private REPORT_BIZ_ID_CACHE_KEY = "REPORT_BIZ_ID_CACHE_KEY";

	private URL_REPORT_RULE = {
		//需要带bizId的
        [InnerResourceType.zlqtj.index]: [/^\/plan-detail\/\d{8}(\d+)/],
        [InnerResourceType.zlqjd.index]: [/^\/tjInterpretation\/(\d+)/],

        //不需要带bizId的
        [InnerResourceType.zjjdlm.index]: [/^\/tjInterpretation\/\d+/,/^\/plan-detail\/\d+/],
        [InnerResourceType.jsbf.index]: [/^\/match-detail/,/^\/bk-match-detail/,/^\/tabs\/match.*/],
        [InnerResourceType.sjgj.index]: [/^\/data.*/,/^\/special-data-index.*/,/^\/special-data-list.*/,/^\/wind-mark-lis;.*/,/^\/special\/.*/],
        [InnerResourceType.bsqb.index]: [/^\/expert-replay\/\d+/],
	};

	constructor(
		private appHttp: AppHttp,
        private localStorage: StorageService,
		private loginCache:LoginCacheService,
	) {

	}

    /**
	 * 通过路径匹配，自动进行上传
	 * 如果不能通过URL获取bizId，请在对应页面调用report()进行上传
	 * url 匹配规则 URL_REPORT_RULE上进行配置
     * @param url
     */
	public autoReportWithUrl(url) {
		if(!url) return;

		// console.log("匹配中。。。",url);
		for(const resourceId in this.URL_REPORT_RULE) {
			const regArr = this.URL_REPORT_RULE[resourceId];

			regArr && regArr.forEach((reg)=>{

				if(reg.test(url)) {
					// console.log("匹配到url:"+resourceId,reg);

					if(this.loginCache.isLogin()) {
                        //这里简单处理，带bizId的不重复上报
                        this.report(resourceId,RegExp.$1,!RegExp.$1);
					}
				}
			});
		}
	}

    /**
     * 自动进行上传页面path
     * 如果不能通过URL获取bizId，请在对应页面调用report()进行上传
     * @param url 页面地址path
     */
    public autoReportForPageUrl(pagePath,lastPageUrl?) {
        /*if (!pagePath) return;
        // console.log(pagePath,lastPageUrl);
        this.reportStatisticForUrl(pagePath,lastPageUrl).catch((res) => {
            console.log(res);
        });*/
    }


    /**
	 * 进行数据上报
     * @param resourceId 页面资源对应的id @link InnerResourceType
     * @param bizId 业务id
	 * * @param repeat 是否可以重复上报，使用bizId区分
     * @param {number} delay 延迟上报时间，如果在延迟时间之前取消，则不会上报  默认延迟1秒上报，是为了不影响主业务
     */
    private report(resourceId,bizId?,repeat=true,delay=1000) {

		// console.log("bizId",bizId);

		/*let handler = setTimeout(()=>{

			let canReport = true;

            let cache = this.localStorage.getObject(this.REPORT_BIZ_ID_CACHE_KEY) || {};
			//检查是否已经上报过
			if(!repeat && cache[bizId]){
				canReport =  false;
				console.log("当前数据上报过："+resourceId,bizId);
			}

			//进行上报
			canReport && this.reportStatistic(resourceId,bizId).catch((res)=>{console.log(res);});
            canReport && console.log("进行数据上报"+resourceId);

			//保存缓存
            if(bizId) {
                cache[bizId] = 1;
                this.localStorage.setObject(this.REPORT_BIZ_ID_CACHE_KEY, cache);
            }

		},delay);

		return {
			//如果延迟时间还没到，则可以取消上报
			cancel:()=>{
				clearTimeout(handler);
			}
		};*/
	}

    /**
	 * 数据上报接口
     * @param resourceId 页面资源对应的id @link InnerResourceType
     * @param bizId 业务id
     */
	private reportStatistic(resourceId,bizId?){
		return this.appHttp.get(`/api/statistic/report/${resourceId}`,{params:{bizId}});
	}

    /**
     * 数据上报接口
     * @param pagePath 页面对应的path
     * @param lastPageUrl 上个页面对应的path
     */
    private reportStatisticForUrl(pagePath:string,lastPageUrl?:string) {
        const devicePixel = window.devicePixelRatio || 1;
        const screenInfo = `${screen.availWidth || screen.width}*${screen.availHeight||screen.height}*${devicePixel}`;
        return this.appHttp.get(`/api/statistic/report/url`, {params: {pagePath,lastPageUrl,screenInfo}});
    }
    /**
     * 数据上报接口 启动上报
     */
    public reportStatisticForStart() {
        /*const devicePixel = window.devicePixelRatio || 1;
        const screenInfo = `${screen.availWidth || screen.width}*${screen.availHeight||screen.height}*${devicePixel}`;
        return this.appHttp.get(`/api/statistic/report/start`, {params: {screenInfo}});*/
    }

    /**
     * 数据上报接口
     * @param pagePath 页面对应的path
     * @param lastPageUrl 上个页面对应的path
     */
    public reportStatisticForClick(key:string,params?:object) {
    	/*let json = '';
    	if(params){
    		try{
    			json = JSON.stringify(params);
				JSON.parse(json);
			}catch{
    			console.error("上报参数发生异常key:"+key+"params:"+params);
    			if(window.location.href.indexOf("jcob-daily-v2.ttyingqiu.com")!=-1){
    				alert("上报参数发生异常key:"+key+"params:"+params);
				}
			}
		}
        const devicePixel = window.devicePixelRatio || 1;
        const screenInfo = `${screen.availWidth || screen.width}*${screen.availHeight||screen.height}*${devicePixel}`;
        return this.appHttp.post(`/api/statistic/report/click`, {key,'params':encodeURIComponent(json),screenInfo});*/
    }


}
