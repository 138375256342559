import {Injectable} from '@angular/core';
import {PageRouterService} from "../page-router.service";
import {BaseType} from "../../model/const-config.model";
import {StorageService} from "../storage.service";
import {SessionStorageService} from "../sessionStorage.service";
import {AppHttp} from "../app-http.service";
import {AppConfig} from "../app-config.service";

/**
 * 定义支付方式
 * 具体参考接口中常量类：ChargeWay
 */
export const CHARGE_WAY = {
    zfb_sdk:new BaseType(1,"支付宝sdk"),
    zfb_wap:new BaseType(2,"支付宝wap"),
    weixin_pay:new BaseType(4,"微信sdk"),
    lianlian_sdk_pay:new BaseType(5,"连连支付sdk"),
    lianlian_wap_pay:new BaseType(6,"连连支付wap"),
    bfb_wxgzh:new BaseType(41,"贝付宝微信公众号wap"),
    weibo_pay:new BaseType(34,"微博wap"),
    cdyzf_wx_wap:new BaseType(57,"易支付微信wap"),
};

//支付返回结果
export const PAY_STATUS = {
    success:0,          //支付成功
    pending:1,          //支付结果确认中
    error:2,          //支付失败
    cancel:3,          //用户取消支付
    redirect:4,          //跳转第三方h5进行支付
    noSupport:5,          //暂时不支持该（银行卡）支付
};

//支付结果封装
class PayResult{
    public status:number;      //支付状态 @link PAY_STATUS
    public msg:string;         //结果说明
    constructor(status?:number,msg?:string) {
        this.status = status || PAY_STATUS.success;
        this.msg = msg || '';
    }
}

/**
 * 调用sdk支付，或者h5支付
 * 所有直付订单都走这个服务，方便进行维护和扩展
 */

@Injectable()
export class CreatePaymentService {

    private cordova = (<any>window).cordova;

    //连连sdk
    private llPay = (<any>window).LLPay;

    //缓存key,用于缓存订单id，用户查询订单支付状态
    private PAY_STATUS_CHECK_ORDER_KEY = 'PAY_STATUS_CHECK_ORDER_KEY';

    constructor(
            private pagerRouter:PageRouterService,
            private localStorage: StorageService,
            private sessionSotrage: SessionStorageService,
            private http: AppHttp,
            private appConfig:AppConfig,
    ) {

    }
    /**
     * 进行支付
     * @param chargeWay 支付方式 @link：CHARGE_WAY
     * @param key 创建订单返回的支付key，如果是sdk则是sdk支付参数，h5则是支付网关
     * @param orderId 订单id，如果是h5支付，需要存储订单id，支付成功回来后使用订单id进行查询支付结果
     * @return Promise<PayResult>  {status:支付状态 msg:错误说明}
     *                  如果支付成功，rs()，支付失败，返回rj();
     */
    public directPay(chargeWay:number, key:string,orderId?):Promise<PayResult> {
        switch (chargeWay) {
            case CHARGE_WAY.zfb_wap.index:
                return this.h5pay(key,orderId);
            case CHARGE_WAY.bfb_wxgzh.index:
                return this.h5pay(key,orderId);
            case CHARGE_WAY.weibo_pay.index:
                return this.weiboH5pay(key,orderId);
            case CHARGE_WAY.cdyzf_wx_wap.index:
                return this.h5pay(key,orderId);
            default:
                //默认选择h5支付
                return this.h5pay(key,orderId);
        }
    }

    /**
     * 查询支付状态
     * @return {Promise<PayResult>}
     * 如果没有支付缓存，则返回空 promise.reject(null)，
     * 否则返回 promise.rs/rj(PayResult)，
     */
    public checkPayStatus():Promise<PayResult> {

        return new Promise((rs,rj)=>{

            let id = this.sessionSotrage.get(this.PAY_STATUS_CHECK_ORDER_KEY) || this.localStorage.get(this.PAY_STATUS_CHECK_ORDER_KEY);
            if(id) {
                this.checkPayStatusHttp(id).then((payStatus)=>{
                    //支付成功
                    if(payStatus == 2) {
                        rs(new PayResult())
                    }else {
                        rj(new PayResult(PAY_STATUS.pending,"支付确认中"));
                    }
                })
            }else {
                rj(null);
            }
        });
    }




    /**
     * h5支付
     * 这里可以做个优化：如果在app里面跳转h5支付，则可以通过scheme来判断是否支付成功，详细参考vip-purchase的h5支付代码
     * 有时间再实现这个
     * @param key
     * @param orderId
     * @return {Promise<PayResult>}
     */
    private h5pay(key,orderId?):Promise<PayResult> {

        //h5支付，添加一个订单缓存，方便回查订单支付状态
        this.setPayCache(orderId);

        return new Promise((rs,rj)=>{

            setTimeout(()=>{
                console.log("h5条跳转支付",key);
                this.pagerRouter.stateGo(null,2,null,key);
            });

            rj(new PayResult(PAY_STATUS.redirect,'支付跳转中...'));
        });


    }

    //微博h5支付
    private weiboH5pay(key,orderId?):Promise<PayResult> {

        this.setPayCache(orderId,2);

        return new Promise((rs,rj)=>{

            setTimeout(()=>{

                if(navigator.userAgent.indexOf('weibo') != -1 || navigator.userAgent.indexOf('Weibo') != -1){
                    window.location.replace("sinaweibo://browser?url=" + encodeURIComponent(key));
                }else {
                    this.pagerRouter.stateGo(null,2,null,key);
                }
            });
            rj(new PayResult(PAY_STATUS.redirect,'支付跳转中...'));
        });

    }

    //缓存订单支付id，用于订单状态查询
    //type 1使用sessionStorage缓存  2使用localStorage缓存（主要是有些浏览器跳支付后回来缓存会消失）
    private setPayCache(orderId,type=1) {

        if(!orderId) return ;

        if(type == 1) {
            this.sessionSotrage.set(this.PAY_STATUS_CHECK_ORDER_KEY,orderId);
        }else {
            this.localStorage.set(this.PAY_STATUS_CHECK_ORDER_KEY,orderId);
        }
    }

    //检查支付状态
    private checkPayStatusHttp(id) {
        return this.http.get('/api/check/pay/status',{params:{id}});
    }

}
