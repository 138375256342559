import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {SwipePhoto} from "./swipe-photo.model";
/**
 * Created by hh on 2019/9/19.
 */
@Injectable()
export class PhotoSwipeService {

    subject:Subject<any> = new Subject<any>();

    constructor() {

    }

    show(src:string,content?:SwipePhoto[]){
        this.subject.next({src,content});
    }

}