import {Injectable} from '@angular/core';
import {AppHttp} from "./app-http.service";
import {AppConfig} from "./app-config.service";
import MtaH5 from 'mta-h5-analysis';

/**
 * 腾讯数据统计service
 * @fixme 注意app和h5埋点有很大不同
 */

@Injectable()
export class TxStatisticsService {

	private config = {
		"sid": '500657569', //必填，统计用的appid
		"cid": '500657627', //如果开启自定义事件，此项目为必填，否则不填
		"autoReport": 0,//是否开启自动上报(1:init完成则上报一次,0:使用pgv方法才上报)
		"senseQuery": 1, //url参数是否进入url统计
		"senseHash": 1, //url参数是否进入url统计
		"performanceMonitor": 0,//是否开启性能监控
		"ignoreParams": [] //开启url参数上报时，可忽略部分参数拼接上报
	};

	constructor(
		public appHttp: AppHttp,
        public appConfig: AppConfig,
	) {

    this.mtaH5Init(this.config);
	}

	//腾讯数据分析sdk初始化
    private mtaH5Init(config) {

        if (!config || !config.sid) {
            config = this.config;
        }

        MtaH5.init(config);
        this.pgv();
    }

	/**
	 *用户点击统计+1
	 * @param eventId 事件id 需要在腾讯统计后台配置事件id
	 * @param params
	 */
	private clickStat(eventId,params?) {
        // console.log('点击上报--:',eventId,params);
         MtaH5.clickStat(eventId, params);
	}

	/**
	 * 页面上报
	 */
	public pgv() {
		MtaH5.pgv();
	}

    /**
     * 参数值处理
     * 添加 客户端类型和版本号
     * @param target 参数键，必须在腾讯数据统计后台，自定义事件home_click上添加参数target
     * @param value 参数值
     */
    private buildValue(value) {

        value += '';
        if(value && ~value.indexOf(this.appConfig.appVersion)) return value;
        return `${this.appConfig.platform}_${this.appConfig.fromPlatform}_${value}`;
    }


    /**
     *	5.9.0 新埋点统计
     * @param events 事件名
     * @param param 参数键，必须在腾讯数据统计后台，自定义事件home_click上添加参数target
     * @param value 参数值
     */
    public maidianClick(events, param, value = '0') {

        let obj = {};
        obj[param] = value;

        // console.log("~~~~~~~~~~~~~~~老的~~~~~~~~~~~~~~~~~~~");
         this.clickStat(events,obj);
    }

  /**
   * 漏斗模型统计
   * @param param 漏斗名称
   * @param events 事件id
   */
  public loudouClick(param,events){
    if (this.appConfig.isWechat||this.appConfig.isCNB) return;

    let obj = {};
    obj[events] = true;
    this.clickStat(param,obj);
    //console.log(this.config['cid']);
  }

	/**
	 *	5.9.0 新埋点统计
	 * @param events 事件名
	 * @param param 参数键，必须在腾讯数据统计后台，自定义事件home_click上添加参数键，才能统计到
	 */
	public mtaClick(events, param = {101:1}) {

		for(let key in param) {
			param[key] = this.buildValue(param[key]);
		}
		// console.log("~~~~~~~~~~~~~~~新的~~~~~~~~~~~~~~~~~~~");
		this.clickStat(events,param);
	}

}
