/**
 * Created by gyx on 2019/3/16
 * 在这个地方配置项目的常量
 */

/*常量类*/
export class BaseType {
    index:number;           //索引
    description:string;     //描述
    constructor(index:number,description:string) {
        this.index = index;
        this.description = description;
    }
}

export const ERROR_MSG = '网络异常,请稍后再试';

/**
 * 赛事类型常量
 */
export class RaceType  {
    static JJ_ZC = new BaseType(1,"足球");
    static JJ_LC = new BaseType(2,"篮球");
    static SF = new BaseType(3,"十四场");
    static SF_R9 = new BaseType(4,"任选9场");
    static BJDC = new BaseType(5,"单场");

    /**
     * 判断是否是足球球类
     * */
    static isFootball(index) {
        return RaceType.JJ_ZC.index == index || RaceType.BJDC.index == index ||  RaceType.SF_R9.index == index ||  RaceType.SF.index == index;
    }
    /**
     * 判断是否是篮球球类
     * */
    static isBasketball(index) {
        return RaceType.JJ_LC.index == index;
    }

}

/**
 * 新资料库足球赛事状态
 */
export const FOOTBALL_STATUS_DESC = {
    '0': '未开赛',
    '1': '比赛中',
    '11': '比赛中-上',
    '12': '比赛中-下',
    '13': '比赛中-加时',
    '14': '比赛中-中场',
    '2': '已完场',
    '3': '比赛中断',
    '7': '腰斩',
    '8': '推迟'
};

/**
 * 足球赛事状态
 */
class FootballRaceStatus {

    //是否比赛中
    static matching(status) {
        let statusArr = [1,11,12,13,14];
        return statusArr.indexOf(status) != -1;
    }
    //是否未开赛
    static beforeMatch(status) {
        return status == 0;
    }
    //是否 异常状态
    static matchCancel(status) {
        let statusArr = [3,7,8];
        return statusArr.indexOf(status) != -1;
    }
    //是否比赛完场
    static matchComplete(status) {
        return status == 2;
    }
    //中场休息
    static matchMidfield(status) {
        return status == 14;
    }
}

/**
 * 新资料库篮球赛事状态
 */
export const BASKETBALL_STATUS_DESC  = {
    '0':'未开赛',
    '1':'第一节',
    '2':'第二节',
    '3':'第三节',
    '4':'第四节',
    '5':'加时1',
    '6':'加时2',
    '7':'加时3',
    '8':'加时4',
    '9':'加时5',
    '10':'加时6',
    '11':'加时7',
    '12':'加时8',
    '50':'中场',
    '-1':'已完场',
    '-2':'待定',
    '-3':'中断',
    '-4':'取消',
    '-5':'推迟',
};

/**
 * 足球赛事状态
 */
class BasketballRaceStatus {

    //是否比赛中
    static matching(status) {

        if(typeof status == 'string')
            status = parseInt(status);
        return status >= 1;
    }
    //是否未开赛
    static beforeMatch(status) {
        return status == 0;
    }
    //是否比赛中断
    static matchCancel(status) {
        if(typeof status == 'string')
            status = parseInt(status);
        return status < -1;
    }
    //是否比赛完场
    static matchComplete(status) {
        return status == -1;
    }
    //中场休息
    static matchMidfield(status) {
        return status == 50;
    }
}

/**
 * 比赛状态判断
 */
export const RACE_STSTUS = {
    //比赛中
    matching: function (raceStatus,raceType?) {
        if(RaceType.isBasketball(raceType))
            return BasketballRaceStatus.matching(raceStatus);
        return FootballRaceStatus.matching(raceStatus);
    },
    //未开赛
    beforeMatch: function (raceStatus,raceType?) {
        if(RaceType.isBasketball(raceType))
            return BasketballRaceStatus.beforeMatch(raceStatus);
        return FootballRaceStatus.beforeMatch(raceStatus);
    },
    //比赛异常
    matchCancel: function (raceStatus,raceType?) {
        if(RaceType.isBasketball(raceType))
            return BasketballRaceStatus.matchCancel(raceStatus);
        return FootballRaceStatus.matchCancel(raceStatus);
    },
    //已完场
    matchComplete: function (raceStatus,raceType?) {
        if(RaceType.isBasketball(raceType))
            return BasketballRaceStatus.matchComplete(raceStatus);
        return FootballRaceStatus.matchComplete(raceStatus);
    },

    //比赛中场
    matchMidfield: function (raceStatus,raceType) {
        if(RaceType.isBasketball(raceType))
            return BasketballRaceStatus.matchMidfield(raceStatus);
        return FootballRaceStatus.matchMidfield(raceStatus);
    },
};

/**
 * 红单会员等级常量
 */
export const VIP_LEVEL = {
    no_vip: new BaseType(0,'没有会员/普通会员'),
    vip: new BaseType(1,'红单会员'),
    super_vip: new BaseType(2,'超级红单会员'),
    red_vip: new BaseType(3,'红单会员'),  //6.4.0新版红单会员 ，相当于弱版的超级红单会员
    data_vip: new BaseType(4,'盈球会员'),//6.4.0新版数据会员
    new_data_vip: new BaseType(6,'盈球会员'),//7.1.0新版盈球会员

    //根据索引获取会员介绍
    getDescByIndex: (index)=>{
      let suffix = '';
      // if(isTry)
      //   suffix = '(体验版)';

      switch (index) {
        case 1:return '红单会员'+suffix;
        case 2:return '超级红单会员'+suffix;
        case 3:return '红单会员'+suffix;
        case 4:return '盈球会员'+suffix;
        case 6:return '盈球会员'+suffix;
      }
      return '';
      // return index == 1 ? '红单会员'+suffix : '超级红单会员'+suffix;
    }
};
/**
 * 红单会员状态常量
 */
export const VIP_STATUS = {
    valid: new BaseType(0,'生效中'),
    valid_try: new BaseType(1,'试用中'),
    invalid: new BaseType(2,'已过期'),
};

/**
 * 会员权益价格信息
 */
export class VipWealPricePrConst {

    static winMarkPrice = 108;         //专家风向标价值
    static specialDataPrice = 188;     //创新指数价值
    static teamInfoPrice = 68;         //球队情报价值

    //会员总价格
    static getVipSumPrice(setMealPrice:number,couponPrice:number) {
        return VipWealPricePrConst.specialDataPrice + VipWealPricePrConst.teamInfoPrice + setMealPrice + couponPrice;
    }

    //超级会员总价格
    static getSuperVipSumPrice(setMealPrice:number,couponPrice:number) {
        return VipWealPricePrConst.winMarkPrice + VipWealPricePrConst.specialDataPrice + VipWealPricePrConst.teamInfoPrice + setMealPrice + couponPrice;
    }

    //6.4.0新版数据会员总价格
    static getDataVipSumPrice() {
      return VipWealPricePrConst.specialDataPrice + VipWealPricePrConst.teamInfoPrice + VipWealPricePrConst.winMarkPrice;
    }

}
/**
 * 会员权益数量信息
 */
export const VipWealNumberConst = {

    vipWealCount: 11,       //普通会员固定权益数量
    superVipWealCount: 13,  //超级会员固定权益数量
    dataVipWealCount:9,      //数据会员
    redVipWealCount:11,     //6.4.0红单会员权益数量

};
/**
 * 会员固定权益
 */
/*export const fixedWorth = {
    vipfixedWorth: 256,       //普通会员固定权益
    supVipfixedWorth: 364    //超级会员固定权益
};*/

// 6.6.0 专家实力榜--专家等级
export const EXPERT_STRENGTH_LEVEL = {
    GRAND_MASTER: new BaseType(1,'大宗师'),
    NINE_GRADE_TOP: new BaseType(2,'九品上'),
    NINE_GRADE_MIDDLE: new BaseType(3,'九品中'),
    NINE_GRADE_BOTTOM: new BaseType(4,'九品下'),
    EIGHT_GRADE_TOP: new BaseType(5,'八品上'),
    EIGHT_GRADE_MIDDLE: new BaseType(6,'八品中'),
    EIGHT_GRADE_BOTTOM: new BaseType(7,'八品下'),
    SEVEN_GRADE_TOP: new BaseType(8,'七品上'),
    SEVEN_GRADE_MIDDLE: new BaseType(9,'七品中'),
    SEVEN_GRADE_BOTTOM: new BaseType(10,'七品下')
};

// 6.6.0 专家实力榜--用户评分体系
export const USER_EVALUATION = {
    attitude: new BaseType(0,'专家态度'),
    quality: new BaseType(1,'内容质量'),
    result: new BaseType(2,'推荐结果'),
};

export const BaoYueSendStatus = {
    noBuy: new BaseType(0,"未订阅或未登录或未支付"),
    unsend: new BaseType(1,"未使用"),
    sendPart: new BaseType(2,"使用中"),
    sendAll: new BaseType(3,"已完成"),
};


//默认客服信息，应该从服务端或客服信息并覆盖
export const defaultPromotion:any = {
    tel:4001888082,
    email:'kf@aicai.com',
    wxNumber:'ttqy20',
    name:'瑶瑶',
    qrCode:'https://m.ttyingqiu.com/assets/img/h5-img/promotion/qr_code.jpg',
};
export const DATA_MODEL_INFO = {
    tongpei:new BaseType(1,'tongpei'),
    kaili:new BaseType(2,'kaili'),
    lisan:new BaseType(3,'lisan'),
    bifa:new BaseType(4,'bifa'),
    touzhubi:new BaseType(5,'touzhubi'),
    fengxiangbiao:new BaseType(6,'fengxiangbiao'),


    getDescByIndex(index){
        switch (Number(index)) {
            case 1:return DATA_MODEL_INFO.tongpei.description;
            case 2:return DATA_MODEL_INFO.kaili.description;
            case 3:return DATA_MODEL_INFO.lisan.description;
            case 4:return DATA_MODEL_INFO.bifa.description;
            case 5:return DATA_MODEL_INFO.touzhubi.description;
            case 6:return DATA_MODEL_INFO.fengxiangbiao.description;
        }
        return '';
    },
};