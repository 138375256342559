import {Injectable} from '@angular/core';

declare let ClipboardJS;

@Injectable()
 export class ClipboardService {

  //需要触发复制的元素
  private trigger;
  private content;
  private status = 1;   //复制状态 1成功 0失败

  constructor(
  ) {
    this.createCopyObjFromContent();
  }

  /**
   * 创建复制代码的对象
   */
  private createCopyObjFromContent() {

    let button = document.createElement("button");
    this.trigger = button;
    button.className = 'copyButton';
    document.body.appendChild(button);

    console.log("create copy");
    setTimeout(()=>{
      let clipboard = new ClipboardJS(".copyButton",{
        text:()=>{
          return this.content;
        }
      });

      clipboard.on("success",(e)=>{

        console.log("复制成功");
        this.status = 1;

      });

      clipboard.on("error",(e)=>{

        console.log("复制失败");
        this.status = 0;

      });

    },100);

  }

  /**
   * 复制到剪切板
   */
  public copyToClipborad(text) {

    return new Promise((rs,rj)=>{
      this.content = text;
      this.trigger.click();

      setTimeout(()=>{
        if(this.status) rs();
        else rj();
      },50);

    });


  }
  public copy(text) {
      return this.copyToClipborad(text);
  }

}
