/**
 * Created by hh on 2019/11/22.
 */
import {Component} from '@angular/core';
import {AppHttp} from "../../services/app-http.service";
import {EncryptService} from "../../services/encrypt.service";
import {AlertService} from "../../services/alert.service";

/**
 * 滑块验证组件
 *
 * 直接注入CaptchaValidateService服务，然后调用checkWithCaptcha();
 * 或者 引入 CaptchaValidateModule 模块，然后使用 captcha-check指令
 *
 */
enum MsgType {
    SUCCESS=1,
    ERROR,
    SIMPLE_SUCCESS,
    SIMPLE_ERROR
}
@Component({
    selector: 'captcha-validate',
    styleUrls: ['captcha-validate.component.scss'],
    templateUrl: 'captcha-validate.component.html',
})
export class CaptchaValidateComponent {

    // showCaptcha:boolean = false;

    // success:Function = undefined;

    // selfClose:Function = undefined;

    msg:string='';//相关信息显示
    msgType:MsgType;

    resetting:boolean = false;

    info:{d:number,x?:number} = {d:0};//滑块位置信息「d:移动距离 x:触摸起始点」

    captchaData:any = {//滑块初始数据「token:滑块uuid,tpl:拼图,map:背景图,Y:y轴位置」
        Y:undefined,
        token:undefined,
        tplImg:'',
        mapImg:''
    };


    constructor(public http:AppHttp,
                public encrypt:EncryptService,
                public alert:AlertService,
    ) {

    }

   /* init(){
        this.refresh();
        return new Promise((resolve,reject)=>{
            this.showCaptcha = true;
            this.success = ()=>{
                this.showCaptcha = false;
                resolve();
            };
            this.selfClose = ()=>{
                this.showCaptcha = false;
                reject();
            };
        })
    }*/

    ngOnInit(){
        this.refresh();
    }

    ngOnDestroy() {
        console.info('验证框被销毁');
    }

    refresh(){
        console.info('刷新验证码');
        this.msg = '';
        this.msgType = undefined;
        this.resetPosition();
        this.queryImgCheckCode();
    }

    resetPosition(){
        this.resetting = true;
        setTimeout(()=>{
            console.log("刷新");
            this.info = {d:0};
            this.resetting = false;
        },500)
    }

    _makeResult(){
        let {token,X,Y} = {
            token:this.captchaData.token,
            X:Math.floor(this.info.d)+'',
            Y:this.captchaData.Y
        };
        let input = X+'|'+token+'|'+Y;
        console.log(input);
        return this.encrypt.encrypt(input);
    }

    touchingBegin:boolean = false;
    touchstart(e){
        if(this.resetting){
            e.preventDefault();return;
        }
        // console.info(e);
        this.touchingBegin = true;
        let isMouseEvent = e.type.indexOf('mouse')>-1;
        let point = isMouseEvent?e:e.touches[0];
        this.info.x = Math.floor(point.pageX);
        console.log("开始移动",this.info.x);
    }

    touchend(e?){
        if(this.resetting || !this.info.x){
            e.preventDefault();return;
        }
        this.touchingBegin = false;
        // console.error('touchEnd');
        this.sendImgCheckCodeResult({
            result:this._makeResult()
        }).then((data:any)=>{//校验成功
            this.showMsg({
                msgType: MsgType.SIMPLE_SUCCESS,
                msg: data.msg,
                delay: 600,
                callback: () => {
                    this.showMsg({
                        msgType:MsgType.SUCCESS,
                        delay:500,
                        callback:()=>{
                            // if(this.success)this.success();
                            this.success();

                        }
                    })
                }
            });
        }).catch((data:any)=>{//校验失败
            if(data.errorMsg){
                console.error('校验失败：',data.errorMsg);
                this.errorCallback(data);
            }
        });
    }

    touchmove(e){
        e.preventDefault();
        if(this.resetting || !this.touchingBegin){
            return;
        }
        // console.info(e);
        let isMouseEvent = e.type.indexOf('mouse')>-1;
        let point = isMouseEvent?e:e.touches[0];
        let d = Math.floor(point.pageX) - this.info.x;
        if(d>=0 && d<=230 && this.info.d != d){
            this.info.d = d;
        }
    }

    showMsg({
                msgType=MsgType.SIMPLE_ERROR,
                msg,
                delay=1200,
                callback
            }:{
        msgType?:MsgType,
        msg?:string,
        delay?:number,
        callback?:Function
    }){
        this.msgType = msgType;
        this.msg = msg;
        setTimeout(()=>{
            if(msgType!=MsgType.ERROR){
                this.msg = '';
            }
            if(callback){
                callback();
            }
        },delay)
    }

    /*http请求*/
    //获取滑块验证码
    queryImgCheckCode(){
        return this.http.get("/api/validate/init").then((data:any)=>{
            this.captchaData = {
                Y:data.Y,
                token:data.token,
                tplImg:'data:image/png;base64,'+data.tplImg,
                mapImg:'data:image/jpeg;base64,'+data.mapImg
            };
            return data;
        }).catch((data:any)=>{
            if(data.errorMsg){
                this.errorCallback(data);
            }
        });
    }

    errorCallback(data) {

        if(!data || !data.errorMsg) return;

        if(data.errorCode&&data.errorCode=='need_refresh'){//发送报告重置缓存
            this.showMsg({
                msg:data.errorMsg,
                msgType:MsgType.ERROR,
                delay:1200,
                callback:()=>{
                    this.sendReport(data.msg).then(()=>{
                        // this.refresh();手动刷新
                    }).catch((data:any)=>{console.error(data)});
                }
            })
        }else{
            this.showMsg({
                msg:data.errorMsg,
                msgType:MsgType.SIMPLE_ERROR,
                delay:1200,
                callback:()=>{
                    this.resetPosition();
                }
            })
        }

    }

    //验证滑块验证码
    sendImgCheckCodeResult(data:{result:string}){
        console.info('提交：',data);
        return this.http.post("/api/validate/check",data);
    }
    //发送错误报告
    sendReport(error:string){
        return this.http.post("/api/validate/send",{
            time:new Date(),
            error:error
        })
    }

    success() {
        this.alert.hideModal2({success:1})
    }

    close(){
        console.info('关闭验证框');
        // if(this.selfClose)this.selfClose();

        this.alert.hideModal2();
    }
}
