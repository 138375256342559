import {AppHttp} from "../../../../services/app-http.service";
import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {AppConfig} from "../../../../services/app-config.service";
import {LoginCacheService} from "../../../account/login/login-cache.service";
import {StorageService} from "../../../../services/storage.service";
import {AlertService} from "../../../../services/alert.service";
import {RootService} from "../../../../services/root.service";
import {NavCtrlService} from "../../../../services/nav-controller.service";
import {SET_MEAL_SCOPE} from '../../../set-meal/set-meal-list';

/**
 * 优惠券相关服务
 * 1、推荐解读可用优惠价格计算
 * 2、新券和过期券统计和弹窗提醒
 */
@Injectable()
export class CouponService {

  public couponList = [];        //优惠券列表
  public setMealCount = 0;
  public countOfOldjc = 0;    //老足彩套餐数量
  public discountCardCount = 0;       //折扣卡数量
  public discountCardWorth = 10;
  public couponListRefreshSubject = new Subject();    //优惠券刷新监听

  public expiredCouponCount = 0;           //即将过期券的数量
  public newMarkCount = 0;           //新券数量

  //新券弹窗提醒
  private _newCouponConfirmCacheKey = 'new_coupon_confirm';
  get newCouponConfirmCacheKey() {

    let id = this.root.getUserInfo() && this.root.getUserInfo().id || 0;
    return this._newCouponConfirmCacheKey+'_'+id;
  }
  //新券角标提醒
  private _newCouponMarkCacheKey = 'new_coupon_mark';
  get newCouponMarkCacheKey() {

    let id = this.root.getUserInfo() && this.root.getUserInfo().id || 0;
    return this._newCouponMarkCacheKey+'_'+id;
  }

  private isLowConsume;       //是否是低消用户

  constructor(
    public http: AppHttp,
    private appConfig:AppConfig,
    private loginCache:LoginCacheService,
    public storage:StorageService,
    public alert:AlertService,
    public root: RootService,
    public navCtrl: NavCtrlService,
  ) {

    //在派券提醒调用时进行刷新了
    // this.refreshCouponList();

    this.loginCache.loginSuccessWatcher.subscribe(()=>{
      this.checkIsLowConssme();
    });
    this.loginCache.logoutSuccessWatcher.subscribe(()=>{
      this.refreshCouponList();
    });
    this.checkIsLowConssme();

  }

  /**
   * 显示新券提醒
   * @return promise.then():有弹窗  promise.catch()没有弹窗
   */
  public showNewCouponConfirm() {

    return new Promise((rs,rj)=>{

      //非主站/微博/微信没有优惠券
      if(!this.appConfig.showCoupon) {
        rs();
        return;
      }

      this.getCouponList().then((couponList)=>{

        if(couponList && couponList.length > 0) {

          //先过滤掉不可用的
          couponList = couponList.filter((value)=>{
            return this.checkClientType(value.onlyClientTypeArr);
          });

          let count = this.checkNewdCoupon(couponList);

          if(count) {
            //弹窗
            this.showCouponConfirm(count);
            rs();
          }
        }else {
          this.initNewCouponCache();
        }
        rj();
      }).catch(()=>{rj()});
    });

  }

  /**
   * 获取券后价
   * @param targetExpertId 需要对比的专家
   * @param targetAmount 需要对比的金额
   * @param targetLevel 需要对比的专家等级
   * @param scope 解读套餐类别 @link SET_MEAL_SCOPE
   * @return {priceType:1,price:0}     priceType：型(0没变化 1套餐 2免费券 3满减券 4折扣卡) price：价格
   */
  public getPriceAfterUseCoupon(targetExpertId?,targetAmount?,targetLevel?,scope?,gameId?,couponList?) {

    // console.log("检查是否可以用券");

    //不是低消用户
    if(!this.isLowConsume) return {priceType:0,price:targetAmount};

    //套餐查看 普通竞彩用套餐
    if(this.setMealCount != 0 && targetLevel == 4 && scope == SET_MEAL_SCOPE.jc.index) return {priceType:1,price:0};
      //套餐查看 胜负彩和14场用套餐
    if(this.countOfOldjc != 0 && targetLevel == 4 && scope == SET_MEAL_SCOPE.oldJc.index) return {priceType:1,price:0};

    /*let couponList = this.couponList.filter((value)=>{
      return this.checkCoupon(value,targetExpertId,targetAmount,targetLevel);
    });*/

    let coupon = couponList && couponList[0];

    let result = {priceType:0,price:targetAmount};
    if(coupon) {
      if(coupon.couponType == 1) {
        result = {priceType:2,price:0};
      }else {
        let price = targetAmount - coupon.worth;
        if(price <0) price = 0;
        result = {priceType:3,price:price};
      }
    }

    // 折扣卡查看
    if((!coupon || (coupon.couponType!=1)) && this.discountCardCount > 0 && targetAmount && targetAmount != 0){
      result = {priceType: 4, price: (targetAmount * this.discountCardWorth / 10)};
    }

    return result;
  }


  //刷新优惠券列表
  public refreshCouponList() {
    this.getCouponList().catch((e)=>{});
  }

  /**
   * 检查券是否可用
   * @param coupon 券对象
   * @param targetExpertId 需要对比的专家
   * @param targetAmount 需要对比的金额
   * @param targetLevel 需要对比的专家等级
   * @returns {any}
   */
  public checkCoupon(coupon,targetExpertId?,targetAmount?,targetLevel?) {
    //需要检查 客户端限制、激活/过期时间限制、券的专家等级限制、专家限制、券起用金额限制
    return  coupon
      && this.checkClientType(coupon.onlyClientTypeArr)
      && this.checkDate(coupon.validStartTime, coupon.validEndTime)
      && this.checkExpertLevel(targetLevel,coupon.onlyExpertLevel)
      && this.checkExpert(targetExpertId,coupon.onlyExpertId)
      && this.checkAmount(targetAmount,coupon.useCondition,coupon.couponType)
  }

  //对券进行排序 按免费券-满减券顺序排列，有多张免费券或满减券的，按过期时间顺序排列
  //充值券 按过期时间排序
  public sortList(list: any[]) {
    return list.sort((a, b) => {
      if (a.couponType == b.couponType)
        return a.validEndTime - b.validEndTime;
      return a.couponType - b.couponType;
    })
  }

  /**
   * 更新新券弹窗缓存时间
   */
  public upDateCouponConfirmCache() {
    //缓存当前时间
    this.storage.setObject(this.newCouponConfirmCacheKey,new Date().getTime())
  }
  /**
   * 更新新券角标提醒缓存时间
   */
  public upDateCouponMarkCache() {
    this.newMarkCount = 0;
    //缓存当前时间
    this.storage.setObject(this.newCouponMarkCacheKey,new Date().getTime())
  }

  private checkIsLowConssme() {
    if(!this.loginCache.isLogin()) {
      this.isLowConsume = false;
      return;
    }

    return this.getIsLowConsume().then((data)=>{

      this.isLowConsume = data.isLow;

    }).catch((res)=>{
      this.isLowConsume = false;

      if(res.errorCode == 'noLogin') {
        this.loginCache.clearLoginCache();
      }
    })
  }

  //获取优惠券列表 和 套餐次数
  private getCouponList() {
    if(!this.loginCache.isLogin()) {
      this.couponList = [];
      this.expiredCouponCount = 0;
      this.setMealCount = 0;
      this.countOfOldjc = 0;
      this.discountCardCount = 0;
      return Promise.reject([]);
    }

    let promise1 =  this.getCouponToUse(1).then((data) => {
      this.expiredCouponCount = 0;
      this.couponList = [];
      if (data.success) {

        if(data.list && data.list.length > 0) {

          // console.log(data.list);
          this.couponList = this.sortList(data.list.filter((value)=>{

            //计算过期券数量
            if (this.checkExpiredCoupon(value))
              this.expiredCouponCount++;

            return this.checkCoupon(value);
          }));
          // console.log(this.couponList);
          // console.log(this.expiredCouponCount);
        }
        return data.list;

      }
      return [];
    }).catch((res) => {
      this.couponList = [];
      this.expiredCouponCount = 0;
      return [];
    });

    //免费券次数
    let promise2 = this.getSetMealCount().then((data)=>{
      this.setMealCount = data.jcResult && data.jcResult.sum || 0;
      this.countOfOldjc = data.sfcResult && data.sfcResult.sum || 0;
      this.discountCardCount = data.discountCardNum || 0;
      this.discountCardWorth = data.discountCardWorth || 10;
    }).catch(()=>{
      this.setMealCount = 0;
      this.countOfOldjc = 0;
      this.discountCardCount = 0;
      this.discountCardWorth = 10;
    });


    //数据刷新事件发布
    Promise.all([promise1,promise2]).then(()=>{
      this.couponListRefreshSubject.next();
    });

    return promise1;
  }

  //判断券是否即将过期
  private checkExpiredCoupon(coupon) {
    return coupon.validEndTime < new Date().getTime() + 3 * 24 * 60 * 60 * 1000
  }

  //判断券是否后台新发券
  private checkNewdCoupon(couponList:any[]) {

    let confirmUpdateTime = this.storage.getObject(this.newCouponConfirmCacheKey);
    let markUpdateTime = this.storage.getObject(this.newCouponConfirmCacheKey);
    let confirmCount = 0, markCount = 0;      //新券个数

    //如果存过记录
    if(confirmUpdateTime && markUpdateTime) {

      couponList.forEach((value)=>{

        //只计算后台人工发放的
        if(value.originType == 1) {
          if(value.createTime > confirmUpdateTime)
            confirmCount ++;

          if(value.createTime > markUpdateTime)
            markCount ++;

        }
      });

      this.newMarkCount = markCount;

    }else {
      //如果没有存过记录
      this.upDateCouponConfirmCache();
      this.upDateCouponMarkCache();
    }

    return confirmCount;
  }

  //更新新券提示缓存
  initNewCouponCache() {
    let confirmUpdateTime = this.storage.getObject(this.newCouponConfirmCacheKey);
    let markUpdateTime = this.storage.getObject(this.newCouponConfirmCacheKey);

    if(!confirmUpdateTime)
      this.upDateCouponConfirmCache();
    if(!markUpdateTime)
      this.upDateCouponMarkCache();
  }


  /**
   * 检查客户端
   * @param {string[]} type 券的可使用客户端
   */
  private checkClientType(type: string[]): boolean {
    if (!type || type.length <= 0)
      return true;
    let currClient = this.appConfig.platform.toLowerCase();
    let index = type.findIndex((value) => {
      return value === currClient;
    });
    return index != -1;
  }

  /**
   * 检查过期时间
   * @param startDate 券的可使用起始时间
   * @param endDate 券的可使用过期时间
   */
  private checkDate(startDate, endDate) {

    let now = new Date().getTime();

    return now >= startDate && now <= endDate;
  }

  /**
   * 检查专家匹配
   * @param targetExpertId 需要对比的专家
   * @param expertId 券的可使用专家 0表示所有人
   */
  private checkExpert(targetExpertId,expertId) {
    //如果没有expertId,则直接返回true
    if (!targetExpertId || !expertId || expertId === 0)
      return true;
    return targetExpertId == expertId;
  }

  /**
   * 检查金额
   * @param targetAmount 需要判断的金额
   * * @param couponAmount 券的可使用金额
   */
  private checkAmount(targetAmount,couponAmount,type) {
    if (!targetAmount || !couponAmount || couponAmount === 0)
      return true;
    if(type==1){//全免券
     return  couponAmount >= targetAmount;
    }
    return couponAmount <= targetAmount;
  }


  /**
   * 检查券是否专家或红人专用
   * @param targetLevel 需要判断的等级
   * @param expertLevel 券的专家红人限制
   */
  private checkExpertLevel(targetLevel,expertLevel) {
    if (!targetLevel || !expertLevel || expertLevel === 0)
      return true;
    return targetLevel == expertLevel;
  }

  //显示弹窗
  private showCouponConfirm(count) {

    let content = `
                        <div class="wd_coupon_remind_pop">
                          <div class="close alert-close"></div>
                          <h3>恭喜!你被惊喜红包砸中,<br>开来看看吧!</h3>
                          <div class="price"><span>${count}</span>张优惠券</div>
                          <div class="btn alert-submit">立即查看</div>
                        </div>`;

    this.alert.show(content).then((data:any)=>{

      if(data.type == 'submit') {
       this.navCtrl.push("CouponListPage");
      }

      this.upDateCouponConfirmCache();
    }).catch();

  }


  /* 数据服务 */
  /**
   *获取可用的优惠券
   * couponType 1专家红人券 2充值赠送券
   * expertLevel 专家等级 2红人 4专家
   */
  getCouponToUse(couponType = 1) {
    return this.http.get('/api/query/coupon/list', {params: {couponType}});
  }

  getCouponToUseByPlan(couponType = 1,planId?,planType?) {
    return this.http.get('/api/query/coupon/list/byPlan', {params: {couponType,planId,planType}});
  }

  /**
   * 获取现有套餐次数
   * 套餐分层：使用新接口，查询竞彩和胜负彩套餐
   */
  getSetMealCount(expertId?) {
    return this.http.get('/api/setMeal/new/count', {expertId});
  }
  /**
   * 判断是否低消用户
   */
  getIsLowConsume() {
    return this.http.get('/api/consume/low');
  }

}
