import {Injectable} from '@angular/core';
import {AppHttp} from '../../../../../services/app-http.service';
import {StorageService} from '../../../../../services/storage.service';

@Injectable()
export class SettingService {
    public comfirm: boolean;

    public oddsCompayList = [];
    public bkOddsCompayList = [];
    public setting: any = {
        settingJson: {},
        bkSettingJson: {}
    };

    constructor(public http: AppHttp, public storage: StorageService) {


        this.reset();

    }

    initFbSetting() {
        this.setting.settingJson = {


            settingByTab: {
                '0': { // 全部
                    provider: {
                        description: '皇冠',
                        oddsCompany: 0
                    }, // 赔率公司
                    oddsType: 1, // 数据类型 1欧赔 2亚盘 3大小球
                },
                '407': { // 竞彩
                    provider: {
                        description: '竞彩官方',
                        oddsCompany: 6
                    }, // 赔率公司
                    oddsType: 1, // 数据类型 1欧赔 2亚盘 3大小球
                },
                '405': { // 北单
                    provider: {
                        description: '皇冠',
                        oddsCompany: 0
                    }, // 赔率公司
                    oddsType: 1, // 数据类型 1欧赔 2亚盘 3大小球
                },
                '401': { // 足彩
                    provider: {
                        description: '皇冠',
                        oddsCompany: 0
                    }, // 赔率公司
                    oddsType: 1, // 数据类型 1欧赔 2亚盘 3大小球
                },
            },
            goalTip: [1, 3],
            redCardTip: [3],
            changed: false,
            // stickFocusMatches: true, // 置顶关注赛事
            // tipForFocusMatchesOnly: true, // 是否只提示关注的赛事
            showYcard: true, // 比赛也是否显示黄牌
            showRank: true, // 比赛也是否显示排名
        };
        return this.setting.settingJson;
    }

    initBkSetting() {
        this.setting.bkSettingJson = {
            settingByTab: {
                '0': { // 全部
                    provider: {
                        description: '皇冠',
                        oddsCompany: 0
                    }, // 赔率公司
                    oddsType: 1, // 数据类型 1让分 2大小分 3胜负
                },
                '406': { // 竞彩
                    provider: {
                        description: '竞彩官方',
                        oddsCompany: 5
                    }, // 赔率公司
                    oddsType: 1, // 数据类型 1让分 2大小分 3胜负
                },
            },
            changed: false,
            // stickFocusMatches: true, // 置顶关注赛事
            showRank: true, // 比赛也是否显示排名
        };
        return this.setting.bkSettingJson;
    }

    reset() {
        let s = this.storage.get('match_list_setting');
        if (s) {
            this.setting.settingJson = JSON.parse(s).settingJson;
            this.setting.settingJson.settingByTab= {
                '0': {//全部
                    provider: {
                        description: '皇冠',
                        oddsCompany: 0
                    },//赔率公司
                    oddsType: 1,//数据类型 1欧赔 2亚盘 3大小球
                },
                '407': {//竞彩
                    provider: {
                        description: '竞彩官方',
                        oddsCompany: 6
                    },//赔率公司
                    oddsType: 1,//数据类型 1欧赔 2亚盘 3大小球
                },
                '405': {//北单
                    provider: {
                        description: '皇冠',
                        oddsCompany: 0
                    },//赔率公司
                    oddsType: 1,//数据类型 1欧赔 2亚盘 3大小球
                },
                '401': {//足彩
                    provider: {
                        description: '皇冠',
                        oddsCompany: 0
                    },//赔率公司
                    oddsType: 1,//数据类型 1欧赔 2亚盘 3大小球
                },
            };
            this.setting.bkSettingJson = JSON.parse(s).bkSettingJson;
        } else {
            this.initFbSetting();
            this.initBkSetting();
        }

    }

    getSetting() {

    }

    saveDefaultSetting() {
        let str: string = JSON.stringify(this.setting);
        this.storage.set('match_list_setting', str);
    }
}
