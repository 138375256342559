import {Component} from '@angular/core';

import {CookieService} from '../services/cookie.service';
import {AppConfig} from '../services/app-config.service';
import {ActivatedRouteSnapshot, ActivationEnd, ActivationStart, NavigationEnd, NavigationStart, Router} from '@angular/router';

import {Events} from '../services/events.service';
import {AlertService} from '../services/alert.service';
import {RootService} from '../services/root.service';
import {NavCtrlService} from '../services/nav-controller.service';
import {UserCenterService} from '../template/user-center/shared/user-center.service';
import {Title} from '@angular/platform-browser';
import {SessionStorageService} from '../services/sessionStorage.service';
import {ClientPlatform} from '../services/client-platform.service';
import {LoginCacheService} from '../template/account/login/login-cache.service';
import {ThirdLoginService} from '../template/account/login/third-login.service';
import {StorageService} from '../services/storage.service';
import {UUID} from 'angular2-uuid';
import {AppStartConfirmService} from '../services/appStartConfirm.service';
import {JcobUtilService} from '../services/jcobUtil.service';
import {ShareService} from '../services/share.service';
import {AppHttp} from '../services/app-http.service';
import {ResourceLoadErrService} from '../services/resourceLoadErr.service';
import {JcobStatisticsService} from '../services/jcobStatistics.service';
import {AppConstConfigService} from '../services/app-const-config.service';

// declare var Jcob: any;
declare  var JWeixin:any;
declare  var WxShare:any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {

  showMsg:boolean = false;
  showAlert:boolean=false;
  alertSubscribe;

  constructor(
      private router: Router,
      public appConfig: AppConfig ,
      private rootService:RootService,
      public session: SessionStorageService,
      private cookieService: CookieService,
      public events:Events,
      public navCtrl:NavCtrlService,
      public alertService:AlertService,
      public userCenterService:UserCenterService,
      public titleService:Title,
      public platform: ClientPlatform,
      public loginCache: LoginCacheService,
      public thirdLogin: ThirdLoginService,
      public storageService:StorageService,
      public appStartConfirm: AppStartConfirmService,
      public jcob:JcobUtilService,
      public shareService:ShareService,
      public errorService:ResourceLoadErrService,
      public http: AppHttp,
      public jcobStatistics: JcobStatisticsService,
      public constConfig: AppConstConfigService,
  ) {



    //默认历史最前面为首页
    /*if(
        self.history.pushState
        && self.history.length <= 1                                     //新打开的页面才需要
        && !/.*\.ttyingqiu.com\/#\/tabs\/home.*!/i.test(self.location.href)    //首页不用
        && !this.session.get("first_page")                              //第一次进来才要
        &&this.platform.platform != 'jcob'                              //盈球客户端里不用
        &&this.platform.platform != 'xiaodian'                              //小店里不用
        &&this.cookieService.get("thirdType")!="xiaodian"                              //小店里不用
        &&!this.platform.isMina()                                       //小程序你不用
        &&navigator.userAgent.indexOf('wxwork')==-1               //企业微信不用
    ) {
      this.session.set("first_page","true");
      let currentUrl = location.href;
      self.history.replaceState({}, null, "/#/tabs/home");
      self.history.pushState({}, null, currentUrl);
    }*/

    this.checkSysTime();

    //Jcob全局变量配置
    this.jcob.initCache();

    if(this.appConfig.isTq) {
      //uuid初始化
      this.uuidInit();
    }
    //初始化会话id
    this.initJcobSessionId();
    //渠道号初始化
    this.initAgentId();

    //初始化app信息 app名字，公司名字等
      //注意首先要初始化agendId
    this.rootService.initAppInfo();

    this.appConfig.reinitPlatformWithAgentId();

    //检查是否来自分享链接
    this.checkShare();

    //初始化rootScope
    this.initRootScope();

    //初始化token
    this.initToken();

    if(this.appConfig.isTq){
      this.initTqInfo();
    }
    

    //测试cookie
    let ck = this.rootService.getUrlParams('ck');
    if(ck){
        this.cookieService.put(this.rootService.getUrlParams('ckKey'), this.rootService.getUrlParams('ckVal'));
    }


      this.constConfig.initConstConfig();

    this.jcobStatistics.reportStatisticForStart();

    //微信自动登录
    /* if(this.appConfig.isWechat&&!this.userCenterService.isLogin()){
     JWeixin.wechatLogin(this.appConfig.agentId,this.appConfig.platform,this.appConfig.version,window.location.href);
     }*/
    //登录页面跳转 事件监听
    this.events.subscribe("openLogin",()=>{
      // this.navCtrl.push("LoginPage",{backUrl:location.href});
      this.loginCache.goLoginPage(location.href);

    });

    //如果不是盈球平台 项目自动登录 微博平台也不需要启动时登录
    if(!this.appConfig.isJcob&&!this.appConfig.isWechat) {
      if(this.appConfig.isTq&&!this.rootService.getUrlParams("uid"))return;
      this.thirdLogin.thirdLoginAfterInit();
    }

    if(this.userCenterService.isLogin()){
      this.userCenterService.index();
    }

    //弹窗逻辑
    this.asyncShowAlert();

    //添加资源加载错误监控
    this.errorService.addErrorListen();

  }

  //弹窗
  private async asyncShowAlert() {
    let showAlert:boolean = false;

    //显示微信关注弹窗
    /*if (this.appConfig.isWechat) {
      //注册微信内下载按钮功能，不造成堵塞
      this.events.subscribe("showWechatAttention", () => {
        //alert
        this.appStartConfirm.showWechatAttentionThenAdPop();
      });

      if (this.userCenterService.isLogin()) {
        //每24小时弹一次
        let time = this.storageService.getObject("wechatAttentionTime") || 0;
        let flag = this.storageService.get("wechatAttentionFlag");
        if (!flag && (!time || (new Date().getTime() - time > (24 * 60 * 60 * 1000)))) {
          //alert
          this.storageService.setObject("wechatAttentionTime", new Date().getTime());
          showAlert = await this.appStartConfirm.showWechatAttentionThenAdPop();
        } else {
          //alert
          showAlert = await this.appStartConfirm.showAdPop();
        }
      }
    }
    //本站 微博 检查启动弹窗
    if (this.appConfig.isPopup) {
      //alert
      showAlert = await this.appStartConfirm.showAdPop();
    }


    //如果已经弹出上面的广告/微信的窗，不再进行下面的弹窗
    if (this.userCenterService.isLogin()&&!showAlert) {
      return this.appStartConfirm.showAppStartConfirm();
    }*/
  }

  public ngOnInit(): void {
    if(this.cookieService.get("chargeBackToHome")&&!this.appConfig.isXd){
      this.cookieService.delCookie("chargeBackToHome");
      setTimeout(()=>{
        history.pushState({},"","/#/home");
        history.pushState({},"","/#/home");
      },30)
    }

    /*第三方登陆后,再次进入页面，设置相关用户信息*/
    if(this.userCenterService.isLogin()){
      this.userCenterService.index();
    }

    //如果在京东浏览器里面，直接执行京东登陆
    /*if(this.appConfig.isJD) {
     setTimeout(()=>{
     console.log("!!!!!!启动执行登陆");
     this.jdService.jdLogin();
     },100);
     }*/

    this.iphoneFit();

    this.registerRouteEvents();

      //全局弹窗相关
      this.alertSubscribe = this.alertService.subject.subscribe((data: any) => {
          /*if (data == 'loading') {
              setTimeout(()=> {
                  this.showLoading = true;
              })
          }
          if (data == 'hideLoading') {
              setTimeout(()=> {
                  this.showLoading = false;
              })
          }*/
          if (data == 'showMsg') {
              this.showMsg = true;
          }
          if (data == 'hideMsg') {
              this.showMsg = false;
          }
          if (data == 'showAlert') {
              this.showAlert = true;
          }
          if (data == 'hideAlert') {
              this.showAlert = false;
          }
      });
  }

  //注册页面跳转事件
  registerRouteEvents() {

      let popState = false;
    //路由缓存页面 自动滚动到历史位置
    this.router.events.subscribe((event) => {

      if (event instanceof NavigationStart) {

          this.titleService.setTitle(decodeURIComponent(this.rootService.appNameToShow) || "体坛周报");

        //切换页面时，如果显示了modal，则关闭它
        if(this.alertService.modalInstance) {
          this.alertService.hideModal();
        }

        //判断是否回退的
          popState = event.navigationTrigger == 'popstate';

      }

      if(event instanceof ActivationStart) {

        /* ===== 将页面 ionViewDidLoad 方法 放在 ionViewDidEnter（ios）或 ionViewWillEnter（android） 之后触发 ===== strat*/
        //之所以这样，主要是ios上，在ngOnInt方法触发数据加载时（主要是ngIf），会导致加载动画有问题

        let proxyFunName = 'ionViewWillEnter';
        // let proxyFunName = 'ngOnInit';       //用ngOnInit 好像不行

        //获取页面类
        let componentClass: any = event.snapshot.component;

        //如果类不存在 或者已经处理过了 或者 没有定义 ionViewDidLoad 方法
        if (!componentClass || componentClass.prototype.viewDidLoad != undefined || componentClass.prototype.ionViewDidLoad == undefined) return;

        //获取页面定义的 ionViewDidEnter 方法
        let proxyFun = componentClass.prototype[proxyFunName];

        //定义新的 ionViewDidEnter 方法
        let proxyFunNew =function () {

          proxyFun && proxyFun.apply(this);
          !this.viewDidLoad && this.ionViewDidLoad && this.ionViewDidLoad();
          this.viewDidLoad = true;
        };
        //添加一个属性，用于防止 load 重复触发
        componentClass.prototype.viewDidLoad = false;
        //覆盖原来的方法
        componentClass.prototype[proxyFunName] = proxyFunNew;

        /* ===== 将页面 ionViewDidLoad 方法 放在 ionViewDidEnter（ios）或 ionViewWillEnter（android） 之后触发 ====== end*/
      }

      if (event instanceof NavigationEnd) {

          const pageUrl = event.urlAfterRedirects || event.url;

          setTimeout(()=>{
              //数据上报，回退时不上报
              if (!popState) this.jcobStatistics.autoReportWithUrl(pageUrl);
          },100);

        if(typeof WxShare != 'undefined') {
          WxShare.initShare(0, '');
        }
      }

      //新加需要上报页面原始的url（不带参数），所以在这个事件处理
        if (event instanceof ActivationEnd) {

          const routeConfig = event.snapshot.routeConfig;

          //只取最底层的url，然后从parent开始遍历path，组装完整path
            if(routeConfig && !routeConfig.loadChildren && (!routeConfig.children || routeConfig.children.length <= 0)) {

                const pageUrl = this.router.url,snapshot = event.snapshot;

                setTimeout(()=>{
                    //数据上报，回退时不上报
                    if (!popState) this.jcobStatistics.autoReportForPageUrl(pageUrl,this.buildRouteUrl(snapshot));
                },1000);

            }
        }
    });

  }

    /**
     * 根据routeConfig构建页面原始url
     * @param {ActivatedRouteSnapshot} route
     */
  buildRouteUrl(route:ActivatedRouteSnapshot) {

      if(!route) return '';

        //有父路由，递归获取path
      if(route.parent)  {
          if(route.routeConfig && route.routeConfig.path)
            return this.buildRouteUrl(route.parent) + '/' + route.routeConfig.path;
          return this.buildRouteUrl(route.parent);
      }

      return '';
  }

  //初始化渠道号
  initAgentId() {
    // 渠道号优先从url拿，拿不到了去cooike拿，再拿不到就用默认的
    let agentId:any = this.rootService.getUrlParams('agentId') || this.cookieService.get('agentId') || this.session.get("jcob_agent_id");
    if (!agentId) {
      if(this.appConfig.isWechat){
        // let source = this.getMobileOperatingSystem();
        if(this.platform.isIOS){
          agentId='2335088';
        }else{
          agentId='2335089';
        }
      }

      if(this.appConfig.isTtHw){
          agentId='2335376';
      }
      if(this.appConfig.isVivoYdzx){
          agentId = "2335109";
      }
    }



    if (agentId && agentId != "undefined") {
      this.appConfig.agentId = agentId;
    }else {
      agentId = this.appConfig.agentId;
    }

    // 存到cookie和localStorage
    this.cookieService.put('agentId', agentId);
    this.session.set("jcob_agent_id",agentId);
  }

  //初始化会话级id,大数据统计使用
  initJcobSessionId() {
      // 优先从url拿，拿不到了去cooike拿，再拿不到就用默认的
      let jcobSessionId:any = this.rootService.getUrlParams('jcobSessionId') || this.cookieService.get('jcobSessionId');

      if (!jcobSessionId) {
          //没有就生成一个
          jcobSessionId = UUID.UUID();
      }
      this.appConfig.jcobSessionId = jcobSessionId;

      // 存到cookie和localStorage
      this.cookieService.put('jcobSessionId', jcobSessionId);
  }

  //初始化uuid
  uuidInit(){
    let uuid = this.cookieService.get('device_uuid');
    if(!uuid){
      uuid = UUID.UUID();
      this.cookieService.put('device_uuid',uuid);
    }
  }

  /**
   * 初始化rootScope
   */
  initRootScope() {

    let $rootScope = this.rootService.getRoot();
    $rootScope.userInfo = {};

    //初始化$rootScope
    $rootScope.appInfo = {
      apiHost: this.appConfig.apiHost,
      agentId: this.appConfig.agentId,
      token: '',
      platform: this.appConfig.platform,
      version: '',
      uuid: '',
      appVersion: this.appConfig.appVersion,
      // siteType:appUpdateService.getSiteType(agentId)//站点类别,默认本站
    };
    $rootScope.isCanLotteryBuy = false;
    $rootScope.existMsg = false;
    $rootScope.tickMessageTimer = 60000;

    //白名单信息获取
    if (!$rootScope.userInfo || !$rootScope.userInfo.lotteryWhiteUser) {
      $rootScope.userInfo = this.jcob.userInfoCache.loadAll();
    }

    $rootScope.openBrowser=function(url){
      (<any>window).open(url);
    };

  }

  /**
   * 初始化登录信息 token
   */
  initToken() {

    // 拿token放到localstroage,
    let token = this.cookieService.get("jcob_token");

    if(token) {
      // this.storageService.setObject("jcob_token",token);
      this.loginCache.addLoginCache(token);
    }else {
      this.loginCache.clearLoginCache();
      this.loginCache.clearUserCache();
      // this.storageService.remove("jcob_token");
    }
  }


  //初始化推球平台信息
  initTqInfo(){
    /*if(!this.rootService.getUrlParams("uid")){
     this.cookieService.delCookie("tq_uid");
     this.cookieService.get("jcob_token")&&this.userCenterService.logout().catch(()=>{});
     this.loginCache.clearLoginCache();         //清除登录缓存
     }*/
    this.cookieService.put("tq_show_url",location.href);

    let cv = this.rootService.getUrlParams("cv");
    let ct = this.rootService.getUrlParams("ct");
    let appName = this.rootService.getUrlParams("appName");
    if(!!cv){
      this.storageService.set("tq_cv",cv);
      this.cookieService.put("tq_cv",cv);
    }else{
      this.cookieService.put("tq_cv",this.storageService.get("tq_cv"));
    }
    if(!!ct){
      this.storageService.set("tq_ct",ct);
      this.cookieService.put("tq_ct",ct);
    }else{
      this.cookieService.put("tq_ct",this.storageService.get("tq_ct"));
    }

    if(!!appName){
      this.cookieService.put("tq_name",appName);
    }

  }

  ngOnDestroy(){
  }

  /**
   * 苹果全面屏手机适配
   */
  iphoneFit() {

    if(this.platform.isIOS && (
            this.platform.platform == 'jingdong'
            || this.platform.platform == 'weibo'
            || this.platform.platform == 'qq'
            || this.appConfig.isTq
            || this.platform.platform == 'jcob'
        )) {
      document.documentElement.classList.add("iphone_fit");
    }

  }

  //检查是否来自分享链接
  checkShare() {

    let share = this.rootService.getUrlParams("shareId") || this.session.get("register_share_id");
    let shareInnfoId = this.rootService.getUrlParams("shareInfoId") || this.session.get("shareInfoId");

    //分享人id
    if(share && share != 'undefined') {
      this.appConfig.share = share;
      this.session.set("register_share_id",share);
    }

    //分享记录id
    if (shareInnfoId && shareInnfoId != 'undefined'){
      let from = 4;
      if (this.appConfig.isWechat||this.appConfig.isCNB){
        from = 1;
      }else if (this.appConfig.isQQ){
        from = 2;
      }else if (this.appConfig.isWB){
        from = 5;
      }
      this.shareService.shareStatistics(shareInnfoId,from).then((data)=>{
        console.log(data)
      })
    }
  }

  //修正本地时间
  checkSysTime() {
    let timeExc:any = this.storageService.get("s");
    if(timeExc && !isNaN(timeExc)) {
      this.appConfig.sysTimeExc = +timeExc;
    }
  }


}
