import {Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {NavController} from '@ionic/angular';

export const routerConfig: any = {

    /*第一级页面 tabs*/

    //首页
    HomePage: {routerLink: 'home',isTab: 1},
    HomeDiscovery: {routerLink: 'home',isTab: 1},
    // 足球赛事列表页
    ZqMatchListPage: {routerLink: 'match',isTab: 1},
    // 篮球赛事列表
    BkMatchListPage: {routerLink: 'match-bk',isTab: 1},
    //专家频道
    HomePlan: {routerLink: 'plan',isTab: 1},
    /*资讯*/
    // InformationPage: {routerLink: 'news', isTab: 1},
    //用户中心
    UserCenterPage: {routerLink: 'user',isTab: 1},
    JdUserCenterPage: {routerLink: '/jd/user-center',isTab: 1},

    /*直播*/
    HomeLive: {routerLink: '/home/live'},
    /*首页搜索*/
    HomeSearchPage:{routerLink:'/home-search/searchPage'},
    SearchContentPage:{routerLink:'/home-search/content',routerParams:{words:undefined}},


    /*用户中心相关*/
    //登录页
    LoginPage: {routerLink: '/login'},
    //注册页
    RegisterPage: {routerLink: '/register'},
    //找回密码
    FindPasswordPage: {routerLink: '/retrieve'},
    //提款详情
    DrawDetailPage: {routerLink: '/userDrawMoneyDetail/{id}'},
    //提款页
    DrawMoneyPage: {routerLink: '/userDrawMoney'},
    //金币详情页
    JinbiDetailPage: {routerLink: '/userHuoyanDetail'},
    //金币流水页
    MoneyDetailPage: {routerLink: '/userMoneyDetail'},
    //充值页
    ChargePage: {routerLink: '/pay/userChargeHuoyan'},
    ChargeFinishPage: {routerLink: '/userChargeFinish'},
    //用户资料页
    UserInfoPage: {routerLink: '/user/info'},
    //修改昵称
    NickNameChangePage: {routerLink: '/user/nickname-change'},
    //修改密码
    PasswordChangePage: {routerLink: '/user/password-change'},
    //修改签名
    SignatureChangePage: {routerLink: '/user/signature-change'},
    //修改手机
    PhoneOperationPage: {routerLink: '/user/phone-operation'},
    //实名认证
    UserInfoCompletePage: {routerLink: '/user/user-info-complete'},
    //设置
    MySettingsPage: {routerLink: '/user/settings'},
    //关于我们
    AboutUsPage: {routerLink: '/user/about-us'},
    //服务中心
    ServiceCenterPage: {routerLink: '/public/serviceCenter'},
    //ios服务中心
    ServiceCenterIos: {routerLink: '/public/iosServiceCenter'},
    /*我的卡券*/
    CouponListPage: {routerLink: '/user/coupon-list', routerParams: {back: false}},
    CouponRulePage: {routerLink: '/user/coupon-rule'},



    /*推荐解读相关页*/
    // 发布推荐页 请使用 [addPlan]指令 具体参数参考 @link ToPublishPlan}
    addPlan: {},
    /*套餐订购记录*/
    SetMealRecord: {routerLink: '/set-meal-record',routerParams: {scope: 1}},
    /*套餐列表*/
    SetMealList: {routerLink: '/set-meal-list', routerParams: {scope: 1}},
    /*专题模板页*/
    SpecialPage: {routerLink: '/special-home', routerParams: {params: undefined}},
    /*免费专题页*/
    PlanSpecial: {routerLink: '/special-home/free', routerParams: {type: 1}},
    //专家红人主页
    ExpertHomePage: {routerLink: '/public/expertHome/{expertId}'},
    //分享
    ExpertHomeSharePage: {routerLink: '/public/expertHomeShare/{expertId}'},
    //专家文章
    ExpertArticlePage: {routerLink: '/expert-article/{id}'}, // 编辑文章
    ExpertArticleFeedbackPage: {routerLink: '/expert-article/feedback/{id}', routerParams: {/*id:'xxx',*/jcobMemberId: 'xxx', expertId: 'xxx'}},
    ExpertReplayPage: {routerLink: '/expert-replay/{id}'}, // 专家文章
    // 我的留言
    ExpertNote: {routerLink: '/expert-note'},
    CommentDetailPage: {routerLink: '/expert-replay/comment-detail/{id}'},
    // 专家搜索
    ExpertSearchPage: {routerLink: '/expert-search', routerParams: {type: ''}},
    // 联赛记录
    LeagueRecordPage: {routerLink: '/leagueRecord/{expertId}/{gameType}'},
    LeagueRecordDetailPage: {routerLink: '/leagueRecordDetail/{option}'},
    // 联赛分布
    ExpertLeagueDistributionPage: {routerLink: 'public/distribution'}, //
    ExpertLeagueJieDuPage:{routerLink:'/public/expertJdByLeague'},
    /*专家包月订购 主页*/
    ExpertPushIndexPage:{routerLink:'/expert-push/index',routerParams: {qiuFlag:1}},
    PushProtocolPage:{routerLink:'/public/push-protocol'},
    /*专家包月订购 购买页*/
    ExpertPushOrderPage:{routerLink:'/expert-push/order/{expertId}',routerParams: {noAlert:false}},
    /*推荐*/
    PlanDetailPage: {routerLink: '/plan/{id}'},
    PlanDetailPayChargePage: {routerLink: '/plan/pay-charge', routerParams: {price: undefined, planNo: undefined, ableBalance: undefined}},
    PlanDetailPayOkPage: {routerLink: '/plan/pay-ok', routerParams: {ableBalance: undefined}},
    PlanDetailPayErrorPage: {routerLink: '/plan/pay-error', routerParams: {errorMsg: undefined, planNo: undefined}},
    PlanDetailFeedbackPage: {routerLink: '/plan/feedback', routerParams: {id: undefined, type: undefined}},
    PlanLookerPage: {routerLink: '/plan/look-list/{planId}'},
    /*已查看的推荐*/
    MyLookPlanPage: {routerLink: '/my-look-plan/{tab}', routerParams: {tab: 'all'}},
    /*已发布的推荐*/
    MyPostPlanPage: {routerLink: '/my-post-plan/{expertId}/{tab}', routerParams: {tab: 'all'}},
    //推荐列表
    PlanListPage: {routerLink: '/plan-list/{qiuFlag}/{type}', routerParams: {qiuFlag: '1', type: 'all'}},
    /*解读*/
    JieduDetailPage: {routerLink: '/tjInterpretation/{id}', routerParams: {id: undefined}},
    JieduDetailPayChargePage: {routerLink: '/tjInterpretation/pay-charge', routerParams: {price: undefined, jieduId: undefined, ableBalance: undefined}},
    JieduDetailPayOkPage: {routerLink: '/tjInterpretation/pay-ok', routerParams: {ableBalance: undefined}},
    JieduDetailPayErrorPage: {routerLink: '/tjInterpretation/pay-error', routerParams: {errorMsg: undefined, jieduId: undefined}},
    JieduDetailFeedbackPage: {routerLink: '/tjInterpretation/feedback', routerParams: {id: undefined, jcobMemberId: undefined}},
    //已查看的解读
    MyLookJieduPage: {routerLink: '/my-look-jiedu/{tab}', routerParams: {tab: 'all'}},
    //已发布的解读
    MyPostJieduPage: {routerLink: '/my-post-jiedu/{tab}', routerParams: {tab: 'all'}},
    //解读列表
    JieduListPage: {routerLink: '/jiedu-list', routerParams: {type: 0}},
    NewJieduListPage: {routerLink: '/jiedu-list', routerParams: {type: 0}},
    /*罗珩-关注和粉丝*/
    HisFollowPage: {routerLink: 'hisAttention/{expertId}/{tab}', routerParams: {tab: 'me', expertId: undefined}},
    MyFollowPage: {routerLink: 'myAttention/{tab}', routerParams: {tab: 'other-all'}},
    /*推荐实验室*/
    ExpertLabPage: {routerLink: '/expert/lab/{qiuFlag}/{type}'},
    /*专家红人排行榜*/
    ExpertListTabPage: {routerLink: '/expert/{tab}/{type}/{qiuFlag}'},
    /*申请红人*/
    ExpertApplyPage: {routerLink: '/expert-apply'},


    /*赛事/资料库相关页面*/
    // 赛事详情页
    MatchDetailFootball: {routerLink: '/match-detail/ft/fit'},
    // 篮球赛事详情页  参数tab
    BkMatchDetailPage: {routerLink: '/bk-match-detail/bk/fit'},
    //资料库首页
    DataIndexPage: {routerLink: '/data'},
    DataTabFtPage:{routerLink:'/data'},
    DataTabBkPage:{routerLink:'/data/bk/index'},
    //搜索
    SearchPage:{routerLink:'/data/search-page',routerParams:{qiuFlag:1}},
    //联赛详情  篮球
    leagueBkDataPage:{routerLink:'/data/bk/league/{leagueId}',routerParams:{sessonId:null,tab:null}},
    LeagueTabsPage:{routerLink:'/data/bk/league/{leagueId}',routerParams:{sessonId:null,tab:null}},
    //球队详情页
    TeamDataPage: {routerLink: '/data/team-data', routerParams: {leagueId: '', from: '', teamId: '', nameCnShort: '', teamLogo: '', seasons: '', seasonName: '', seasonId: ''}},
    TeamsTabsPage:{routerLink:'/data/bk/league/team/{tab}',routerParams: {tab: 0}},
    //球员详情页
    PlayerInfoPage: {routerLink: '/data/player-info', routerParams: {playerId: '', seasonId: '', leagueId: ''}},
    //联赛列表页
    LeagueListPage: {routerLink: '/data/league-list', routerParams: {countryId: '', countryName: ''}},
    // 联赛详情页 tab值(number)：LeagueDetailTabsEnum  必须参数:leagueId
    LeagueDataPage: {routerLink: '/data/league-data/{leagueId}', routerParams: {seasonId: null, isFromAicai: null, showRule: null}},
    StatisticsDetailPage: {routerLink: '/data/statistics-detail', routerParams: {leagueName: '', leagueId: '', type: '', seasons: '', seasonFlag: '', leagueSeasonName: '', }},
    PlayersDetailPage: {routerLink: '/data/players-detail', routerParams: {leagueName: '', leagueId: '', type: '', seasons: '', seasonId: ''}},
    CountryListPage: {routerLink: '/data/country-list', routerParams: {region: '', areaList:'',ballType:''}},


    /*特殊数据*/
    // 篮球凯利列表页
    KellyBk: {routerLink: '/bk-kelly/list'},
    // 篮球凯利详情页
    KellyDetailBk: {routerLink: '/bk-kelly/detail/{matchId}'},
    // 凯利介绍页
    KellyIntroduce: {routerLink: '/bk-kelly/introduce/{raceType}'},
    // 篮球离散列表页
    DispersedBk: {routerLink: '/bk-dispersed/list'},
    // 离散介绍页
    DispersedIntroduce: {routerLink: '/bk-dispersed/introduce/{raceType}'},
    // 同赔指数介绍页
    SameOddsIntroduce: {routerLink: '/same-odds-introduce'},
    // 数据研究所
    SpecialDataIndexPage: {routerLink: '/special-data-index'},
    // 数据研究所  -  内页-列表页
    SpecialDataListPage: {routerLink: '/special-data-list', routerParams: {type: undefined}},
    BetfairPage: {routerLink: '/special/betfair'},
    BetfairDetailPage: {routerLink: '/special/betfair-detail'},
    DispersedPage: {routerLink: '/special/dispersed'},
    DispersedChangePage: {routerLink: '/special/dispersed-change'},
    KellyPage: {routerLink: '/special/kelly'},
    KellyDetailPage: {routerLink: '/special/kelly-detail'},
    KellyChangePage: {routerLink: '/special/kelly-change'},
    ProportionPage: {routerLink: '/special/proportion'},
    SameOddsDetailPage: {routerLink: '/special/same-odds-detail'},
    ProportionDetailPage: {routerLink: '/special/proportion-detail'},

    /*消息中心*/
    MsgCenterPage: {routerLink: '/message/center'},
    ActivityAreaPage: {routerLink: '/message/activity'},
    MsgCommunityPage: {routerLink: '/message/community'},
    MsgLetterPage: {routerLink: '/message/letter'},
    MsgNoticePage: {routerLink: '/message/notice'},
    MsgSettingPage: {routerLink: '/message/setting'},
    MsgSystemPage: {routerLink: '/message/system'},
    OfficialPlanPage: {routerLink: '/message/official-plan'},
    SnsMessagePage: {routerLink: '/message/message'},

    /*vip相关*/
    /*我的会员*/
    VipPage: {routerLink: '/user/vip'},
    /*vip体验活动页面*/
    HdVipTry: {routerLink: '/hd/vip/try'},
    VipOrderListPage: {routerLink: '/user/vip/order', routerParams: {vipMemberId: ''}},
    VipGiftListPage: {routerLink: '/user/vip/gift', routerParams: {vipMemberId: ''}},
    GiftDetailPage: {routerLink: '/user/vip/giftDetail', routerParams: {giftPackgeId: ''}},
    /*风向标列表*/
    WindMarkListPage: {routerLink: '/wind-mark-lis', routerParams: {qiuFlag: '' || 1}},
    VipExclusiveData: {routerLink: '/wind-mark-lis/vip-exclusive-data/{matchId}'},
    ProbabilityChange: {routerLink: '/wind-mark-lis/probability-change/{windMarkId}'},
    /* vip购买页*/
    VipPurchase: {routerLink: '/vip/buy', routerParams: {type: 2}},
    /*vip 聚合页*/
    HomeVip: {routerLink: '/vip/index',routerParams: {type: 3}},
    VipChannel: {routerLink: '/vip/index'},
    //vip砍价
    VipBargain: {routerLink: '/hd/vipBargain/apply'},
    /*我包月的专家*/
    MyExpertMonth:{routerLink: '/my-expert-month'},

    /*海报分享*/
    ShareBkMatchPage: {routerLink: '/share-page/bkMatch'},
    ShareFtMatchPage: {routerLink: '/share-page/ftMatch'},
    ShareInformationPage: {routerLink: '/share-page/information'},

    /*活动页面*/
    HdSpree1188:{routerLink: '/hd/spree1188'},
    hd218Page:{routerLink: '/hd/1812'},
    HdEigthVipPage:{routerLink: 'hd/eightVip'},

    FeedbackListPage: {routerLink: '/user/feedback-list'},
    "CustomerHelpPage": {routerLink: '/user/customer-help',routerParams: {from: undefined}},
    BrokeNewsList: {routerLink: '/broke-news'},
    XdHome:{routerLink:'/xd-home'},
};

@Injectable()
export class NavCtrlService {

    public tabsPrefix = 'tabs';
    public lastTab = routerConfig.HomePage.routerLink;        //记录上一个tab页面

    constructor(public router: Router,
                public navCtrl: NavController,
                public ngZone: NgZone,
    ) {}

    /**
     * 页面跳转
     * @param {string} page 页面地址，@see routerConfig
     * @param params 参数
     * @param {boolean} replace 是否直接替换页面（路由栈中直接替换）
     * @param {boolean} animated 是否需要动画
     * @return {Promise<any>}
     */
    push(page: string, params?: any, replace= false, animated= true) {

        console.log('route navigate',page,params);

        const route = routerConfig[page];
        if(!route || !route.routerLink) return Promise.reject({msg:'route有误'});

        let routerLink = route.routerLink;

        //tab处理
        if(route.isTab) routerLink = this.tabsPrefix + '/' + routerLink;

        let paramsCopy = null;
        let newLink = routerLink;

        // 有参数的情况
        if (params || route.routerParams) {

            paramsCopy = Object.assign({},route.routerParams, params);

            //url中带参数的情况
            if (routerLink.indexOf('{') != -1) {
                try {

                    routerLink.match(/\{([a-zA-Z]+)\}/g).map((value) => {
                        return {
                            key: value,
                            wrapKey: value.substring(1, value.length - 1)
                        };
                    }).forEach(c => {

                        newLink = newLink.replace(c.key, paramsCopy[c.wrapKey]);
                        delete paramsCopy[c.wrapKey];
                    });

                } catch (e) {
                    console.error("路由跳转出错",e);
                    return Promise.reject({msg:'解析routeLink出错'});
                }
            }
        }

        let routeUrl =  [newLink];
        if (paramsCopy && Object.keys(paramsCopy).length > 0) {
            routeUrl.push(this.clearNullValue(paramsCopy));
        }

        return this.navigateForwardWithZone(routeUrl, {replaceUrl: replace, animated},route.isTab).catch((data)=>{
            console.error(data);
            return Promise.reject(data);
        });
    }

    /**
     * 在ngZone中进行跳转 主要是变更监测问题
     * @param url
     * @param opts
     * @param root 跳转root
     */
    public navigateForwardWithZone(url,opts,root) {

        return this.ngZone.run(()=>{
            if(root) {
                return this.navCtrl.navigateRoot(url,opts);
            }else {
                return this.navCtrl.navigateForward(url,opts);
            }
        });
    }

    pop(animate:boolean=false) {
        if(animate){
            this.navCtrl.back();
            return;
        }
          this.navCtrl.back({
              animated: false,
              animationDirection: 'back'
          });
    }

    /**
     * 回到首页
     * h5，这里之所以不调用events，是因为h5 tabs首页evens不一定被监听了
     * @param page 页面地址id @link routerConfig
     * @param params 传递的参数
     */
    popToRoot(page?,params?){
        if (!page) {
            const routerLink = this.tabsPrefix + '/' + this.lastTab;
            this.navigateForwardWithZone(routerLink,params,1);
        }else {
            this.push(page,params);
        }
    }

    clearNullValue(obj) {

        if(!obj) return;

        let  newObj = {};
        for (let key in obj) {

            if(obj[key] && obj[key] != 'undefined' || obj[key] == 0) {
                newObj[key] = obj[key];
            }
        }
        return newObj;
    }
}
