import {Injectable} from "@angular/core";
import {Html2canvasService} from "../../../services/html2canvas.service";
import {AlertService} from "../../../services/alert.service";
import {AppHttp} from "../../../services/app-http.service";
/*
* 保存图片，分享图片
*/
@Injectable()
export class SharePageService {

    constructor(
                public html2canvas: Html2canvasService,
                public alertService: AlertService,
                public http: AppHttp,
                ) {

    }

    //生成图片
    produceImg(element, loading=true){
        loading && this.alertService.loading();
        return new Promise((rs,rj)=>{
            this.html2canvas.html2canvas(element).then((img)=> {

                this.alertService.hideLoading();

                setTimeout(()=>{
                    rs(img);
                },50)

            }).catch((res)=>{

                console.log(res);
                this.alertService.hideLoading();
                setTimeout(()=>{
                    this.alertService.msg("生成图片失败，请重试");
                    rj()
                },100);
            })
        });
    }

    /**
     * 将远程url图片装换成base64
     * 如果转换失败 会返回默认图片url
     * @param url
     * @return {Promise<any>}
     */
    getBase64Url(url){

        return new Promise((rs,rj)=>{

            if(url.indexOf(";base64,") != -1) {
                rs(url);
            }else {
                this.http.post('/api/proxy/transform/url',{url}).then((data)=>{
                    // console.log(data);
                    if(data.isSuccess) {

                        //清空换行符/回车符
                        let base64 = (data.base64 || '').replace(/[\n\r]/g,'');
                        // console.log(base64);
                        rs(base64);

                    }else {
                        rj('assets/img/user_img.png');
                    }

                }).catch((res)=>{
                    console.log(res);
                    rj('assets/img/user_img.png');
                })
            }

        })

    }

    expertIconToBase64(interList) {
        interList.forEach((item)=>{
            if (item.jcobMember&&item.jcobMember.icon){
                let url = item.jcobMember.icon;
                this.getBase64Url(url).then((base64:string)=>{
                    item.jcobMember.icon = base64;
                }).catch((res)=>{
                    item.jcobMember.icon = 'assets/img/user_img.png';
                });
            }
        })
    }

    goApp(){
        this.alertService.show(`
           <div class="wd_bargain_confirm">
              <div class="close alert-close"></div>
              <div class="txt pt50">
                <p></p>
                <p>活动太火爆啦！</p>
                <p>到盈球App里参与更流畅哦~</p>
              </div>
              <div class="btn border bdt bdc_eee">
                <a class="active alert-close">我知道了</a>
                <a class="red alert-submit">下载APP</a>
              </div>
           </div>`).then((data:any)=>{
            if (data.type == 'submit'){
                location.href = "https://m.ttyingqiu.com/page/download/h5_ttyq.html?agentId=2335037";
            }
        })
    }

    getShareUserInfo(){
        return new Promise((rs,rj)=>{
            this.http.get("/api/proxy/apply/userShareInfo").then((data)=>{
                if (data.isSuccess) {
                    rs(data)
                }
            }).catch((err)=>{
                rj(err);
            })
        })
    }
}
