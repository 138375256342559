import {Injectable} from '@angular/core';
import {AppHttp} from "../../../services/app-http.service";
import {SportdataHttp} from "../../../services/sportdata-http.service";


@Injectable()
export class BtDataService {

  private _leagueDetailCache = {};             //联赛信息缓存,包括赛季信息

  private videoAndCartoonMap = new Map();     //某比赛是否有动画和视频 缓存容器

  constructor(public http: AppHttp, public dataHttp: SportdataHttp) {
    String.prototype.trim = function () {
      return this.replace(/(^\s*)|(\s*$)/g, "");
    }
  }


  //判断是否有视频或动画
 /* hasVideo(matchId) {
    return this.videoAndCartoonMap[matchId] && this.videoAndCartoonMap[matchId].video;
  }*/

  //判断是否有视频或动画
  /*hasCartoon(matchId) {
    return this.videoAndCartoonMap[matchId] && this.videoAndCartoonMap[matchId].cartoon;
  }*/

  //获取对应联赛的详情信息
  getLeagueDetailCache(leagueId) {
    if (this._leagueDetailCache[leagueId])
      return this._leagueDetailCache[leagueId];
    return null;
  }

  //添加对应联赛的详情信息到缓存
  setLeagueDetailCache(leagueId, object) {
    this._leagueDetailCache[leagueId] = object;
    return object;
  }

  //清空联赛缓存
  clearLeagueDetailCache() {
    this._leagueDetailCache = {};
  }

  //获取动画直播，视频直播信息
  getVideoAndCartoonInfo(matchList: Array<any>, type) {

    if (!matchList || matchList.length <= 0)
      return Promise.reject(null);

    let ids = [];
    matchList.forEach((v) => {
      //只取缓存没有的的比赛
      if (v.matchId && typeof this.videoAndCartoonMap[v.matchId] == 'undefined') {
        ids.push(v.matchId);
      }
    });
    if (ids.length <= 0)
      return Promise.resolve(null);

    let callBakc = (data) => {
      if(data.code == '1'&&data.data) {
        for(let name in data.data){

          let temp = data.data[name];

          if(temp) {

            let key = this.getMediaKey(name,type);

            let map = {
              is_video: temp.is_video,
              is_cartoon: temp.is_cartoon,
            };

            this.videoAndCartoonMap.set(key,map);
          }
        }
      }
      return data;
    };

    if (type == 1)
      return this.getFtVideoAndCartoonInfo(ids).then(callBakc).catch((err) => {
        return err;
      });
    return this.getBkVideoAndCartoonInfo(ids).then(callBakc).catch((err) => {
      return err;
    });
  }

  //今日赛事
  getTodayGroupLeagueMatchList() {
    return this.http.sportDataPost('/sportdata/f', {"apiName": "getBkTodayGroupLeagueMatchList"});
  }

  //热门赛事-简单列表
  getBkHotLeagueList() {
    return this.http.sportDataPost('/sportdata/f', {"apiName": "getBkHotLeagueList"});
  }

  //获取赛事国家列表
  getBkCountryList(code) {
    return this.http.sportDataPost('/sportdata/f', {
      "apiName": "getBkCountryList",
      "areaCode": code,
      "pageNo": 1,
      "pageSize": 1000
    });
  }

  //获取联赛信息
  getBkLeagueList(code, pageNo, countryId?, level = 1, order = undefined ,pageSize = 30) {
    return this.http.sportDataPost('/sportdata/f', {
      "apiName": "getBkLeagueList",
      "areaCode": code,
      "countryId": countryId,
      level,
      "pageNo": pageNo,
      pageSize,
      "order":order,
    });
  }

  //获取赛季信息/联赛详情
  getBkLeagueDetailMessage(leagueId) {
/*    if (this.getLeagueDetailCache(leagueId))
      return new Promise((rs, rj) => {
        console.log("form cache");
        rs(this.getLeagueDetailCache(leagueId));
      });*/

    return this.http.sportDataPost('/sportdata/f', {
      "apiName": "getBkLeagueDetailMessage",
      leagueId
    }).then((data) => {
      if (data.code == 1) {
        this.setLeagueDetailCache(leagueId, data);        //缓存
      }
      return data;
    }).catch((res) => {
      return res;
    });

  }
  //获取球队信息
  getBkTeamInfo(teamId){
    return this.http.sportDataPost('/sportdata/f',{
      "apiName":'getBkTeamDetailMessage',
      "teamId":teamId
    })
  }

  //查询赛季信息
  getFtLeagueSeasons(leagueId){

    let key = leagueId + '1';       //添加一个后缀 防止和篮球重复

    if (this.getLeagueDetailCache(key))
      return new Promise((rs, rj) => {
        // console.log("form cache");
        rs(this.getLeagueDetailCache(key));
      });

    return this.http.sportDataPost('/sportdata/f', {
      "apiName": "queryLeagueSeasons",
      leagueId
    }).then((data) => {
      if (data.code == 1) {
        this.setLeagueDetailCache(key, data);        //缓存
      }
      return data;
    }).catch((res) => {
      return res;
    });

  }


  //获取赛制信息
  getLeagueRule(leagueId) {
    return this.http.sportDataPost('/sportdata/f', {"apiName": "getLeagueRule", leagueId});
  }

  //热门赛事-联赛首页
  getBkLeagueHotMatchList(leagueId, seasonName) {
    return this.http.sportDataPost('/sportdata/f', {"apiName": "getBkLeagueHotMatchList", leagueId, seasonName});
  }

  //根据id获取热门页赛事聚合信息  篮球
  getHomeBkMatchInfo(qtMatchIds){
    return this.http.sportDataPost('/sportdata/f',{"apiName":"getBkMatchJuheInfoByIdList",qtMatchIds});
  }

  //根据id获取热门页赛事聚合信息  足球
  getHomeFtMatchInfo(qtMatchIds){
    return this.http.sportDataPost('/sportdata/f',{"apiName":"getMatchJuheInfoByIdList",qtMatchIds});
  }

  //获取赛程列表-联赛首页
  /**
   *
   * @param upOrDown  赛程查询上一天或下一天时传
   */
  getBkLeagueMatchList(leagueId, seasonName,date,first,upOrDown, round?, group?, pageNo = 1, pageSize = 20) {
    return this.http.sportDataPost('/sportdata/f', {
      "apiName": "getBkLeagueMatchList",
      leagueId,
      seasonName,
      round,
      group,
      pageNo,
      pageSize,
      date,
      first,
      upOrDown,
    });
    //return this.http.sportDataPost('/sportdata/f',{"apiName":"getBkLeagueMatchList","leagueId":15,"seasonName":"18-19",round});
  }

  //联赛特征-联赛首页-杯赛，ABCD分组
  getBkLeagueStat(leagueId, seasonId) {
    return this.http.sportDataPost('/sportdata/f', {"apiName": "getBkLeagueStat", leagueId, seasonId});
  }

  //联赛之最统计-联赛首页
  getBkTheMostOfLeague(leagueId, seasonId) {
    return this.http.sportDataPost('/sportdata/f', {"apiName": "getBkTheMostOfLeague", leagueId, seasonId});
  }

  //队积分排名-首页-排名
  getBkTeamSeasonScoreRanking(leagueId, seasonId, homeAway = 0) {
    return this.http.sportDataPost('/sportdata/f', {
      "apiName": "getBkTeamSeasonScoreRanking",
      leagueId,
      seasonId,
      homeAway
    });
  }

  //球员榜单-1--得分榜,2--篮板榜, 3--助攻榜, 4--封盖榜,5--抢断榜,6--失误榜,7--犯规榜,8--贡献值
  getBkTeamPlayerRankList(leagueId, seasonId, type = 1) {
    return this.http.sportDataPost('/sportdata/f', {"apiName": "getBkTeamPlayerRankList", type, leagueId, seasonId});

  }

  //获取球员基本信息
  getBkTeamPlayerDetailMessage(playerId) {
    return this.http.sportDataPost('/sportdata/f', {"apiName": "getBkTeamPlayerDeatilMessage", playerId});
  }

  //球员转会记录
  getBkPlayerTransferRecord(playerId) {
    return this.http.sportDataPost('/sportdata/f', {"apiName": "getBkPlayerTransferRecord", playerId});
  }

  //球员职业生涯
  getBkPlayerCareerStanding(playerId) {
    return this.http.sportDataPost('/sportdata/f', {"apiName": "getBkPlayerCareerStanding", playerId});
  }

  //球员详情
  getBkPlayerStandingData(playerId, leagueId, seasonId, isPart = 0, homeAway = 0, num = 0) {
    if (homeAway == 0) {
      homeAway = 2;
    } else if (homeAway == 2) {
      homeAway = 0;
    }
    return this.http.sportDataPost('/sportdata/f', {
      "apiName": "getBkPlayerStandingData",
      playerId,
      leagueId,
      seasonId,
      isPart,
      homeAway,
      "stageName": null,
      num
    });
  }

  //获取贡献值
  getBkPlayerContribution(playerId, leagueId, seasonId) {
    return this.http.sportDataPost('/sportdata/f', {
      "apiName": "getBkPlayerContribution",
      "playerIds": [playerId],
      leagueId,
      seasonId
    });
  }

  //篮球视频 动画
  getBkVideoAndCartoonInfo(matchIds) {
    return this.dataHttp.post({matchIds: matchIds, apiName: 'getBkLeisuMatchVideo'});
  }

  //篮球足球 动画
  getFtVideoAndCartoonInfo(matchIds) {
    return this.dataHttp.post({matchIds: matchIds, apiName: "getFtLeisuMatchVideo"});
  }

  //篮球联赛数据 单双统计
  getBkOddEvenStatData(params) {

    params.apiName = 'getBkLeagueOddEvenStatApi';

    return this.dataHttp.post(params);
  }

  //篮球联赛数据 让分盘口统计接口
  getBkRfStatData(params) {

    params.apiName = 'getBkLeagueLetStatApi';

    return this.dataHttp.post(params);
  }

  //篮球联赛数据 胜负差统计接口
  getBkScoreDifferStatData(params) {

    params.apiName = 'getBkLeagueScoreDifferStatApi';

    return this.dataHttp.post(params);
  }

  //篮球联赛数据 背靠背统计接口
  getBkBkbStatData(params) {

    params.apiName = 'getBkLeagueBackToBackStatApi';

    return this.dataHttp.post(params);
  }
  //是否关注和 解读数量
  queryFollowStatusAndTjNumber(ids,matchType){
    return this.http.post('/api/matchcenter/queryFollowStatusAndTjNumber',{ids,matchType});
  }
  //获取视频动画信息缓存key
  getMediaKey(matchId,matchType) {
    return matchId + '' + matchType;
  }
  //判断是否有视频或动画
  hasVideo(matchId,matchType) {
    let key = this.getMediaKey(matchId,matchType);
    return this.videoAndCartoonMap.get(key) && this.videoAndCartoonMap.get(key).is_video;
  }
  //判断是否有视频或动画
  hasCartoon(matchId,matchType) {
    let key = this.getMediaKey(matchId,matchType);
    return this.videoAndCartoonMap.get(key) && this.videoAndCartoonMap.get(key).is_cartoon;
  }

  queryLeagueFootballTeamScore(seasonFlag,conference,leagueSeasonId,leagueSessionType,leagueId){
    return  this.dataHttp.post({seasonFlag,conference,leagueSeasonId,leagueSessionType,leagueId,apiName:'queryLeagueFootballTeamScore'});
  }
  //热门赛事-联赛首页 足球
  getFtLeagueHotMatchList(leagueId,seasonName,seasonFlag?){
    return this.http.sportDataPost('/sportdata/f',{"apiName":"getLeagueHotMatchList",leagueId,seasonName,seasonFlag});
  }
  //获取赛程数据
  getLeagueMatchList(seasonFlag,seasonName,round,group,leagueId,pageNo,pageSize){
    return  this.dataHttp.post({seasonFlag,seasonName,round,group,leagueId,pageNo,pageSize,apiName:'getLeagueMatchList'});
  }

    //根据id列表获取赛事列表  篮球
    getBkMatchListByIds(qtMatchIdList:any[]){
        return this.http.sportDataPost('/sportdata/f',{"apiName":"getBkMatchListById",qtMatchIdList});
    }
    //根据id列表获取赛事列表  足球
    getFtMatchListByIds(qtMatchIdList:any[]){
        return this.http.sportDataPost('/sportdata/f',{"apiName":"getMatchListById",qtMatchIdList});
    }

    //根据比赛id列表拿比赛及时信息
    queryTimelyMatchInfo(idList: any[]) {
        return this.dataHttp.post({apiName: 'getMatchListLiveScore', matchIdList: idList});
    }

    //篮球比赛即时比分
    queryTimelyBkMatchInfo(ids){
        return  this.dataHttp.post({matchIdList:ids,apiName:'getBkMatchListLiveScore'});
    }
    //聊天人数
    getChatroomOnlineCountApi(matchId,sportType){
        return  this.dataHttp.post({matchId,sportType,apiName:'getChatroomOnlineCountApi'});
    }

    //将 "1:2"格式分解成数组 [1,2]
    formatScore(scoreString) {
        if(scoreString) {
            let s = scoreString.split(":");
            if (s && s.length > 1) {
                let temp = [];

                temp.push(parseInt(s[0].replace('"', '') || '0'));
                temp.push(parseInt(s[1].replace('"', '') || '0'));
                return temp;
            }
        }
        return [0, 0];
    }
}
