import {Component, HostBinding, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {AlertService} from "../services/alert.service";

@Component({
  selector:'alert',
  template: `
        <style>
            .container{
             position:relative;
             width: 100%;
             height: 100%;
             background-color: rgba(0, 0, 0, 0);
            }
            .black{
              background-color: rgba(0, 0, 0, 0.5);
            }
            .wrapper{
                position:absolute;
                left:50%;top:50%;
                -webkit-transform:translate(-50%,-50%);
                transform:translate(-50%,-50%);
            }
           .content{
              -webkit-animation-name: superScaleIn;
              animation-name: superScaleIn;
              -webkit-animation-duration: .2s;
              animation-duration: .2s;
              -webkit-animation-timing-function: ease-in-out;
              animation-timing-function: ease-in-out;
              -webkit-animation-fill-mode: both;
              animation-fill-mode: both;
            }
            @keyframes superScaleIn{from{transform:scale(1.2);opacity:0}to{transform:scale(1);opacity:1}}
        </style>
       <div class="container" [ngClass]="{black:!lucencyBg}" (click)="close()">
           <div class="wrapper">
                <div class="content" [innerHTML] ="content" (click)="clickHandler($event)">
                </div>
           </div>
       </div>`,
  styles: [ ':host {height:100%;width:100%;z-index:1000;bottom:0;position:absolute }' ]
})
export class Alert implements OnInit{

  content;
  lucencyBg = false;  //是否背景透明

  constructor(public router: Router,
              public alertService:AlertService) {

  }

  ngOnInit(){
    this.content = this.alertService.content;
    this.lucencyBg = this.alertService.lucencyBg;

  }

  clickHandler($event){
    $event.stopPropagation();
    let reg = $event.target.className.match(/alert-(\S+)/);
    if(reg){

      // this.alertService.hideAlert({type:reg[1]});
        this.alertService.subject.next('hideAlert');
        this.alertService.clickSubject.next({type:reg[1]});
        this.alertService.content = null;

    }
  }

  close(){

    // this.alertService.hideAlert({type:'close'});
      this.alertService.subject.next('hideAlert');
      this.alertService.clickSubject.next({type:'close'});
      this.alertService.content = null;
      if(this.alertService.subscribe){
          this.alertService.subscribe.unsubscribe();
          this.alertService.subscribe = null;
      }
  }

}
