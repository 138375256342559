import {Injectable} from '@angular/core';
import {CookieService} from "../../../services/cookie.service";
import {SessionStorageService} from "../../../services/sessionStorage.service";
import {NavCtrlService} from "../../../services/nav-controller.service";
import {StorageService} from "../../../services/storage.service";
import {Subject} from "rxjs/Subject";
import {Events} from "../../../services/events.service";
import {RootService} from "../../../services/root.service";
import {AppConfig} from "../../../services/app-config.service";

/**
 * 登陆缓存相关 服务
 */

declare var Jcob:any;

@Injectable()
export class LoginCacheService {

  public loginSuccessWatcher =  new Subject();
  public logoutSuccessWatcher =  new Subject();

  /**
   * 登录成功回调地址
   * @returns {string}
   */
  get backUrl() {
    return this.session.get("loginCallbackUrl");
  }

  /**
   * 登录成功回调地址 带域名的
   * @returns {string}
   */
  get fullBackUrl() {
    let url = this.backUrl;
    if(url.indexOf("http") == -1)
      return this.appConfig.domain + '/#' + url;
    return url;
  }

  /**
   * 缓存 backUrl 防止丢失
   * 将backUrl存到缓存，防止到登录页，又跳转到其他页面而造成参数丢失
   */
  set backUrl(backURL:string) {
  backURL = this.resolveBackUrl(backURL);
  this.session.set("loginCallbackUrl",backURL);
  }

  constructor(
    private cookieService: CookieService,
    private storageService: StorageService,
    private session: SessionStorageService,
    private navCtrl: NavCtrlService,
    private event: Events,
    private rootService: RootService,
    private appConfig: AppConfig,
    ) {

  }

  /**
   * 登录成功调用
   * 保存登录缓存
   * @param token 登录缓存 凭证
   * @param type  登录方式 可能取值  盈球登录jcob  微信登录wechat 微博登陆weibo 京东登陆 jingdong
   */
  public loginSuccessAfter(token:string,type='jcob') {

    this.addLoginCache(token);

    //发布登录成功事件
    setTimeout(()=>{
      this.event.publish("loginSuccess",{type:'jcob'});
      this.loginSuccessWatcher.next();
    },200);
  }

  /**
   * 退出登录成功调用
   */
  public logoutSuccessAfter() {

    this.clearLoginCache();         //清除登录缓存
    this.clearUserCache();          //清除用户缓存

    //发布注销成功事件
    setTimeout(()=>{
      this.event.publish("logoutSuccess");
      this.event.publish("clearStoredRoutes");      //清除页面缓存
      this.logoutSuccessWatcher.next();
    },200);

  }

  /**
   * 添加登录缓存
   */
  public addLoginCache(token:string) {

    this.storageService.set("jcob_token",token);
    this.session.set("jcob_token",token);
  }

  /**
   * 清除登录缓存
   */
  public clearLoginCache() {

    this.storageService.remove("jcob_token");
    this.session.remove("jcob_token");
    this.cookieService.delCookie2("jcob_token");
  }

  /**
   * 清除用户缓存
   */
  public clearUserCache() {

    Jcob.userInfoCache.clear();     //清除用户缓存信息

    let $rootScope = this.rootService.getRoot();
    delete $rootScope.userInfo;
    if($rootScope.appInfo)
      delete $rootScope.appInfo.token;

  }

  /**
   * 从本地缓存获取用户缓存
   */
  public getUserCacheFormLocal() {
    return Jcob.userInfoCache.loadAll();

  }

  /**
   * 从内存中获取用户缓存
   */
  public getUserCacheForMmemory() {
    return this.rootService.getRoot() && this.rootService.getRoot().userInfo;
  }

  /**
   * 获取保持登录的token
   */
  public getLoginToken() {
    //从域里取 jcob_token,使用jcob_token判断登录
    let token: string = this.storageService.get('jcob_token') || this.session.get('jcob_token');
    if (!token) {
      //从cookie里取
      token = this.cookieService.get("jcob_token");
      if (token) {
        this.addLoginCache(token);
      }
    }
    // console.log("登录token："+token);
    return token;
  }

  /**
   * 跳转登录页
   * @param {string} backUrl 登录成功回调地址 默认回到首页
   * @param {boolean} replaceUrl 跳转时是否保存跳转前页面的历史记录
   */
  public goLoginPage(backUrl='/home',replaceUrl = false) {
    this.navCtrl.push("LoginPage",{backUrl:backUrl},replaceUrl);
  }

  /**
   * 判断是否登录
   * @returns {boolean}
   */
  public isLogin() {

    return !!this.getLoginToken();
  }

  /**
   * 检查登录状态，
   * 如果未登录，则跳转登录页面并且返回true
   * 否则直接返回false
   *  @param {string} backUrl 登录成功回调地址 默认回到首页
   *  @param {boolean} replaceUrl 跳转时是否保存跳转前页面的历史记录
   */
  public checkAndToLogin(backUrl='/home',replaceUrl = false) {

    let login = this.isLogin();

    if(!login) {
      this.goLoginPage(backUrl,replaceUrl);
    }
    return !login;
  }

  /**
   * 跳转登录页面 并清除登录缓存
   *  @param {string} backUrl 登录成功回调地址 默认回到首页
   *  @param {boolean} replaceUrl 跳转时是否保存跳转前页面的历史记录
   */
  public goLoginAndClearCache(backUrl='/home',replaceUrl = false) {
    this.clearLoginCache();
    this.clearUserCache();
    this.goLoginPage(backUrl,replaceUrl);

  }

  /**
   * 处理url
   * 将一些特殊outlet url地址删掉  如   /aa/bb;(oName=xx)
   */
  public resolveBackUrl(url:string) {
    return url && url.replace(/\(.*\)/,'');
  }

}
