import {Injectable} from '@angular/core';
import {ERROR_MSG, RACE_STSTUS} from '../../../model/const-config.model';
import {LoginCacheService} from '../../account/login/login-cache.service';
import {AppHttp} from '../../../services/app-http.service';
import {SportdataHttp} from '../../../services/sportdata-http.service';
import {AlertService} from '../../../services/alert.service';
import {Events} from '../../../services/events.service';
import {Subject} from 'rxjs/Subject';
import {AppConfig} from '../../../services/app-config.service';

/**
 * 赛事红包雨 服务
 */

@Injectable()
export class MatchRedPacketsService {

    private redPacketMatches = {};      // 存放全部红包赛事 键赛事id ， 值，赛事进球情况

    private matchingIds = [];           // 需要刷新的赛事id

    private refreshTask1;               // 比赛中刷新任务
    private refreshTask2;               // 刷新任务
    private refreshTime = 10000;        // 刷新间隔

    private joinedTime = {};             // 参加红包雨的时间  2次参加红包雨时间间隔不能少于60秒
    private joinedCache = [];             // 保存参与过的缓存 matchId+count 赛事id加轮次
    private redPacketRainMatchId;         // 是否下红包雨开关 值为赛事id，如果为null，则不下红包雨

    private lockRain = false;           // 锁定红包雨 防止在红包雨页面时，多次弹出红包雨

    public RACE_STSTUS = RACE_STSTUS;


    // 红包雨组件控制 要在对应页面 引入红包雨组件
    public matchRedPacketRainComponent = {
        showSubject: new Subject(),     // 显示订阅
        matchId: null,   // 红包雨赛事id
        goal: null,      // 红包雨进球轮次
    };

    constructor(
        private http: AppHttp,
        private dataHttp: SportdataHttp,
        private alertService: AlertService,
        private loginCache: LoginCacheService,
        private events: Events,
        private appConfig: AppConfig,
    ) {

        // 京东和推球端不需要红包雨
        if (appConfig.showRedPack) {
            this.getRedPacketMatchesData().then(() => {
                this.startRefresh();
            }).catch(() => {
                this.startRefresh();
            });
        }

        this.events.subscribe('loginSuccess', () => {
            this.joinedTime = {};
            this.joinedCache = [];
        });
        this.events.subscribe('logoutSuccess', () => {
            this.joinedTime = {};
            this.joinedCache = [];
        });

    }

    // 获取红包赛事信息 type 1全部 2赛中 3赛前
    private getRedPacketMatchesData(matchIds?) {
        return new Promise((rs, rj) => {
            this.queryRedPacketMatches(matchIds).then((data) => {

                if (data && data.code == 1 && data.matchs.length > 0) {

                    this.matchingIds = [];

                    data.matchs.forEach((match) => {

                        this.matchingIds.push(match.matchId);

                        // 红包赛事初始化
                        if (match.redStatus && match.redStatus == 1) {
                            this.resolveMatch(match);
                        } else {
                            delete this.redPacketMatches[match.matchId];
                        }

                    });

                    rs();
                    // console.log(this.redPacketMatches);
                } else {
                    rj();
                }
            }).catch((err) => {
                console.log(err);
                rj();
                // this.alertService.msg(err.msg || ERROR_MSG,1000);
            });
        });
    }

    // 处理返回的红包赛事信息
    private resolveMatch(match) {

        let m = {
            status: 0,       // 赛事状态
            goal: null,          // 进球情况
            shake: 0,      // 是否抖动  根据进球时间判断 如果是 1-5分钟内的进球，则为true  值为第几个进球
        };

        m.status = match.matchStatus;

        this.redPacketMatches[match.matchId] = m;
        // 进球数据
        if (match.pointJson) {
            m.goal = JSON.parse(match.pointJson);
            this.checkShake(match).then((data) => {

                // 下赛事红包雨判断
                if (this.redPacketRainMatchId && this.redPacketRainMatchId == match.matchId) {
                    // console.log("检查是否下红包雨");
                    this.checkRadPacketRain(m);
                }
            }).catch((res) => {
                console.log(res);
            });
        }

    }

    // 是否下赛事红包雨
    private checkRadPacketRain(match) {

        let joinedTime = this.getJoinedTime(this.redPacketRainMatchId);
        // 每次参与时间间隔
        let join = !joinedTime || (new Date().getTime() - joinedTime >= 60 * 1000);

        // 判断是否显示红包雨弹窗
        if (match.shake && join && !this.lockRain) {
            this.showGetPacketConfirm(this.redPacketRainMatchId, match.shake);

            // 弹出红包雨 就算参与了 不再弹窗
            this.cacheJoined(this.redPacketRainMatchId, match.shake);
        }

    }

    // 检测是否红包抖动
    // 规则:进球时间在1-5分钟内，比如 现在是 10:06,那么10.01 到 10.05之间有进球，就抖动
    private checkShake(match) {


        return new Promise((rs) => {

            if (!match || !match.pointJson) {
                return;
            }

            let shake = 0;

            try {

                let json = JSON.parse(match.pointJson);

                for (let key in json) {

                    let goalTime = json[key];

                    // 进球时间
                    if (goalTime) {

                        let now = new Date().getTime();

                        // 判断是否在指定时间内有进球
                        if ((now - goalTime > 60 * 1000) && (now - goalTime <= 5 * 60 * 1000)) {

                            // 从缓存获取用户是否参与过
                            if (this.joinedCache.indexOf('' + match.matchId + key) == -1) {
                                this.checkCanJoin(match.matchId, parseInt(key)).then(() => {

                                    shake = parseInt(key);
                                    this.redPacketMatches[match.matchId].shake = shake;

                                    rs();

                                }).catch((res) => {
                                    console.log(res);
                                    // 如果参与过，保存参与结果
                                    if (res.errorCode && (res.errorCode == 101 || res.errorCode == 1007 || res.errorCode == 1008)) {

                                        this.cacheJoined(match.matchId, key);
                                    }
                                });

                                return;

                            }
                        }
                    }
                }

            } catch (e) {
            }
        });

    }


    // 开始刷新红包情况
    private startRefresh() {

        if (!this.refreshTask1) {

            // 赛中的赛事刷新
            this.refreshTask1 = setInterval(() => {

                if (this.matchingIds && this.matchingIds.length > 0) {
                    this.getRedPacketMatchesData(this.matchingIds).catch(() => {
                    });
                } else {
                    clearInterval(this.refreshTask1);
                    this.refreshTask1 = null;

                }

            }, this.refreshTime);
        }

        if (!this.refreshTask2) {

            // 重新获取红包赛事
            this.refreshTask2 = setInterval(() => {

                this.getRedPacketMatchesData().catch(() => {
                });
                ;

            }, this.refreshTime * 100.3);
        }

    }

    // 停止刷新
    private stopRefresh() {

        this.refreshTime && clearInterval(this.refreshTask1);
        this.refreshTask1 = null;

        this.refreshTime && clearInterval(this.refreshTask2);
        this.refreshTask2 = null;
    }


    // 检查是否是红包赛事
    public getRedPacketMatche(matchId) {
        // return true;
        return this.redPacketMatches[matchId];
    }

    // 显示红包规则弹窗
    public showRedPacketRule() {

        this.alertService.show(`
        <div class="popupWrap redPacketRule">
            <div class="popupCon">
              <div class="wd_topBar"><span>进球红包雨活动规则</span><a class="close_btn_gray alert-close"></a></div>
              <div class="rule pl15 pr15 pt5 pb5">
                <span>1、参与方法</span>
                <div class="mb15">只要观看有红包标志的足球赛事直播，比赛的每次进球得分都会触发一轮红包雨，在10秒内点击屏幕上的任意红包即可抽取奖品。红包雨参与时间为5分钟，即进球之后5分钟内进入直播的用户皆可参与一轮。</div>
                <span>2、查看奖品方法</span>
                <div class="mb5">如果在活动期间抽中了红包雨的奖品，那么在天天盈球的“我的优惠券”/金币明细内可以查看抽中的奖品。</div>
                <div class="mb15">优惠券有效期为券面时间，金币自获得之日起有效期为7天。</div>
                <span>3、注意事项</span>
                <div class="mb5 ml8">①参与红包雨活动时，红包雨只在直播时会触发，且判定进球为有效进球。</div>
                <div class="mb5 ml8">②其中点球大战是不会触发红包雨的。</div>
                <div class="mb5 ml8">③同一场比赛中，每一轮红包雨至少间隔1分钟。</div>
              </div>
            </div>
            <div class="popupBtn">
              <a class="border bdt bdr bdc_ddd orange alert-submit">我知道了</a>
            </div>
          </div>
        
        `).then((data) => {
            console.log(data);

            /*  if(data.type == 'submit') {
                  navCtrl.push("MatchDetailFootball", {matchId});
              }*/


        });

    }

    // 显示抢红包弹窗
    public showGetPacketConfirm(matchId, goalCount) {

        console.log('显示红包雨弹窗');
        this.matchRedPacketRainComponent.matchId = matchId;
        this.matchRedPacketRainComponent.goal = goalCount;

        this.matchRedPacketRainComponent.showSubject.next({type: 1});

    }

    // 隐藏抢红包弹窗
    public hideGetPacketConfirm() {

        // console.log("隐藏红包雨弹窗");
        this.matchRedPacketRainComponent.showSubject.next({type: 0});
    }

    // 设置上次抢红包的时间
    public setJoinedTime(time, matchId, count) {
        this.joinedTime[matchId] = time;
        this.cacheJoined(matchId, count);
    }

    // 获取某个赛事上次抢红包时间
    public getJoinedTime(matchId) {
        return this.joinedTime[matchId];
    }

    // 锁定红包雨浮窗 防止在红包雨页面时，多次弹出红包雨
    public toLockRain() {

        this.lockRain = true;

    }

    // 解锁锁定红包雨浮窗
    public toUnlockRain() {

        this.lockRain = false;
    }

    // 缓存参与记录 防止一直调用查询接口
    public cacheJoined(matchId, count) {
        console.log('缓存红包雨' + matchId, count);
        this.joinedCache.push('' + matchId + count);        // 保存抢过红包的缓存
    }

    // 设置需要下红包雨的赛事
    public setRedPacketRainMatch(matchId) {
        console.log('赛事红包雨准备' + matchId);
        this.redPacketRainMatchId = matchId;
    }

    // 取消下红包雨监听
    public stopRedPacketSubscribe() {
        console.log('赛事红包雨取消');
        this.redPacketRainMatchId = null;
    }

    // 检查是否可以参与此轮红包雨
    public checkCanJoin(matchId, goalCount, checkOrJoin = 0) {

        return new Promise((rs, rj) => {
            this.queryUserJoinInfo(matchId, goalCount, checkOrJoin).then((data) => {

                if (data.isSuccess) {

                    if (data.join) {

                        if (checkOrJoin == 1 && (!data.login || data.login == 'false')) {
                            rj(data);
                        } else {
                            rs(data.award);
                        }
                    }
                } else {
                    rj(data);
                }

            }).catch((data) => {
                /*rs([
                    {
                        "description": "金币",
                        "index": 3
                    },
                    {
                        "description": "免费券",
                        "index": 1
                    },
                    {
                        "description": "满减券",
                        "index": 2
                    },

                    {
                        "description": "空红包",
                        "index": 0
                    }
                ]);*/
                rj(data);
            });
        });

    }

    // 点击红包 参与红包雨
    public clickRedPacket(matchId, goalCount) {

        return new Promise((rs, rj) => {
            this.joinedHd(matchId, goalCount).then((data) => {

                if (data.isSuccess) {
                    rs(data.award);
                } else {
                    rj(data);
                }
            }).catch((res) => {
                rj(res);
            });
        });

    }

    /*数据接口*/

    // 获取全部红包赛事
    private queryRedPacketMatches(matchIds?) {
        return this.dataHttp.post({matchIds: matchIds, apiName: 'getRedpackedMatchList'});
    }

    // 获取用户参与情况 查询是否可参与
    // @param checkOrJoin 查询参与情况还是参与 0表示只判断 1表示判断并参与
    private queryUserJoinInfo(matchId, goalCount, checkOrJoin = 0) {
        return this.http.get('/api/hd/match/redPacket/check', {params: {matchId, goalCount, checkOrJoin}});
    }

    // 点击红包 参与活动
    private joinedHd(matchId, goalCount) {
        return this.http.get('/api/hd/match/redPacket/clickRed', {params: {matchId, goalCount}})

    }

}
