
import {Injectable} from "@angular/core";
import {AppHttp} from "../../../services/app-http.service";
import {Events} from "../../../services/events.service";
@Injectable()
export class ExpertService {

    qiuFlag:any=1;

    constructor(public http: AppHttp,
                public events: Events) {

    }

    searchUser (keyWord,type,pn) {
        // console.info(keyWord,type,pn);
        return this.http.get("/api/search/searchUser",{params:{keyWord:keyWord,type:type,pn:pn}});
    }

    queryHotUser (type) {
        return this.http.get("/api/search/hotUser",{params:{type:type}});
    }

    addClick (memberId,level) {
        return this.http.get("/api/search/addClick",{params:{memberId:memberId,userLevel:level}});
    }

    addCache (key,value){
        let searchCache:any = localStorage.getItem("search");
        if(searchCache){
            searchCache = JSON.parse(searchCache);
        }else{
            searchCache = {};
        }
        searchCache[key] = value;
        localStorage.setItem("search",JSON.stringify(searchCache));
    }


    getCache () {
        let searchCache:any = localStorage.getItem("search");
        if(searchCache){
            searchCache = JSON.parse(searchCache);
        }else{
            searchCache = {};
        }
        return searchCache;
    }

    getHistoryWord () {
        return this.getCache().words || [];
    }

    clearHistoryWord () {
        this.addCache("words",[]);
    }

    cacheWord (word) {
        if(!word){
            return;
        }
        let cache = this.getCache();
        let words = cache.words || [];
        for(let i in words){
            if(word == words[i])
                return;
        }
        words.unshift(word);
        if(words.length > 8){
            words.pop();
        }
        this.addCache("words",words);
    }

    queryWbFamousExpertList (type,pageNo,qiuFlag=1,pageSize=8) {//查询微博名人排行榜
      // return this.http.post('/api/WbFamousExpert/list/'+type+'/'+pageNo,{},{params:{qiuFlag,pageSize}});
        return this.http.get(`/api/famous/ranking/${qiuFlag}/${type}`,{params:{pageNo,pageSize}});
    }

  attentionExpert(params){
      return this.http.post("/api/uc/attention",params);
  }

  queryLcMatchName(gameType?) {
    if (!gameType)
      gameType = "all";
    return this.http.post('/api/lcRace/matchNameListNew', {playType: gameType});
  }

  queryMatchName(gameType?) {
    if (!gameType) gameType = "all";
    return this.http.post('/api/tjRace/matchNameListNew', {playType: gameType});
  }

  //测试专家方案列表
  testPlanList(planSearchOption, pageNo) {
    return this.http.post('/api/labPlan/list/' + pageNo, planSearchOption);
  }


  //返奖率排行  4.9.2改成回报率
  pageExpertProfitRatioByRank(pageNo, raceTypeId) {
    return this.http.post('/api/plan/expertWinRatioByRankOption/' + pageNo, {raceTypeId: raceTypeId, sortType: 8});
  }


}
