/**
 * 验证码发送类型
 * @link jcob-interface-common: SmsAuthType
 */
import {AppHttp} from "../services/app-http.service";

export const SMS_AUTH_TYPE = {
    RESET_PWD: 48,		//用户中心密码重置
    USER_REGISTER: 47,	//用户中心注册
    PHONE_VERIFY: 49,	//用户中心手机验证
    EXPERT_APPLY: 50,	//专家申请手机验证
    USER_LOGIN_REGISTER: 51,	//6.7.0新加：用户手机验证码登录/注册
    RETRIEVE_PWD: 52,	//6.7.0新加：找回密码发送验证码
};

//验证码参数，为了扩展，所以使用对象
export interface CodeParams{
    phone?:string;
    authType?:number;
}

/**
 * 验证码中心实体
 * 每个不同 SMS_AUTH_TYPE 有且只有一个实体
 * 相同 SMS_AUTH_TYPE 请勿重复创建实体
 */
export class AuthCodeCenter {

    public static MAX_WAIT_TIME = 61;		//等待时间  这里多了1秒，是因为倒计时时直接先-1了

    private waitTimeLeft = AuthCodeCenter.MAX_WAIT_TIME;		//当前需要等待时间

    private timer;				//计时器

    constructor(
            private appHttp: AppHttp,
            private authType:number = 49,   //验证码类型
    ) {

        this.authType = authType;
    }

    /**
     * 还需要等待的时间
     * 当时间 == AuthCodeCenter.MAX_WAIT_TIME 时表示可以再次发送
     * @return {number}
     */
    public get leftTime():number {
        return this.waitTimeLeft;
    }

    /**
     * 判断是否可以发送验证码
     */
    public get canSend():boolean {
        return this.leftTime == AuthCodeCenter.MAX_WAIT_TIME;
    }


    /**
     * 验证码发送，所有发送接口都必须经过这个接口
     * 用来做验证码发送倒计时限制
     * 另外，此接口进行请求会自动带上 authType 参数
     * @param {number} authType
     * @param {string} api 请求验证码的接口
     * @param params
     */
    public sendAuthCode(api:string, params:CodeParams):Promise<any> {

        //用户中心手机验证可以不传手机号
        if(!params && this.authType != SMS_AUTH_TYPE.PHONE_VERIFY) return Promise.reject({msg:'请传入手机号等参数'});
        if(!this.canSend) return Promise.reject({msg:'请等待60秒才可再次发送'});

        params.authType = this.authType;

        //注意，发送验证码成功后，返回体中必须包含 success 或 isSuccess
        return this.appHttp.post(api,params).then((data)=>{

            if(data && (data.isSuccess || data.success)) {
                data.codeCenter = this;
                this.startTimer();
                return data;
            }else {
                return Promise.reject(data);
            }

        });
    }

    /**
     * 开始计时器
     */
    private startTimer() {

        //先重置时间
        this.waitTimeLeft = AuthCodeCenter.MAX_WAIT_TIME - 1;
        this.startTimerTask();
    }

    /**
     * 开始计时器任务
     */
    private startTimerTask() {

        this.timer = setTimeout(()=>{

            //清空计时器
            if(this.waitTimeLeft <= 1) {
                clearTimeout(this.timer);
                this.timer = null;

                this.waitTimeLeft = AuthCodeCenter.MAX_WAIT_TIME;
            }else {
                this.waitTimeLeft --;
                this.startTimerTask();
            }

        },1000);
    }


}
