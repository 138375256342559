
import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    template: `
        <div class="shadow" style="display:block;opacity: 1;background: transparent;" tappable (click)="close()"></div>
        <div [innerHTML] ="content" (click)="clickHandler($event)" tappable></div>
    
       `
})
export class ConfirmPage{
    @Input() content:String;
    @Input() backdropDismiss:boolean;
    constructor(public modalCtrl:ModalController){
    
    }

    clickHandler($event){
        $event.stopPropagation();
        let reg = $event.target.className.match(/alert-(\S+)/);
        if(reg){
            this.modalCtrl.dismiss({type:reg[1]},null);
        }
    }

    close(){
        if(this.backdropDismiss) this.modalCtrl.dismiss({type:'close'},null);
    }

}
