import {Injectable} from "@angular/core";
import {AppHttp} from "./app-http.service";


@Injectable()
export class ShareService{


  constructor(public appHttp:AppHttp){}

  /**
   * 上传活动分享点击信息
   * @param shareInfoId
   * @param  from
   * @returns {Promise<any>}
   */
  shareStatistics(shareInfoId,from){
    return this.appHttp.get("/api/share/uploadClick",{params:{shareInfoId:shareInfoId,shareFrom:from}})
  }
}
