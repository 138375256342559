
import {AppHttp} from "../../../../services/app-http.service";
import {Injectable} from "@angular/core";
@Injectable()
export class ExpertSearchService {

    constructor(public myHttp: AppHttp) {

    }

    searchUser (keyWord,type,pn) {
        // console.info(keyWord,type,pn);
        if(type) {
          return this.myHttp.get("/api/search/searchUser", {params: {keyWord: keyWord, type: type, pn: pn}});
        }else{
          return this.myHttp.get("/api/search/searchUser", {params: {keyWord: keyWord, pn: pn}});
        }
    }

    queryHotUser (type) {
      if(type){
        return this.myHttp.get("/api/search/hotUser", {params: {type: type}});
      }else {
        return this.myHttp.get("/api/search/hotUser");
      }
    }

    addClick (memberId,level) {
        return this.myHttp.get("/api/search/addClick",{params:{memberId:memberId,userLevel:level}});
    }

    addCache (key,value){
        let searchCache:any = localStorage.getItem("search");
        if(searchCache){
            searchCache = JSON.parse(searchCache);
        }else{
            searchCache = {};
        }
        searchCache[key] = value;
        localStorage.setItem("search",JSON.stringify(searchCache));
    }


    getCache () {
        let searchCache:any = localStorage.getItem("search");
        if(searchCache){
            searchCache = JSON.parse(searchCache);
        }else{
            searchCache = {};
        }
        return searchCache;
    }

    getHistoryWord () {
        return this.getCache().words || [];
    }

    clearHistoryWord () {
        this.addCache("words",[]);
    }

    cacheWord (word) {
        if(!word){
            return;
        }
        let cache = this.getCache();
        let words = cache.words || [];
        for(let i in words){
            if(word == words[i])
                return;
        }
        words.unshift(word);
        if(words.length > 8){
            words.pop();
        }
        this.addCache("words",words);
    }


}
