import {JsAutoLoadGuard} from './JsAutoLoadGuard';

//获取渠道号
export function getDefaultAgentId():Promise<any> {

    return new Promise((rs,rj)=>{
        new JsAutoLoadGuard().dynamicLoading('/assets/js/checkPhoneType.js').then(()=>{

            if((<any>window).jcobCheckPhoneType) {
                // window.alert("特殊渠道"+ (<any>window).jcobCheckPhoneType.getSpecialAgentId())
                rs((<any>window).jcobCheckPhoneType.getSpecialAgentId());
            }else{
                rj();
            }
        }).catch(()=>{
            console.log("加载判断手机型号js失败！");
            rj();
        })
    });
}
