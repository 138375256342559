import {Injectable, NgZone} from '@angular/core';
import {AppConfig} from "./app-config.service";
import {RootService} from "./root.service";
import {AlertService} from "./alert.service";
import {FileItem, FileUploader} from 'ng2-file-upload';

/**
 * 图片上传服务
 * h5使用 ng2FileSelect 插件获取图片
 */
@Injectable()
export class ImageUploadService {

    private get userInfo() {
        return this.rootService.getUserInfo() || {};
    }

    //图片选择并上传对象，需要当 ng2FileSelect 指令的参数
    public uploader:FileUploader;

    constructor(
            private appConfig:AppConfig,
            private rootService:RootService,
            private ngZone:NgZone,
            private alert:AlertService,
            // private imgPicker:ImagePickerService,
    ) {
        this.initUploader();
    }

    private initUploader(){
        this.uploader = new FileUploader({
            url: "/api/sns/savePicture",
            method: "POST",
            itemAlias: "image",
            allowedFileType:['image'],
            autoUpload:false,
            removeAfterUpload:true,
        });

        this.uploader.options.additionalParameter={
            'memberId' : this.userInfo.id,
            'platform' : this.appConfig.platform||'',
            'appVersion' :this.appConfig.appVersion||'',
            'agentId' :this.appConfig.agentId||''
        };

        return this.uploader;
    }

    /**
     * 从相册或拍张获取并上传图片
     * @param  pictureContainer 图片容器，保存上传的图片信息
     * @param {number} maxPicNum 最大选择图片数
     * @return 图片上传相关信息
     */
    getAndUploadPhoto(pictureContainer:any[],maxPicNum=6) {

        pictureContainer = pictureContainer || [];
        const pics =  this.uploader.queue;

        if(pics.length > maxPicNum) {
            pics.splice(0,pics.length - maxPicNum);
            this.alert.msg("只能上传6张图片");
        }

        if(pics && pics.length > 0) {
            this.alert.loading(5000,"上传图片中...",false);
            pics.forEach((fileItem,index)=>{
                pictureContainer.push(this.uploadPhoto(fileItem,pics.length-1==index));
            })

        }
        return pictureContainer;
    }


    /**
     * 上传图片
     * @param fileItem ng2FileSelect 的 文件上传组件子item
     * @return 图片上传相关信息
     */
    private uploadPhoto(fileItem:FileItem,last=false){

        if(!fileItem) return;

        let pic = {
            percent:0,  //上传百分比
            path:'',    //图片路径
            uri:'', //需要回显的图片地址
            fail:0, //上传失败? 1失败 0成功
        };

        //上传成功
        fileItem.onSuccess = (rp,status,headers)=>{

            if(status == 200) {
                const result = JSON.parse(rp);

                if(!result.isSuccess) {
                    this.alert.msg(result.msg);
                    pic.fail = 1;
                }else {
                    this.ngZone.run(()=>{
                        pic.path = result.path;
                        setTimeout(()=>{
                            pic.uri = result.prefix + result.path;
                        },200);
                    });
                }
                if(last){
                    this.alert.hideLoading();
                }
            }else {
                this.alert.hideLoading()
                pic.fail = 1;
                this.alert.msg('图片上传失败,请重试');
            }
        };

        //上传失败
        fileItem.onError = (rp)=>{
            this.alert.hideLoading()
            console.error(rp);
            pic.fail = 1;
            this.alert.msg('图片上传失败,请重试');
        };

        //上传进度
        fileItem.onProgress = (progress)=>{
            pic.percent = progress;
        };

        //开始上传
        fileItem.upload();

        return pic;
    }
}
