import {Injectable} from '@angular/core';
import {AppHttp} from './app-http.service';
import {RootService} from "./root.service";

/**
 * 取项目常量
 */
@Injectable()
export class AppConstConfigService {
    constructor(
            private http: AppHttp,
            private root: RootService,
    ) {
    }
    /**
     * 初始化全局常量
     */
    public initConstConfig() {

        this.http.get("/api/app/const/config").then((data)=>{

            this.root.promotion = data.promotion;

        }).catch((err)=>{})

    }

}
