import {Injectable} from '@angular/core';
import {AlertController, LoadingController, ToastController, ModalController} from "@ionic/angular";
import {AlertPage} from "../component/alert/alert";
import {ConfirmPage} from "../component/alert/confirm";
import {Subject} from "rxjs/Subject";

@Injectable()
export class AlertService {

  loadingInstance:any;
  modalInstance:any;
  msgInstance:any;

  content: string;
  subject = new Subject<any>();
  subscribe: any;
  clickSubject= new Subject<any>();
  lucencyBg: boolean = true;
  showModal:boolean=false;
  lazyLoadKey: string;      //懒加载路径
  params;     //参数传递中

  constructor(private modalCtrl:ModalController,
              private toastCtrl:ToastController,
              private loadingCtrl: LoadingController,
              private alertCtrl:AlertController,) {}

  //top 定位高度 单位：百分比
  async show(content:String,top=30):Promise<any>{

    let option:any = {};
    option.component = AlertPage;
    option.componentProps = {content,top};
    option.animated = false;
    option.showBackdrop = true;
    option.cssClass = 'alert_page';

    let alert:any=await this.modalCtrl.create(option);

    alert.present();
    return new Promise((resolve,reject)=>{
      alert.onDidDismiss().then((data:any)=>{
        //添加一个延迟，防止马上进行页面跳转导致报错
        setTimeout(()=>{
          resolve(data.data);
        },50);
      })
    })
  }

    show2(content, lucencyBg = false) {
        this.content = content;
        this.lucencyBg = lucencyBg;
        if (!content)
            return new Promise((rs,rj)=>{
                rj({mag:'没有内容'});
            });
        this.subject.next('showAlert');
        return new Promise((resolve) => {
            this.subscribe = this.clickSubject.subscribe((data: any) => {
                resolve(data);
            })
        })
    }
    msg2(msgContent, duration?:number){
        return new Promise((rs,rj)=>{
            this.content = msgContent;
            this.subject.next('showMsg');
            if(duration===void 0){
                duration=1200;
            }
            setTimeout(()=>{
                this.subject.next('hideMsg');
                rs();
            },duration)
        })
    }
    hideMsg2(){
        this.subject.next('hideMsg');
    }
  createModal2(component:any, params?:any,lazyLoadKey?:string) {
      this.content = component;
      this.lazyLoadKey = lazyLoadKey;
      this.params = params;
      if (!component)
          return new Promise((rs,rj)=>{
              rj({mag:'没有内容'});
          });

      this.showModal = true;

      return new Promise((resolve) => {
          this.subscribe = this.clickSubject.subscribe((data: any) => {
              console.info(data);
              resolve(data);
          })
      })
  }
    //隐藏modal
    hideModal2(params?) {
        this.showModal = false;
        this.content = null;
        this.lazyLoadKey = null;
        this.params = null;
        this.clickSubject.next(params);
        if(this.subscribe){
            this.subscribe.unsubscribe();
            this.subscribe = null;
        }
    }

  async createModal(page:any,param?:any,showBackdrop=true):Promise<any>{

    this.modalInstance =await this.modalCtrl.create({
      component:page,
      showBackdrop:showBackdrop,
      componentProps:param,
      cssClass:'alert_page',
      id:'modal'
    });
    this.modalInstance.present();
    return new Promise((resolve,reject)=>{
      this.modalInstance.onDidDismiss().then((data:any)=>{
        this.modalInstance=undefined;
        resolve(data.data);
      })
    })
  }

  /**
   *  创建延迟加载的 modal
   * @param {ModalController} modal  因为延迟加载的modal和本服务直接注入的modal是不同的，所以需要将modal当参数
   * @param page  需要加载的组件
   * @param param 需要的参数
   * @return {Promise<any>}
   */
  async createModalDelay(modal:ModalController,page: any, param?: any): Promise<any> {

    this.modalInstance = await modal.create({
      component: page,
      componentProps: param,
      cssClass: 'alert_page',
      id: 'modal'
    });
    this.modalInstance.present();
    return new Promise((resolve, reject) => {
      this.modalInstance.onDidDismiss().then((data: any) => {
        this.modalInstance = undefined;
        resolve(data.data);
      })
    })
  }

  //隐藏modal
  hideModal(params?) {
    return this.modalCtrl.dismiss(params, null);
  }

  msg(msg:string,time?:number,force?):Promise<any>{
    if(!time){
      time = 2000;
      //信息太长，稍微延长下
      if(msg && msg.length > 20) time = 3000;
    }
    return new Promise(async (resolve,reject)=>{
      this.msgInstance =await this.toastCtrl.create({
        message: msg,
        duration: time,
        position:'middle',
        //dismissOnPageChange:true,
        cssClass:'alert200',
        color:'dark'
      });
      this.msgInstance.onDidDismiss().then((data:any) => {
        this.msgInstance = undefined;
        resolve(data);
      });
      this.msgInstance.present();
    })
  }
  hideMsg(){
    if(this.msgInstance) {
      this.toastCtrl.dismiss();
    }
  }

  async loading(duration=5000,content?:string,backdropDismiss=true) {

    this.loadingInstance= await this.loadingCtrl.create({
      duration: duration||5000,
      message: content||'',
      backdropDismiss:backdropDismiss,
    });
    await this.loadingInstance.present();
    await this.loadingInstance.onDidDismiss();
    this.loadingInstance = undefined;
  }
  //防止loading没有初始化完成前调用hideLoading，延迟300毫秒递归调用几次，num为当前递归次数
  hideLoading(delay=0,num=0) {
    if(this.loadingInstance){
        setTimeout(()=>{
            this.loadingInstance && this.loadingInstance.dismiss();
        },delay);
    }else if(num<5){
      setTimeout(()=>{
        this.hideLoading(delay,++num);
      },300);
    }
  }

  async alert(content:any):Promise<any>{
    return new Promise(async (resolve,reject)=>{
      let obj:any = await this.alertCtrl.create({
        header:'',
        message:content||'',
        backdropDismiss:false,
        buttons:[
          {
            text:"我知道了",
            role:'cancel',
            handler:()=>{
              resolve();
            }
          }
        ]

      })
      obj.present();
    })
  }

  /**
   * confirm确认框
   */
  //无内容 无样式 有遮罩
  async confirmNew(content:String,animated = true,backdropDismiss = true):Promise<any>{

      let option:any = {};
      option.component = ConfirmPage;
      option.componentProps = {content,backdropDismiss,top};
      option.animated = animated;
      option.showBackdrop = true;
      option.backdropDismiss = backdropDismiss;//点击背景时是否允许消失
      option.cssClass = 'alert_page';

    let alert:any =await this.modalCtrl.create(option);

    alert.present();
    return new Promise((resolve,reject)=>{
      alert.onDidDismiss().then((data:any)=>{
        let response = data.data;
        if(response.type=='close'){//点击关闭时使用reject
          reject(response);
        }else{
          //添加一个延迟，防止马上进行页面跳转导致报错
          setTimeout(() => {
            resolve(data.data);
          }, 50);
        }
      })
    })
  }


    /**
     * 底部提醒通用弹窗
     * ***** 需要注意，这里对应的是app的confirm2
     * @param {string} content
     * @param {string} canel
     * @param {string} ok
     * @param {boolean} backdropDismiss 是否可以点击消失
     * @return {Promise<any>}
     */
  confirm(content:string,ok:string='确定', canel:string='取消', backdropDismiss=true) {

    return this.confirmNew(`
          <div class="wd_popup_auto animate">
            <div class="wd_popup_con">
                <p class="plan_hint_text tac fs16 c333 pt40 pb45 pl30 pr30">${content}</p>
            </div>
            <div class="popup_btn border bdt bdc_e5">
                <div class="wd_popup_btn_group">
                    <a  class="alert-close">${canel}</a>
                    <a  class="orange alert-submit">${ok}</a>
                </div>
            </div>
        </div>
      `,true,backdropDismiss);
  }

    /**
     * 和上面弹窗一样的，只是为了和app保持一致，才添加这个方法
     * @param {string} content
     * @param {string} canel
     * @param {string} ok
     * @return {Promise<any>}
     */
    confirm2(content: string, ok: string = '确定', canel: string = '取消') {
        return this.confirm(content,ok,canel);
    }

    /**
     * 中部提醒弹窗，只有“我知道了”按钮
     * @param {string} text
     * @return {Promise<any>}
     */
    confirm3(text) {
        return this.show(`
           <div class="popupWrap pr">
                    <div class="popupCon pt35 pb35 pr15 pl15">
                        <p class="c666">${text}</p>
                    </div>
                    <div class="popupBtn">
                        <a class="border bdt bdr bdc_ddd orange alert-submit">我知道了</a>
                    </div>
                </div>
            
            `);
    }

}
