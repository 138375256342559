import {Injectable} from '@angular/core';
import {ClientPlatform} from './client-platform.service';
import {JcobUtilService} from './jcobUtil.service';
import {defaultPromotion, VIP_LEVEL} from '../model/const-config.model';
import {AppConfig} from './app-config.service';
import {Title} from '@angular/platform-browser';
import {StorageService} from './storage.service';

// declare var Jcob:any;

@Injectable()
export class RootService {

    public rootObj:any = {};

    constructor(
      public platform: ClientPlatform,
      public jcob: JcobUtilService,
      public appConfig:AppConfig,
      public title:Title,
      public storageService:StorageService,
    ) {
    }

    set(key:string, value:any):void {
        this.rootObj[key] = value;
    }

    get(key:string):string {
        return this.rootObj[key] || false;
    }

    getRoot(){
        return this.rootObj;
    }


    remove(key:string):any {
        delete this.rootObj[key];
    }

    clear() {
        this.rootObj = {};
    }


    isIOS(){
        return this.platform.isIOS;
    }

    isAndroid(){
        return this.platform.isAndroid;
    }

    isFromApp(){
        return this.getUrlParams("from")=='app';
    }

  /**
   * 获得浏览器参数
   * @param e
   * @returns {string}
   */
    getUrlParams (e) {//要传入key值
      let url = window.location.href;
      if (url.indexOf('?') == -1) {
        return;
      }
      let params = url.split('?')[1];
      let param = params.split('&');
      for (let i = 0; i < param.length; i++) {
        let strs = param[i].split('=');
        if (strs[0] == e) {
          return strs[1];
        }
      }
    }


  /**
   * 保存用户缓存
   * 信息存在2个地方，
   * 1、存在内存中 rootService.getRoot().userInfo
   * 2、存在本地 localStorage里的 jcob_userInfo
   * 其中存在内存的信息会详细点
   */
  public saveUserCache(data) {

    let $rootScope = this.getRoot();
    $rootScope.userCenterTimer = new Date().getTime();
    let userInfo = $rootScope.userInfo = {
      id: data.userInfo.memberId,
      aicaiId: data.userInfo.aicaiId,
      realNickName: data.userInfo.nickName || '未设置',
      account: data.userInfo.account,
      nickName: data.userInfo.nickName || data.userInfo.account,
      describe: data.userInfo.describe || '未设置',
      isExpert: data.userInfo.level > 0 ? true : false,
      userLevel: data.userInfo.level,
      idCard: data.userInfo.certNo || '未设置',
      realName: data.userInfo.realName || '未设置',
      telephone: data.userInfo.phone || '',
      phoneCheck: data.userInfo.phoneCheck || false,
      userIcon: data.userInfo.icon,
      attentionMe: data.userInfo.attentionMe,
      attentionOther: data.userInfo.attentionOther,
      expertApplyProgress: data.expertApplyProgress,
      switchOpen: data.switchOpen,
      qiuFlag: data.userInfo.qiuFlag,
      lotteryWhiteUser: data.lotteryWhiteUsers,
      proxy: data.proxy || 0,         //普通0 免费合伙人1 付费合伙人2 如果失效了则是 -1或-2
      bindedWX:data.bindedWX || '未绑定',    //账号是否绑定微信号，为空表示：未绑定；有则显示微信号
      passwordSet:data.userInfo.passwordSet,   //是否设置过密码  0否  1是

    };

    let oldLevel = this.jcob.userInfoCache.load('oldLevel') || {};
    if (typeof this.jcob.userInfoCache.load('userLevel') == 'number') {
      oldLevel[this.jcob.userInfoCache.load('id')] = this.jcob.userInfoCache.load('userLevel');
    } else {
      oldLevel[userInfo.id] = userInfo.userLevel;
    }
    $rootScope.userInfo.oldLevel = oldLevel;
    // $rootScope.userInfo.oldLevel = typeof Jcob.userInfoCache.load('userLevel')=='number'?Jcob.userInfoCache.load('userLevel'):data.userInfo.level;
    for (let p in userInfo) {
      this.jcob.userInfoCache.save(p, userInfo[p]);
    }
    $rootScope.userInfo.passwordStrength = data.userInfo.passwordStrength;
    // $rootScope.userInfo.registerType = data.userInfo.registerType;
    // $rootScope.userInfo.myPublishPlanCountNotOpen = data.myPublishPlanCountNotOpen;
    // $rootScope.userInfo.myPublishPlanCountMatching = data.myPublishPlanCountMatching;
    // $rootScope.userInfo.myLookedPlanCountMatching = data.myLookedPlanCountMatching;
    // $rootScope.userInfo.myLookedPlanCountNotOpen = data.myLookedPlanCountNotOpen;
    $rootScope.userInfo.huoyanWallet = data.huoyanWallet;
    $rootScope.userInfo.ableBalance = data.ableBalance ? this.jcob.toDecimal2(data.ableBalance) : "0.00";
    $rootScope.userInfo.totalBalance = data.totalBalance ? this.jcob.toDecimal2(data.totalBalance) : "0.00";
    $rootScope.userInfo.todayProfit = data.todayProfit ? this.jcob.toDecimal2(data.todayProfit) : "0.00";
    // $rootScope.userInfo.buyHuoYanSwitch = data.buyHuoYanSwitch || false;
    // $rootScope.userInfo.tiKuanSwitch = data.tiKuanSwitch || false;
    $rootScope.userInfo.versionSwitch = data.versionSwitch || false;
    // $rootScope.userInfo.myJoiningRoomCount = data.myJoiningRoomCount;
    $rootScope.userInfo.attentionMeCount = data.attentionMeCount;
    $rootScope.userInfo.attentionOtherCount = data.attentionOtherCount;
    $rootScope.userInfo.tjExpertSummary = data.tjExpertSummary;
    $rootScope.userInfo.mySnsCount = data.mySnsCount;
    $rootScope.userInfo.followMatchCount = data.followMatchCount;
    $rootScope.userInfo.countUnOpenRace = data.countUnOpenRace;
    // $rootScope.userInfo.earnSp = data.earnSp;
    $rootScope.userInfo.expertMonthCount = data.expertMonthCount;

    $rootScope.showApplyExpert = data.showApplyExpert && data.showApplyExpert != '0';

    //保存会员相关信息
    if(data.vipInfo) {

      this.saveVipInfo(data.vipInfo);
    }
  }

  //用户数据是否需要刷新
  //1分钟刷新数据
  public userInfoNeedUpdate() {
    return !this.getRoot().userCenterTimer || (this.getRoot().userCenterTimer + 60 * 1000) < new Date().getTime() || this.getRoot().needUpdate;
  }

  //设置用户中心信息需要刷新
  public setUserInfoNeedUpdate() {
    this.getRoot().needUpdate = true;
  }

  /**
   * 保存vip信息
   */
  saveVipInfo(vipInfo) {

    let userInfo = this.getUserInfo();
    //保存会员相关信息
    if(vipInfo && userInfo) {

      vipInfo.timeThrough = (((new Date().getTime() - vipInfo.startTime) / (vipInfo.endTime - vipInfo.startTime)) * 100).toFixed(2);      //会员已用时间百分比
      vipInfo.isVipTry = vipInfo.vipStatus == 1 && vipInfo.vipMemberLevel == VIP_LEVEL.super_vip.index;           //是试用版vip
      userInfo.vipInfo = vipInfo;
      userInfo.vipLevel = vipInfo.vipMemberLevel;

      this.jcob.userInfoCache.save('vipInfo', vipInfo);
      this.jcob.userInfoCache.save('vipLevel', vipInfo.vipMemberLevel);
    }
  }

  /**
   * 获取用户信息
   */
  public getUserInfo() {
    return this.getRoot() && this.getRoot().userInfo;
  }

  /**
   *  获取vip信息
   *  返回的对象为vip实体类： VipMemberDetailResult
   */
  public getVipInfo() {

    let userInfo = this.getUserInfo();

    return userInfo && userInfo.vipInfo;
  }

  /**
   * 获取用户vip等级
   * 0 普通会员(没有会员)
   * 1 红单会员
   * 2 超级红单会员
   * @link VipMemberLevel
   */
  /**
   *  获取vip信息
   */
  public getVipLevel() {

    let userInfo = this.getUserInfo();

    return userInfo && (userInfo.vipLevel || 0);
  }

  //判断现在是否是vip
  //如果是vip 则返回当前vip等级 否则返回0
  public isVipNow() {

    if(this.getVipLevel() == 0) return 0;

    if(!this.getVipInfo() || this.getVipInfo().vipStatus == 2) return 0;

    return this.getVipLevel();

  }

  /**
   * 判断是不是合伙人
   * @returns {any}
   */
  get proxy() {
    return this.getUserInfo() && this.appConfig.showProxy && this.getUserInfo().proxy ;
  }

    /**
     * 判断是否绑定微信号
     * @returns {string}
     */
    get bindedWX() {
        return this.getUserInfo() && this.getUserInfo().bindedWX;
    }

    /**
     * 修改密码设置信息
     */
    public changePasswordSet(){

        let $rootScope = this.getRoot();

        $rootScope.userInfo.passwordSet = 1;

        this.jcob.userInfoCache.save('passwordSet', 1);

    }

    /**
     * 获取微信客服信息
     * @return {string}
     */
    public get promotion() {
        return this.get("promotion") || defaultPromotion;
    }
    public set promotion(promotion) {
        //使用线上获取的修改掉默认的
        Object.assign(defaultPromotion,promotion);
        this.set("promotion",defaultPromotion);
    }

    /**
     * 是否显示申请红人入口
     */
    public get showApplyExpert() {
       return this.get("showApplyExpert") || 1;
    }

    //上架包app
    isFromIosApp(){

        if(this.getRoot().isFromIosApp) {
            return true;
        }
        this.getRoot().isFromIosApp = this.getUrlParams("sh")=='1'
                || ((this.getUrlParams("f_cv") == '6.9.1')) && this.appConfig.agentId == 2335034;   //处理现ios上架包服务中心的bug
        return this.getRoot().isFromIosApp;
    }

    //需要显示的appName
    get appNameToShow(){

        if(this.appConfig.isXd){
            return "专家推荐";
        }

        if(this.getRoot().appName) {
            return this.getRoot().appName;
        }

        //如果url带了appName，用url带的
        let appName = this.getUrlParams("appName");
        if(appName) {
            this.getRoot().appName = this.getUrlParams("appName");
        }else {
            //否则根据渠道默认

            this.getRoot().appName = this.storageService.get("appName") || '体坛周报';

            //ios马甲包的用户中心默认使用
            /*if(location.href.indexOf("/service/ios") != -1)
                this.getRoot().appName = '赢球体育';

            //处理ios上架马甲包
            if(this.appConfig.agentId == 2335241) {
                if(this.getUrlParams("f_cv") == '7.0.0') {
                    this.getRoot().appName = '文璇迎球体育';
                }else {
                    this.getRoot().appName = '赢球体育';
                }
            }else if(this.appConfig.agentId == 2335299) {
                this.getRoot().appName = '拓广体育竞猜';
            }else if(this.appConfig.agentId == 2335361) {
                this.getRoot().appName = '料球体育';
            }else if(this.appConfig.agentId == 2335341) {
                this.getRoot().appName = '足球明星';
            }else if(this.appConfig.agentId == 2335376) {
                this.getRoot().appName = '体坛+';
            }*/
        }

        this.storageService.set("appName",this.getRoot().appName);
        this.title.setTitle(this.getRoot().appName);
        return this.getRoot().appName;
    }

    //需要显示的公司名
    get appCompanyToShow(){

        if(this.getRoot().companyName) {
            return this.getRoot().companyName;
        }

        this.getRoot().companyName = '深圳市天盈互动网络技术有限公司';

        //ios马甲包的用户中心默认使用
        if(location.href.indexOf("/service/ios") != -1)
            this.getRoot().companyName = '大连文璇网络科技有限公司';

        //处理ios上架马甲包
        if(this.appConfig.agentId == 2335241) {
            this.getRoot().companyName = '大连文璇网络科技有限公司';
        }else if(this.appConfig.agentId == 2335299) {
            this.getRoot().companyName = '盐城拓广商贸有限公司';
        }else if(this.appConfig.agentId == 2335361) {
            this.getRoot().companyName = '成都市乐蜂炫彩科技有限公司';
        }else if(this.appConfig.agentId == 2335341) {
            this.getRoot().companyName = '成都亚讯络科技有限公司';
        }

        return this.getRoot().companyName;
    }

    //初始化app名还有公司名等
    public initAppInfo() {
        this.isFromIosApp();
        this.appNameToShow;
        this.appCompanyToShow
    }


}
