import {Injectable} from '@angular/core';
import 'rxjs/add/observable/from';
import {Events as IonicEvents} from "@ionic/angular";

@Injectable()
export class Events {

    constructor(public event: IonicEvents) {
    }

    //订阅事件
    subscribe(name, listener) {

        this.event.subscribe(name, listener);

        return {
            unsubscribe: () => {
                this.event.unsubscribe(name, listener);
            }
        }

    }

    //取消某事件的订阅  如果传了listener，则取消对应的方法订阅
    unsubscribe(name, listener?) {
        this.event.unsubscribe(name,listener);
    }

    publish(name, ...args) {
        this.event.publish(name,...args);
    }
}
