import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import 'rxjs/add/operator/toPromise';
import {AppConfig} from './app-config.service';
import {Router} from '@angular/router';
import {AlertService} from './alert.service';
import {LoginCacheService} from '../template/account/login/login-cache.service';
import {StorageService} from './storage.service';
import {RootService} from "./root.service";
// import {b} from '../model/const-config.model';
// declare let require: any;
// const md5 = require('js-md5');

@Injectable()
export class AppHttp {

  constructor(private http:HttpClient,
              private appConfig:AppConfig,
              private router:Router,
              private loginCache:LoginCacheService,
              private alert:AlertService,
              private storageService:StorageService,
              public rootService: RootService,
  ) {
  }

  get(url: string, options?:any):Promise<any> {

    url = this.appConfig.apiHost + url;
    let reqFun = (tryCount)=>{
        return this.http.get(url, this.generateOption(options))
                .toPromise()
                .then((res:HttpResponse<any>) => this.responseSuccess(res),
                        (res:HttpErrorResponse) => this.responseError(res))
                // .catch(res=>this.signErrorWithTime(res,tryCount,reqFun));
    };
    return reqFun(1);
  }

  post(url: string, body: any, options?: any):Promise<any> {

      url = this.appConfig.apiHost + url;
      let reqFun = (tryCount) => {
          return this.http.post(url, body, this.generateOption(options))
                  .toPromise()
                  .then((res:HttpResponse<any>) => this.responseSuccess(res),
                          (res:HttpErrorResponse) => this.responseError(res))
                  // .catch(res => this.signErrorWithTime(res,tryCount,reqFun));
      };
      return reqFun(1);
  }

  sportDataPost(url: string, body: any, options?: any):any{
    let option = this.generateOption(options);

    //符老师后端大数据统计，增加会话id和用户id，盈球的接口有别的方法获取,不特别使用该参数，soprtData才采用数据传递方式
    option['params']['jcobSessionId'] = this.appConfig.jcobSessionId;
    let userInfo = this.rootService.getRoot().userInfo;
    if(userInfo && userInfo['id']){
        option['params']['userId'] = userInfo['id'];
    }
    return this.post(url,body,option);
  }

  responseSuccess(result:HttpResponse<any>) {
    // console.log(result);
    result && result['no_login_and_exit'] && this.loginCache.goLoginAndClearCache(this.router.url);

    //判断是不是经过了状态装换
    const headers = result.headers ,
            status = headers.get("status-transform-from");
    if(status) {
      console.log("http状态经过了转换" + status);
      return this.responseError(new HttpErrorResponse({
          error:result.body,
          headers:headers,
          status:+status,
          url:result.url,
      }));
    }

    return Promise.resolve(result.body);
  }


  responseError(response:HttpErrorResponse) {
      // console.log(response);
    let data:any = response.error;
    if(response.status && (response.status==500||response.status==503
        ||response.status==408||response.status==504||response.status==-1)) {
      //此时data 为string
      this.alert.msg("网络异常，请稍后再试");
    }else if (data) {
      if(data.errorCode) {
        if(data.errorCode=="no_login" || data.errorCode=="no_login_and_exit" || data.errorCode=="no_token") {

          this.loginCache.goLoginAndClearCache(this.router.url,false);

          data.msg = null;
          return Promise.reject(data);
        }
      }else {
        if(typeof data == 'string')
          data = {};
        data.errorCode = "system_error";
        data.errorMsg = data.msg = "系统异常，请稍后再试";
      }
    }else {
      data = {};
      data.errorCode = "system_error";
      data.errorMsg = data.msg = "系统异常，请稍后再试";
    }
    return Promise.reject(data);
  }

  //因为本地系统时间和服务器系统时间不一致的错误，做时间修正
  signErrorWithTime(res,tryCount,callback) {

      if(res.code == 'timeError' && tryCount < 3) {
          this.appConfig.sysTimeExc = res.time - Date.now();
          this.storageService.set("s",this.appConfig.sysTimeExc+'');
          tryCount++;
          return callback(tryCount);
      }if(res.code == 'signError'){
          //签名错误，刷新应用
          this.showError();
          console.log("签名错误，重新刷新");
      }
      return Promise.reject(res);
  }

  generateOption(options: any):Object {
    let params:any = {
      agentId:this.appConfig.agentId,
      platform:'wap',
      // version:this.appConfig.version,
      appVersion:this.appConfig.appVersion,
      from:this.appConfig.fromPlatform,
      // timestamp:Date.now()+this.appConfig.sysTimeExc,
    };

    // params.sign =  this.getSign(params);

    if(!options) {
      options = {params:params};
    }else if(!options.params) {
      options.params = params;
    }else {
      for (let name in params) {
        options.params[name] = params[name];
      }
    }

    //将返回封装成完整的响应体，因为正常返回需要用到header
    options.observe = 'response';

    this.clearNullAndUndefinedValue(options.params);
    return options;
  }


  clearNullAndUndefinedValue(value){
    if(value){
      Object.keys(value).forEach(key=>{
        if(value[key] == null || value[key] == undefined || value[key] === ''){
          delete value[key];
        }
        if(value[key] instanceof Array && value[key].length < 1){
          delete value[key];
        }
      })
    }
  }

  //获取sign
  getSign(params) {
    // return md5(`${params.appVersion}_${params.platform}__${params.timestamp}_${b.a}`);
    return '';
  }

    private showError() {
        this.alert.confirm("发生未知错误，请刷新页面","立即刷新","稍后手动刷新",false).then(()=>{
            self.location.reload();
        }).catch(()=>{});
    }


}
