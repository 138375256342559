import {Injectable} from '@angular/core';
import {AppHttp} from "./app-http.service";
import {AppConfig} from "./app-config.service";
import {RootService} from "./root.service";
import {StorageService} from "./storage.service";
import {NavigationEnd, NavigationStart, Router} from "@angular/router";

//tab 页面位置定位
export const TAB_LOCATION = {
    raceType: 1,
    fbGameType: 2,
    bkGameType: 3,
    ftDetail: 5,        //赛事详情会加状态后缀
    bkDetail: 6,
    news: 7,
    vip: 8,
    ftLeagueDetail: 9,
    bkLeagueDetail: 10,
    homePlan: 11,
};
/**
 * 处理和统计用户行为习惯
 */
@Injectable()
export class UserHabitService {

    private USER_HABIT_CACHE_KEY = "USER_HABIT_CACHE_KEY";

    //当前登录用户id
    private get userId() {
        return this.root.getUserInfo() && this.root.getUserInfo().id;
    }
    //进入页面在n秒内
    private pageEnter = false;
    //settimeout任务
    private pageEnterTimeTask;

    constructor(
            private appHttp: AppHttp,
            private appConfig: AppConfig,
            private root: RootService,
            private localStorage:StorageService,
            private router:Router,
    ) {
        //路由缓存页面 自动滚动到历史位置
        this.router.events.subscribe((event) => {

            if (event instanceof NavigationStart) {
                clearTimeout(this.pageEnterTimeTask);
            }

            // console.log(event);

            if (event instanceof NavigationEnd) {

                // console.log(event);

                this.pageEnter = true;
                this.pageEnterTimeTask = setTimeout(()=>{
                    this.pageEnter = false;
                },5000);
            }
        });

    }

    /**
     * 获取默认的tab
     * @param local tab位置 @link TAB_LOCATION
     */
    public getDefaultTab(local) {
        return this.localStorage.get(this.USER_HABIT_CACHE_KEY + "_default_tab_" + local);
    }

    /**
     * 用户默认偏向篮球
     * @return {(local) => string}
     */
    public get isBk() {
        return this.getDefaultTab(TAB_LOCATION.raceType) == '2';
    }

    /**
     * 刚进入页面tab初始化
     */
    public tabInit() {

        clearTimeout(this.pageEnterTimeTask);

        this.pageEnter = true;
        this.pageEnterTimeTask = setTimeout(()=>{
            this.pageEnter = false;
        },5000);
    }

    /**
     * 用户点击tab
     * @param local tab位置 @link TAB_LOCATION
     * @param value tab值
     */
    public clickTab(local,value) {

        // console.log("改变tab",local,value);

        if(!local || (!value && value != 0)) return;

        if(this.pageEnter) {

            // console.log("短时切换tab",value);

            setTimeout(()=>{
                //从cache中获取当前位置点击记录
                const key = this.USER_HABIT_CACHE_KEY + "_" + local;
                let tabCount = this.localStorage.getObject(key) || {};

                if(!tabCount[value]) tabCount[value] = [];

                const now = Date.now();

                tabCount[value].push(now);

                //过滤掉1小时前的记录
                tabCount[value] = tabCount[value].filter(value => now - value < 60 * 60 * 1000);

                //超过3次就将它保存成默认tab
                if(tabCount[value].length >= 3) {
                    console.log("点击超过3次，保存tab记录");
                    this.localStorage.set(this.USER_HABIT_CACHE_KEY + "_default_tab_" + local,value);
                }

                //清掉其他的点击记录
                let tempArr = tabCount[value];
                tabCount = {};
                tabCount[value] = tempArr;

                //将tab保存
                this.localStorage.setObject(key,tabCount);
            },100);
        }
    }

    /**
     * -----------------------http请求-----------------------
     */
    private getUserHabitData(memberId) {
        this.appHttp.get("/api/user/habit/data/"+memberId);
    }

}
