import {Injectable} from '@angular/core';

/**
 * jcob 用户信息缓存工具服务
 */

declare let Jcob:any;

@Injectable()
export class JcobUtilService {

    //本地缓存键
    private cacheKey = 'jcob_userInfo';

    //用户信息缓存键
    private userInfoKey = 'userInfoCache';
    //登录信息缓存键
    private loginDataKey = 'loginCache';

	constructor() {}

    //Jcob util 用户信息全局初始化
    public initCache() {
        Jcob.initCache(this.userInfoKey, this.cacheKey);
    }

    //用户信息缓存对象
    get userInfoCache() {

	    return {
            /**
             *  缓存用户信息
             * @param key 键
             * @param value 值
             */
	        save: (key, value)=>{
	            return Jcob[this.userInfoKey].save(key, value);
            },
            /**
             * 加载缓存中对应的数据
             * @param key
             */
            load: (key)=>{
                return Jcob[this.userInfoKey].load(key);
            },
            /**
             * 加载缓存中所有的数据
             */
            loadAll: ()=>{
                return Jcob[this.userInfoKey].loadAll();
            },
            /**
             * 删除缓存中对应的数据
             * @param key
             */
            remove: (key)=>{
                return Jcob[this.userInfoKey].remove(key);
            },
            /**
             * 清空缓存中所有的数据
             */
            clear: ()=>{
                return Jcob[this.userInfoKey].clear();
            },

        }

    }

    //登录信息缓存对象
    get loginCache() {
	    return {
            /**
             * 保存登录账号
             * @param account 账号
             * @param imageUrl 头像
             */
	        save: (account,imageUrl)=>{
	            return Jcob[this.loginDataKey].save(account,imageUrl);
            },
            /**
             * 获取所有登录缓存数据
             */
            loadRecentData: ()=>{
                return Jcob[this.loginDataKey].loadRecentData();
            },
            /**
             * 清空登录缓存
             */
            clear: ()=>{
                return Jcob[this.loginDataKey].clear();
            },
            /**
             * 判断你缓存中是否存在账号
             */
            isPhoneExist: (account)=>{
                return Jcob[this.loginDataKey].isPhoneExist(account);
            }
        }
    }

    /**
     * 数字转换
     * @param number
     */
    public toDecimal2(number) {
	    return Jcob.toDecimal2(number);
    }

    /**
     * 添加关注的赛事id
     * @param id 赛事id
     * @param type 赛事类型
     */
    public addFollowId(id,type) {
        return Jcob.addFollowId(id,type);
    }

    /**
     * 添加关注的赛事id
     * @param id 赛事id
     * @param type 赛事类型
     */
    public removeFollowId(id,type) {
        return Jcob.removeFollowId(id,type);
    }

    /**
     * 添加关注的赛事id
     * @param id 赛事id
     * @param type 赛事类型
     */
    public hasFollowId(id,type) {
        return Jcob.hasFollowId(id,type);
    }

}
