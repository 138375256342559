import { Injectable } from '@angular/core';

@Injectable()
 export class CookieService {

  constructor() {

  }

  /*获得cookie*/
  public get(key:string):string {
    let name = key + "=";
    let ca = document.cookie.split(';');
    for(let i=0; i<ca.length; i++) {
      let c = ca[i].trim();
      if (c.indexOf(name)==0)
        return c.substring(name.length,c.length);

    }
    return null;
  }

  /*设置cookie
  exdays 过期时间单位/天 不传则关闭浏览器就过期
  */
  public put(key:string,value:string,exdays:number=0) {
    let domain = this.getLocation();
    if(exdays != 0) {
      let d = new Date();
      d.setTime(d.getTime()+(exdays*24*60*60*1000));
      let expires = "expires="+d.toUTCString();
      document.cookie = key + "=" + value + "; " + expires + ";domain="+domain+";path=/";
    } else {
      document.cookie = key + "=" + value + ";domain="+domain+";path=/";
    }
  }
  public getLocation(){
    let arr = document.domain.split('.');
    if(arr.length <= 1){
        return document.domain;
    }
    let arrStr = arr[arr.length-2]+'.'+arr[arr.length-1];;
    return arrStr;
  }

  /*删除cookie*/
  public delCookie(key:string) {
    this.put(key,' ',-1);
  }

  /*
  * 删除cookie
  * 此方法会删除domian为 .xxxxx.com 的cookie
  * */
  public delCookie2(key:string) {
    let d = new Date();
    d.setTime(d.getTime()-1);

    let domain = document.domain;

    domain = '.' + domain.replace(/.*?\./,'');
    let path = '/';

    let expires = "expires="+d.toUTCString();
    // console.log(key + "=0" + ";domain="+domain+";" + expires);
    document.cookie = key + "=0" + ";domain="+domain+";path="+ path+";"+ expires;
    document.cookie = key + "=0" + ";domain="+document.domain+";path="+ path +";"+ expires;
    this.delCookie(key);
  }

}
