/**
 * Created by hh on 2017/7/29.
 */
import {Injectable} from '@angular/core';
import {AppHttp} from "../../../services/app-http.service";
import {RootService} from "../../../services/root.service";
import {AlertService} from "../../../services/alert.service";
import {NavCtrlService} from "../../../services/nav-controller.service";
import {LoginCacheService} from "../../account/login/login-cache.service";
import {EncryptService} from "../../../services/encrypt.service";

declare var Jcob: any;

@Injectable()
export class UserCenterService {

    // private passwordChangeData: any = {};


    //验证码缓存
    private _passwordVerifyCodeCache:string;

    constructor(public http: AppHttp,
                private rootService: RootService,
                private alertService: AlertService,
                public navCtrl: NavCtrlService,
                public loginCache: LoginCacheService,
                public encrypt: EncryptService,
                ) {
    }

    /**
     * 修改密码需要的验证码
     * 主要是修改密码不是在同一个页面中验证的
     * 需要缓存验证码，然后提交修改密码时再次验证
     */
    get passwordVerifyCodeCache() {
        return this._passwordVerifyCodeCache;
    }
    set passwordVerifyCodeCache(code) {
        this._passwordVerifyCodeCache = code;
    }
    clearPasswordVerifyCodeCache() {
        this._passwordVerifyCodeCache = null;
    }


    index(type = 1) {

        return new Promise((rs, rj) => {
            this.http.get("/api/uc/index", {params: {type}}).then((data) => {

                this.rootService.saveUserCache(data);
                rs(data);
            }).catch((data) => {
                console.info(data);
                this.loginCache.clearLoginCache();
                rj(data);
            });
        })
    }

    //聊天室查询用户状态
    queryUserStatus(userId) {
        return this.http.get('/api/char/userStatus/' + userId);
    }

    //聊天室用户禁言   userId:被禁言用id , operateUserId:管理员id
    chatUserForbiddenWords(userId, status, operateUserId) {
        return this.http.post('/api/char/chatOperateForbiddenWords', {
            userId: userId,
            status: status,
            operateUserId: operateUserId
        });
    }


    queryMyLeagueList() {
        return this.http.get('/api/uc/my/leagueList');
    }

    checkLevel() {
        let $rootScope = this.rootService.getRoot();

        //未登录
        if (!this.rootService.getUserInfo() || !this.rootService.getUserInfo().id) return;

        if (!$rootScope || $rootScope.userInfo)
            return;
        let oldLevel = $rootScope.userInfo.oldLevel[$rootScope.userInfo.id];
        let newLevel = $rootScope.userInfo.userLevel;
        if (oldLevel != null && newLevel != oldLevel && oldLevel != undefined) {
            // console.info('=========level change==========');
            if (newLevel > oldLevel) {//升级
                // console.info('升级啦!!!!!');
                let template;
                switch (newLevel) {
                    case 1:
                        template = `
                            <div class="popup_Box">
                                <div class="popup_Box_t">
                                    <img class="popup2" src="assets/img/shiyanshi.png" alt="">
                                    <h3>恭喜您获得发布免费推荐资格!</h3>
                                    <p class="p1">当月发布推荐≥30个，发单天数≥15天，月回报率≥0%，且月回报率排名前20，即可成为战绩红人</p>
                                </div>
                            </div>
                        `;
                        break;
                    case 2:
                        template = `
                            <div class="popup_Box">
                                <div class="popup_Box_t">
                                    <img class="popup2" src="assets/img/zhengshi.png" alt="">
                                    <h3>恭喜您成为战绩红人!</h3>
                                    <p class="p1">您可发布付费推荐，其它用户查看您的推荐，即可获得高达30%收益分成！</p>
                                </div>
                            </div>
                        `;
                        break;
                        // case 3:
                        //     template = `
                        //         <div class="popup_Box">
                        //             <div class="popup_Box_t">
                        //                 <img class="popup2" src="assets/img/zhengshi.png" alt="">
                        //                 <h3>恭喜您成为带V战绩红人!</h3>
                        //                 <p class="p1">您发布推荐时金币最大可设为30个了!</p>
                        //             </div>
                        //         </div>
                        //     `;
                        //     break;
                    default:
                        // console.info('出了什么错呢!');
                        return;
                }
                this.levelUp(template, '发布推荐', '知道了').then(data => {
                    if (data) {
                        this.navCtrl.push("PublishPlanTab", {matchType: $rootScope.userInfo.qiuFlag || 1, tab: 0});
                    }
                });
            } else {//降级
                let templateBegin = '<div class="popup_Box">' + '<div class="popup_Box_t">' +
                        '<img class="popup1" src="assets/img/downgrade.png" alt="">' +
                        '<p class="p1">';
                let templateEnd = '</p></div></div>';
                this.queryLastLevelLog().then((data) => {
                    let template;
                    if (data.levelLog.reason) {
                        switch (newLevel) {
                            case 0:
                                if (oldLevel == 1) {//实验室->普通
                                    template = "因您成为实验室用户后，" + data.levelLog.reason + "，您已被降为普通用户！";
                                } else if (oldLevel > 1) {//红人->普通
                                    template = "因您成为战绩红人后，" + data.levelLog.reason + "，您已被降为普通用户！";
                                }
                                break;
                            case 1:
                                template = "因您成为战绩红人后，" + data.levelLog.reason + "，您已被降为实验室用户！";
                                break;
                            default:
                                template = "很抱歉您已被降级";
                        }
                    } else {
                        switch (newLevel) {
                            case 0:
                                if (oldLevel == 1) {//实验室->普通
                                    template = "因您成为实验室用户后，所发推荐未达到战绩红人要求或推荐理由不规范，您已被降为普通用户！";
                                } else if (oldLevel > 1) {//红人->普通
                                    template = "因您成为战绩红人后，发布推荐时填写的推荐理由不规范或命中不达标，您已被降为普通用户！";
                                }
                                break;
                            case 1:
                                template = "因您成为战绩红人后，所发推荐未达到战绩红人要求或推荐理由不规范，您已被降为实验室用户！";
                                break;
                            default:
                                template = "很抱歉您已被降级";
                        }
                    }
                    template = templateBegin + template + templateEnd;
                    this.levelUp(template, '知道了').then(data1 => {
                        if (data1) {
                        }
                    });
                });
            }
            $rootScope.userInfo.oldLevel = newLevel;
        }
    }

    levelUp(content: string, okText: string = '确定', cancelText: string = '取消'): Promise<any> {
        content = `
                <div class="popupWrap">
                   ${content}
                    <div class="popupBtn">
                        <a  class="border bdt bdr bdc_ddd alert-close">${cancelText}</a>
                        <a  class="orange border bdt bdr bdc_ddd alert-submit">${okText}</a>
                    </div>
                </div>
        `;
        return new Promise((resolve, reject) => {
            this.alertService.show(content).then((data: any) => {
                if (data.type == 'submit') resolve(data);
            });
        })
    }

    queryExpertInfoById(id) {
        return this.http.get("/api/expert/info/" + id);
    }

    changeNickName(nickName,modifyType) {
        return this.http.post("/api/uc/modify/nickName", {nickName: nickName, modifyType:modifyType});
    }

    changeDescribe(describe) {
        return this.http.post("/api/uc/modify/describe", {describe: describe});
    }

    verifyPhone(phone, code) {
        return this.http.post("/api/uc/modify/verifyPhone/verifyCode", {phone: phone, code: code});
    }

    //验证码发送
    sendVerifyPasswordCode(phone) {
        return this.http.post("/api/uc/modify/changePassword/sendMessage", {phone: phone})
    }

    //给未注册的手机号发送验证码---->取消唯一性验证
    sendVerifyPhoneCode(phone) {
        return this.http.post("/api/uc/modify/verifyPhone/sendMessage", {phone: phone});
    }

    //验证码发送end
    verifyPassword(phone, code) {
        return this.http.post("/api/uc/modify/changePassword/verifyCode", {phone: phone, code: code});
    }

    /*setPasswordChangeData(key: string, value?: any) {
        if (!key) {
            this.passwordChangeData = {};
        } else {
            this.passwordChangeData[key] = value;
        }
    }

    getPasswordChangeData() {
        return this.passwordChangeData;
    }*/

    //首次设置密码
    commitSetPassword(phone, password, code) {
        return this.http.post('/api/uc/regiester/pwdSet', {phone, password: this.encrypt.encrypt(password), code});
    }

    //修改密码
    commitNewPassword(phone, oldPassword, password, code) {
        return this.http.post("/api/uc/modify/changePassword", {phone, oldPassword, password: this.encrypt.encrypt(password), code
        });
    }

    completeUserInfo(realName, certNo, phone, verifyCode, upgrade = false, applyFxPatner = false) {
        return this.http.post("/api/uc/modify/userInfoComplete",
                {realName: realName, idCard: certNo, phone: phone, verifyCode: verifyCode, upgrade, applyFxPatner});
    }

    confirmUserInfo(realName, certNo, phone, verifyCode) {
        return this.http.post("/api/uc/modify/userInfoConfirm",
                {realName: realName, idCard: certNo, phone: phone, verifyCode: verifyCode});
    }

    // applyExpert(realName,certNo,verifyCode,applyReason,applyImageUrl){
    //     return this.http.post("/api/uc/expertApply",
    //         {realName:realName,certNo:certNo,verifyCode:verifyCode,applyReason:applyReason,applyImageUrl:applyImageUrl});
    // }
    checkIdCardUsedForExpert(qiuFlag) {
        return this.http.get("/api/uc/checkIdCardUsed/" + qiuFlag);
    }

    applyExpert2(applyReason, applyImageUrl, pickedId, frontImgPath, backImgPath) {
        return this.http.post("/api/uc/formalExpertApply",
                {
                    applyReason: applyReason,
                    applyImageUrl: applyImageUrl,
                    pickedId: pickedId,
                    frontImgPath: frontImgPath,
                    backImgPath: backImgPath
                });
    }

    queryApplyStatus() {
        return this.http.get("/api/uc/expertApply/applyStatus");
    }

    queryAttentionMe(expertId, pageNo) {
        return this.http.get('/api/expert/attentionMe/' + expertId + '/' + pageNo);
    }

    queryAttentionOther(expertId, pageNo) {
        return this.http.get('/api/expert/attentionOther/' + expertId + '/' + pageNo);
    }

    queryAttentionMeWithMember(expertId, pageNo) {
        return this.http.get('/api/uc/attentionMe/' + expertId + '/' + pageNo);
    }

    queryAttentionOtherWithMember(expertId, pageNo) {
        return this.http.get('/api/uc/attentionOther/' + expertId + '/' + pageNo);
    }

    /*    queryTop10FamousExpert() {
            return this.http.get("/api/famousExpert/top10");
        }*/

    queryTop10FormalExpert() {
        return this.http.get("/api/formalExpert/top10");
    }

    queryFormalExpertByType(type, lastDays, qiuFlag) {
        if (type == 'all') {
            return this.http.get("/api/expert/list/page/" + lastDays, {params: {qiuFlag: qiuFlag}});
        } else {
            return this.http.get("/api/formal/type/" + type, {params: {qiuFlag: qiuFlag, lastDays: lastDays}});
        }
    }

    queryMyAttentionForFormalExpert(pageNo) {
        return this.http.get('/api/myAttention/expert/list/' + pageNo);
    }

    queryMyAttentionForFamousExpert(pageNo) {
        return this.http.get('/api/myAttention/famousExpert/list/' + pageNo);
    }

    attentionExpert($params: any) {
        return this.http.post('/api/uc/attention', $params);
        // .then(()=> {
        //     localStorage.setItem("newFollowUser", "1");
        // }).catch((e)=> {
        //     if (e.errorCode != "no_token" && e.errorCode != 'uuid_error') {
        //         // $ionicLoading.show({template: '<div>' + e.errorMsg + '</div>', duration: 1500});
        //     }
        // });
    }

    /*attentionExperts(experts) {
        if (experts == null || experts.length == 0) {
            // $ionicLoading.show({template: '请选择要关注的专家', duration: 1500});
            return;
        }
        return this.http.get("/api/uc/attentionExperts", {params: {experts: experts.join()}}).then(() => {
            localStorage.setItem("newFollowUser", "1");
        });
    }*/

    /***
     * 服务中心-意见反馈
     * @param content 内容
     * @param index 反馈类型
     * @param picturePath 关联图片,使用 , 隔开每个url
     *
     * @returns {*}
     */
    feedBack(content,index=7,picturePath?) {
        return this.http.post('/api/uc/feedback/create',{content,index,picturePath});
    }

    queryMemberFeedBackList(pageNo) {
        return this.http.get('/api/uc/feedbackRecored/' + pageNo);
    }

    queryExpertReplayDetail(id) {
        return this.http.get('/api/expert/replay/detail/' + id);
    }

    updateReplayLike(id, index) {
        return this.http.post('/api/expert/replay/addLike/' + id, {type: index});
    }


   /* queryExpertHomeInfo(expertId, memberId) {
        return this.http.post("/api/expertHome", {expertId: expertId, memberId: memberId || 0});
    }

    queryFormalExpertScoreInfo(expertId, gameType, dateIndex, qiuFlag) {
        return this.http.post("/api/formalExpert/scoreInfo/" + expertId, {
            gameType: gameType,
            dateIndex: dateIndex,
            qiuFlag: qiuFlag
        });
    }
*/
    queryLeagueRecord(expertId, gameType, dateIndex, qiuFlag) {
        return this.http.post("/api/expertHome/leagueRecord/" + expertId, {
            gameType: gameType,
            dateIndex: dateIndex,
            qiuFlag: qiuFlag
        });
    }

    queryLeagueRecordDetail(pageNo, expertId, leagueId, gameId, qiuFlag, dateIndex) {
        return this.http.post("/api/expertHome/leagueRecordDetail/" + expertId + "/" + pageNo, {
            leagueId: leagueId,
            gameId: gameId,
            dateIndex: dateIndex,
            qiuFlag: qiuFlag
        });
    }

    queryLastLevelLog() {
        return this.http.get("/api/uc/my/queryLastLevelLog");
    }

    queryExpertHomePlan(planSearchInput, pageNo) {
        return this.http.post('/api/expertHome/plan/' + pageNo, planSearchInput);
    }


    isAttentionExpertNew(expertId) {
        return this.http.get('/api/interpretation/isAttentExpert/' + expertId);
    }

    attentionExpertNew(expertId) {
        return this.http.get('/api/uc/interpretation/attentExpert/' + expertId);
    }

    queryExpertArticleDetail(id) {
        return this.http.get('/api/expert/article/detail/forShare/' + id);
    }

    queryExpertInterNote(id, pageNo) {
        return this.http.get('/api/expert/interNote/' + id + '/' + pageNo || "1");
    }

    createInterNote(interpretationId, content, expertId) {
        return this.http.post('/api/expert/interNote/create', {
            expertMemberId: expertId,
            content: content,
            interpretationId: interpretationId
        });
    }


    myLookJieduList(planSearchInput, pageNo) {
        return this.http.post('/api/uc/interpretation/myLook/' + pageNo, planSearchInput);
    }

    myPublishJieduList(planSearchInput, pageNo) {
        return this.http.post('/api/uc/interpretation/myPublish/' + pageNo, planSearchInput);
    }

   /* queryReadings(option, pageNo) {
        return this.http.post('/api/interpretation/list/' + pageNo, option);
    }
*/
    queryMyMsgReplapy(pageNo, type, dateIndex) {
        return this.http.post('/api/uc/msgReply/' + pageNo, {dateIndex: dateIndex, type: type});
    }

    logout() {
        return this.http.get("/api/user/loginOut");
    }

    queryAccount() {
        return this.http.get("/api/uc/phoneChange/index");
    }

    idCheck(realName, idCard) {
        return this.http.post("/api/uc/phoneChange/checkId", {realName: realName, idCard: idCard});
    }

    commitNewPhone(phone, code) {
        return this.http.post("/api/uc/phoneChange/commitNewPhone", {phone: phone, code: code});
    }

   /* queryExpertScoreInfo(expertId) {
        return this.http.post("/api/cpz/queryExpertInfo", {expertId: expertId});
    }
*/
    queryCpzExpertHomeInter(pageNo, expertId, memberId) {
        return this.http.post("/api/expertHome/interpretation/" + pageNo, {
            expertId: expertId,
            memberId: memberId || 0,
            type: 0,
            fromCpz: true
        });
    }


    isLogin() {

        return this.loginCache.isLogin();

    }

    //专家页联赛分布
    queryExpertDistribution(expertId, qiuFlag) {
        return this.http.get(`/api/expert/distribution/${expertId}/${qiuFlag}`);
    }

    //专家页擅长联赛下的解读列表
    queryExpertGoodAtLeaguePlans(expertId,leagueId){
        return this.http.get(`/api/expert/goodAtLeague/${expertId}/${leagueId}`);
    }
    //famous/rankByRed/bestScore/{expertId}
    //专家页擅长联赛下的解读列表
    queryExpertRedBestScore(expertId){
        return this.http.get(`/api/famous/rankByRed/bestScore/${expertId}`);
    }

    /**
     * 获取专家套餐列表
     * @param params
     * @returns {Promise<any>}
     */
    getSetMealRecordList(params) {
        return this.http.post("/api/setMeal/record/list", params);
    }

    //查询名人排行榜-新接口
    queryWbFamousExpertList(type, pageNo, qiuFlag = 1, pageSize = 8) {//查询微博名人排行榜
        return this.http.get(`/api/famous/ranking/${qiuFlag}/${type}`, {params: {pageNo, pageSize}});
    }

    //查询名人排行榜-新接口
    queryExpertRankingList(type, pageNo, qiuFlag = 1, pageSize = 8) {//查询胜负彩奖金榜、大奖榜数据排行榜
        return this.http.get(`/api/famous/sfc/ranking/${qiuFlag}/${type}`, {params: {pageNo, pageSize}});
    }

    /**
     * 检测用户实名认证状态
     * 需再次实名认证的，会返回以前的实名信息
     * code:1,从未实名认证过，2，以前实名认证过，未参加新的实名认证，3，已经再次实名认证
     * msg:消息
     * certNo:身份证，code=2才有,下同
     * realName:真实姓名
     * phone:手机号
     */
    checkCompleteInfo() {
        return this.http.get('/api/uc/modify/checkComplete');
    }

    /*查询会员相关*/
    queryVipMemberDetail() {
        return this.http.get("/api/member/vip/info");
    }

    /**
     * 获取登陆广告
     * @param params
     * @returns {Promise<any>}
     */
    getLoginImg() {
        return this.http.get("/api/login/img");
    }

    /**
     * 获取资讯详情广告
     * @param params
     * @returns {Promise<any>}
     */
    getExpertReplayImg() {
        return this.http.get("/api/expert/replay/detail/img");
    }

    /**
     * 获取支付广告
     * @param params
     * @returns {Promise<any>}
     */
    getPayImg() {
        return this.http.get("/api/pay/img");
    }
  getPasswordSetInfo(){
      return this.http.get("/api/uc/password/setInfo")
  }
    /*查询会员相关*/
    /*queryVipMemberDetail(){
      return this.http.get("/api/member/vip/info");
    }*/

    //查询会员礼包发放状态及剩余
    queryVipPakLeft(vipMemberLevel) {
        return this.http.get("/api/vip/gpkDesc/" + vipMemberLevel);
    }

    //查询礼包纪录
    queryGiftRecord(pageNo, vipMemberId) {
        return this.http.post("/api/vip/giftRecord", {pageNo: pageNo, vipMemberId: vipMemberId});
    }

    //查询礼包详情
    queryGiftRecordDetail(giftPackgeId) {
        return this.http.get("/api/vip/giftRecordDetail/" + giftPackgeId);
    }

    //查询会员订购纪录
    queryVipOrderRecord(pageNo, vipMemberId) {
        return this.http.post("/api/vip/vipOrderRecord", {pageNo: pageNo, vipMemberId: vipMemberId});
    }

    //查询优惠券数量
    queryCouponNum() {
        return this.http.get("/api/query/couponNum");
    }

}
