import {EventEmitter, Injectable} from '@angular/core';
import {JsAutoLoadGuard} from './JsAutoLoadGuard';

/**
 * html2canvas插件使用
 */

// declare let html2canvas;

@Injectable()
export class Html2canvasService {

    //任务列表，主要用来缓存任务
    private loadingWtacher;        //加载中标志
	//临时容器
	private eleContainer;
	private html2canvasObj;

	constructor() {}

    /**
     * 将HTML转化成Base64图片
     * @param ele 需要转换的元素
     */
	public html2canvas(ele) {

	    if(!ele) return Promise.reject("没有ele");

	    return this.cloneUselyEle(ele).then((ele)=>{

            let option = {allowTaint: true,useCORS: true};

			return this.getHtml2cancas().then((html2canvas)=>{

				return html2canvas(ele,option).then((canvas)=>{

					this.clearContainer();
					if(canvas) {
						return canvas.toDataURL("image/png");
					}
					return null;
				}).catch((res)=>{
					console.log(res);
					this.clearContainer();
					return res;
				})
			})

		});

    }

    /**
     * 获取html2canvas 对象
     * @return {Promise<any>}
     */
    private getHtml2cancas():Promise<any>{
        return new Promise((rs,rj)=>{
            if(!this.html2canvasObj) {

                //js加载中，接口并发的情况
                if(this.loadingWtacher) {
                    const sub = this.loadingWtacher.subscribe(()=>{
                        rs(this.html2canvasObj);
                        sub.unsubscribe();
                    });
                    return;
                }

                this.loadingWtacher = new EventEmitter();
                new JsAutoLoadGuard().dynamicLoading("/assets/js/html2canvas.min.js").then(()=>{

                    this.html2canvasObj = (<any>window).html2canvas;
                    rs(this.html2canvasObj);

                    this.loadingWtacher.emit();
                    this.loadingWtacher = null;

                }).catch((e)=>{
                    rj("加载echarts失败")
                });
            }else {
                rs(this.html2canvasObj);
            }
        });
    }

    //克隆html代码
    private cloneUselyEle(ele) {

        return new Promise((rs,rj)=>{
			//因为直接在ionic 路由元素里面生成会报错，所以在元素外处理一下

			let container = document.createElement("div");
			container.style.position = 'relative';
			container.style.zIndex = '-1';

			if(!container || !ele) rj('初始化克隆元素失败');

			container.appendChild(ele.cloneNode(true));

			self.document.body.appendChild(container);
			this.eleContainer = container;

        	setTimeout(()=>{
        		rs(container.firstChild);
			},200);
		});

	}

	//清除临时容器
	private clearContainer() {

        this.eleContainer && self.document.body.removeChild(this.eleContainer);
        this.eleContainer = null;

	}

}
