import {Directive, EventEmitter, HostListener, Output} from "@angular/core";
import {CaptchaValidateService} from "./captcha-validate.service";

/**
 * 滑块验证指令，方便直接在html使用
 *
 * 使用方法：
 * 在页面所在module导入 CaptchaValidateModule 模块，然后直接使用：
 *  (captcha-check)="login()" ，验证成功后直接回调，
 *  如果需要失败处理，可以回调 (error)="xxx()"
 */
@Directive({
    selector:"[captcha-check]",
})
export class CaptchaValidateDirective{

    @Output('captcha-check') success = new EventEmitter();
    @Output('error') error = new EventEmitter();

    constructor(
            public catchaService: CaptchaValidateService
    ) {

    }

    @HostListener("click",['$event'])
    targetClick(e) {

        e.preventDefault();
        e.stopPropagation();

        this.catchaService.checkWithCaptcha().then(()=>{

            this.success.emit();

        }).catch(()=>{
            console.log("滑块验证失败");

            this.error.emit();
        })
    }
}