import {Injectable} from '@angular/core';
import {AlertService} from '../alert.service';
import {AppConfig} from '../app-config.service';
import {DateFormatter} from '../../pipe/date-formatter.pipe';

/**
 * 下载图片或者文件
 *
 * @fixme 注意和app是不同的
 */

@Injectable()
export class FileDownloadService {

	constructor(
            private alert: AlertService,
            private appConfig: AppConfig,
    ) {
    }

    /**
     * 下载指定图片地址的图片
     * @param fileUrl 文件地址
     * @param fileName 保存的文件名称
     */
    public downloadByImageUrl(fileUrl, fileName?) {

        if(!fileUrl) {
            return Promise.reject("图片url为空");
        }

        return this.saveFileOnH5(fileUrl, fileName);
    }


    /**
     * h5保存图片
     */
    private  saveFileOnH5(fileUrl,fileName?) {

        return new Promise((rs, rj) => {
            this.alert.msg( '请长按图片进行保存!',3000).then(() => {
                //产生随机文件名
               /* if (!fileName) fileName = this.createRandomFilename(this.getExtFromFileUrl(fileUrl));

                var alink = document.createElement("a");
                alink.href = fileUrl;
                alink.download = fileName; //图片名
                alink.click();
*/
                setTimeout(rs, 500);
            });
        });
    }

    /**
     * 产生随机文件名
     * @param ext 文件后缀
     */
    private  createRandomFilename(ext?) {

        const dateStr = new DateFormatter().transform(new Date().getTime(),'yyyy_MM_dd_HH_mm_ss');
        if(!ext) return 'ttyq_' + dateStr;
        return 'ttyq_' + dateStr + ext;
    }

    /**
     * 从文件url中获取文件后缀
     */
    private  getExtFromFileUrl(fileUrl:string) {

        if(!fileUrl) throw new Error("文件url为空");

        const index = fileUrl.lastIndexOf('.');
        let str = fileUrl.substring(index);

        //如果url后面带有url参数，则替换掉
        if(str.indexOf("?") != -1) {
            str = str.replace(/\?.+/,'');
        }
        return str;
    }
    /**
     * 保存base64图片
     * @param url
     * @param fileName
     */
    public saveBase64Img(url,fileName:string) {


        fileName += ('_'+new Date().getTime()).substr(0,11);

        return new Promise((rs,rj)=>{


            this.alert.loading(null,'请长按图片保存图片').then(()=>{
                setTimeout(()=>{
                    rs();
                },1000);
            });


        })

    }

}
