/**
 * Created by hh on 2019/9/11.
 */
import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import * as PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';
import * as PhotoSwipe from 'photoswipe/dist/photoswipe';
import {SwipePhoto} from "./swipe-photo.model";
import {PhotoSwipeService} from "./photo-swipe.service";
@Component({
    selector: 'photo-swipe',
    templateUrl: './photo-swipe.component.html'
})
export class PhotoSwipeComponent implements OnInit {

    imgWidth:number = 600;
    imgHeight:number = 600;
    // imgReg:RegExp = /<img.*?(?:>|\/>)/gi;
    // srcReg:RegExp = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;

    constructor(private cd: ChangeDetectorRef,private photoSwipeService:PhotoSwipeService) { }

    ngOnInit() {
        this.photoSwipeService.subject.subscribe((data:{src:string,content:SwipePhoto[]})=>{
            let photos:SwipePhoto[] = data.content;
            let src:string = data.src;
            let index:number = 0;
            if(photos&&photos.length){
                index = photos.findIndex((item)=>{
                    return item.src==src;
                });
            }else{
                photos = [new SwipePhoto(src,this.imgWidth,this.imgHeight)];
            }
            this.open(photos,index);
        })
    }

    open(images: SwipePhoto[], index?: number){
        this.cd.detectChanges();
        const pswpEle: any = document.querySelectorAll('.pswp')[0];
        let options = {
            index: 0 ,// start at first slide
            spacing:0,
            maxSpreadZoom:3,
            loop:true,
            hideAnimationDuration:0, showAnimationDuration:0,
            fullscreenEl:true
            // bgOpacity:0.85,
            // tapToClose:true,
            // closeOnScroll:false,
            // closeOnVerticalDrag:false,//禁用垂直滚动关闭画廊
            // allowPanToNext:true,
            // history: false
        };
        if(index){options.index = index;}
        // Initializes and opens PhotoSwipe
        const gallery:any = new PhotoSwipe(pswpEle, PhotoSwipeUI_Default, images, options);
        /*gallery.listen('resize', function() {
            console.info("resize");
        });

        gallery.listen('firstTouchStart', (e)=>{
            console.info("firstTouchStart",e);
            // setTimeout(()=>{
            //     this.cd.detectChanges();
            // });
        });
        gallery.listen('destroy', function() {
            console.info('destroyed');
        });*/
        gallery.init();
    }

    // swipe(src:string,content?:string){
    //     let photos:SwipePhoto[] = this.findPhotos(content);
    //     let index:number = 0;
    //     if(photos&&photos.length){
    //         index = photos.findIndex((item)=>{
    //             return item.src==src;
    //         });
    //         // for(let i = 0;i<photos.length;i++){
    //         //     if(photos[i].src==src){
    //         //         index = i;
    //         //         break;
    //         //     }
    //         // }
    //     }else{
    //         photos = [new SwipePhoto(src,this.imgWidth,this.imgHeight)];
    //     }
    //     this.open(photos,index,this.isSelector(content)?content:null);
    // }
    //
    // isSelector(content?:string){
    //     if(content&&(content[0]=='.'||content[0]=='#')){
    //         return true;
    //     }
    //     return false;
    // }
    //
    // findPhotos(content?:string):SwipePhoto[]{
    //     if(!content){
    //         return [];
    //     }
    //     if(this.isSelector(content)){//content是文章资讯帖子的id或class
    //         let contentEle:any = document.querySelectorAll(content)[0];
    //         let imgEles:any = contentEle.querySelectorAll("img");
    //         let arr = [];
    //         for(let i=0;i<imgEles.length;i++){
    //             arr.push(imgEles[i]);
    //         }
    //         return arr.map((e)=>{
    //             console.info(e.width,e.height,e.naturalWidth,e.naturalHeight);
    //             return new SwipePhoto(e.src,e.naturalWidth||e.width,e.naturalHeight||e.height);
    //         });
    //     }else{//content是文章资讯帖子的内容
    //         let imageStrArray:string[] = content.match(this.imgReg);
    //         let srcArray:string[] =  imageStrArray.map(c=>{
    //             let result = c.match(this.srcReg);
    //             if(result&&result.length){return result[1];}
    //             return '';
    //         });
    //         return srcArray.map(src=>{
    //             return new SwipePhoto(src,this.imgWidth,this.imgHeight);
    //         })
    //     }
    // }


}
