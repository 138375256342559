import {Injectable} from '@angular/core';
import {CookieService} from "./cookie.service";

class Marker {
  flag:string;
  reg:RegExp;
  constructor(flag:string,reg:RegExp) {
    this.flag = flag;
    this.reg = reg;
  }
}

//平台标识符
const CLIENT_MARKER = [
  new Marker('weibo',/\bWeibo/i),       //微博客户端浏览器
  new Marker('wechat',/\bMicroMessenger/i),      //微信客户端浏览器
  new Marker('jingdong',/jdapp/i),        //京东客户端浏览器
  new Marker('jcob',/jcob/i),             //盈球客户端浏览器
  new Marker('qqbrowser',/\bmqqbrowser/i),         //安卓qq内置浏览器/qq浏览器
  new Marker('qq',/\bQQ/i),      //ios qq内置浏览器
  new Marker('safari',/\bSafari/i),       //safari浏览器
  new Marker('uc',/\bUCBrowser/i),         //UC浏览器
  new Marker('chrome',/\bChrome/i),        //谷歌浏览器
  new Marker('firefox',/\bFirefox/i),      //火狐浏览器
];

//url识别平台
const CLIENT_URL_MARKER = [
  // new Marker('weibo',/weibo.*\.ttyingqiu\.com/i),       //微博客户端浏览器
  // new Marker('wechat',/wechat.*\.ttyingqiu\.com/i),      //微信客户端浏览器  微信暂时没有独立clientType
  // new Marker('jingdong',/jd.*\.ttyingqiu\.com/i),        //京东客户端浏览器
  // new Marker('tq',/tq.*\.ttyingqiu\.com/i),        //推球客户端浏览器
  new Marker('hw',/hw.*\.51yingqiu\.com/i),        //体坛周报华为
  new Marker('hw',/zhuanjia.*\.titan24\.com/i),        //体坛周报华为
  new Marker('vivoydzx',/yidian.*\.titan24\.com/i),        //体坛周报一点资讯vivo合作
];

//当前项目运行的平台
const CLIENT_TYPE = [
  'jcob',         //盈球
  // 'weibo',        //微博
  // 'wechat',       //微信
  // 'jingdong',     //京东
  // 'tq',     //推球
  'hw',     //体坛周报华为
  'vivoydzx',     //体坛周报vivo
];

/**
 * 浏览器平台信息 服务
 */
@Injectable()
export class ClientPlatform {

  private _platform:string;    //客户端运行的浏览器平台
  private _urlType:string;    //客户端url属于哪个平台
  // private _version;     //浏览器内核版本
  // private _sys_version; //操作系统版本
  private _sys;    //操作系统

  constructor(public cookieService:CookieService) {}

  /**
   * 获取客户端运行的浏览器
   * @returns {any}
   */
  get platform():string {
    // this._platform = 'jingdong';
    if(!this._platform)
      this._platform = this.checkBrowser();
    return this._platform;
  }

  set platform(platform) {
    this._platform = platform;
  }

  /**
   * 获取客户端平台 微信/微博/京东/盈球
   *
   * 运行在微博浏览器平台的为 微博端
   * 运行在微信浏览器平台的 微信端
   * 不管运行在哪，url为 jd.ttyingqiu.com 的为 京东端
   * 其他为 盈球端
   *
   * @returns {string}
   */
  get clientType():string {

    /*if(this.cookieService.get("thirdType")=="xiaodian"){
      return "xiaodian";
    }*/
    if(!this._urlType)
      this._urlType = this.checkPlatformByUrl();

    if(this._urlType == 'hw')
        return 'ttHw';
    if(this._urlType == 'vivoydzx')
        return 'vivoydzx';

    //如果是京东的url 直接返回京东平台
    /*if(this._urlType == 'jingdong')
      return 'jingdong';

    if(this._urlType == 'tq')
      return 'tq';*/

    if(CLIENT_TYPE.indexOf(this.platform) != -1)
      return this.platform;
    return 'jcob';
  }

  /**
   * 操作系统  苹果 ios  ，安卓 android ，未知 unknown
   * @returns {any}
   */
  get system() {
    if(!this._sys)
      this._sys = this.getSys();
    return this._sys;
  }

  /**
   * 判断是否是ios系统
   * @returns {boolean}
   */
  get isIOS() {
    return this.system === 'ios';
  }
  /**
   * 判断是否是安卓系统
   * @returns {boolean}
   */
  get isAndroid() {
    return this.system === 'android';
  }

  /**
   * 判断是否是小程序请求的页面
   */
  isMina(){
    return location.href.indexOf('mina')>-1
  }

  /**
   *是否在移动端浏览器
   */
  private onMobile() {
    let agent = navigator.userAgent;
    return /\bMobile/.test(agent);
  }

  /**i
   * 根据浏览器信息，获取客户端平台信息
   * 根据CLIENT_MARKER进行浏览器匹配,匹配成功返回key
   * 如果没有匹配则返回 ‘other‘
   */
  private checkBrowser():string {
    //客户端浏览器信息
    let agent = navigator.userAgent.toLocaleLowerCase();

    for (let i in CLIENT_MARKER){
      let v = CLIENT_MARKER[i];
      let test = v.reg.test(agent);
      if(test) {
        return v.flag;
      }
    }
    return 'other';
  }

  /**
   *  根据url，判断具体属于什么平台客户端
   *  根据 CLIENT_URL_MARKER 进行浏览器匹配,匹配成功返回key
   *  如果没有匹配则返回 ‘jcob‘ 表示基础盈球平台
   * @returns {string}
   */
  private checkPlatformByUrl():string {

    //客户端浏览器信息
    let url = self.location.host;

    for (let i in CLIENT_URL_MARKER){
      let v = CLIENT_URL_MARKER[i];
      let test = v.reg.test(url);
      if(test) {
        return v.flag;
      }
    }
    return 'jcob';
  }


  /**
   * 和获取操作系统
   * @returns {any}
   */
  private getSys() {
    let agent = navigator.userAgent.toLocaleLowerCase();
    if ( agent.match( /iPad/i ) || agent.match( /iPhone/i ) || agent.match( /iPod/i ) ){
      return 'ios';
    } else if ( agent.match( /Android/i ) ) {
      return 'android';
    } else {
      return 'unknown';
    }
  }

  /**
   *  获取ios系统版本
   * @returns {number} 返回ios系统版本号，如果不是ios，返回0
   */
  public getIosVersion():number {
    let userAgent = navigator.userAgent;
    if (userAgent.indexOf("AppleWebKit") != -1) {

      try {
        let reg = /iPhone\sOS\s(\d*)_/;
        let match = userAgent.match(reg);
        if (match && match[1]) {
          return parseFloat(match[1]);
        }
      } catch (e) {
        return 0;
      }
    }
    return 0;
  }

  /**
   * 获取安卓系统版本
   * 如果不是安卓系统 返回0
   * @returns {any}
   */
  public getAndroidVersion():number {

    let  ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("android") > 0) {
      try {

        let  version;
        let reg = /android\s[\d._]+/gi;
        let v_info = ua.match(reg);
        version = (v_info + "").replace(/[^0-9|_.]/ig, "").replace(/_/ig, "."); //得到版本号4.2.2
        version = parseInt(version.split('.')[0]);// 得到版本号第一位

        return version;

      }catch (e){}
    }

    return 0;
  }


  /**
   * 监听 判断当前页面是否处于激活状态
   * (当前用户是否正在浏览当前页)
   * @param comebackFun 用户回来回调函数
   * @param leaveFun 用户离开回调函数
   * @return 移除监听句柄
   */
  public pageComebackListener(comebackFun,leaveFun?) {
    let document = (<any>window).document;
    let hidden, visibilityChange;
    if (typeof document.hidden !== "undefined") {
      hidden = "hidden";
      visibilityChange = "visibilitychange";
    } else if (typeof document.mozHidden !== "undefined") {
      hidden = "mozHidden";
      visibilityChange = "mozvisibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden";
      visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
    }

    let callback = ()=> {
      if(document[hidden]){
        // 用户离开当前页面
        leaveFun && leaveFun();
      }else{
        // 用户回来当前页面
        comebackFun && comebackFun();
      }
    };

    // 添加监听器
    document.addEventListener(visibilityChange,callback, false);

    return {
      //移除监听
      unsubscribe:()=>{
        // window.alert("移除监听");
        document.removeEventListener(visibilityChange,callback);
      }
    }

  }

}
