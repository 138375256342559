import {ActivatedRouteSnapshot, CanDeactivate, CanLoad, Route, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from "rxjs/Observable";

/**
 * 如果动态加载flexible.750.min.js，在离开页面时需要删除掉
 * 可以在加载子模块的地方添加守护路由
 */
export class JsAutoUnLoadGuard implements CanDeactivate<any> {
    canDeactivate(component: any, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      console.log("离开页面咯");

      return true;

    }


}
