import {Injectable, ApplicationRef} from "@angular/core";
import {StorageService} from "../../../services/storage.service";
import {AppHttp} from "../../../services/app-http.service";

@Injectable()
export  class SnsService{
    images = [];
    constructor(private storageService:StorageService,
                private apf:ApplicationRef,
                private  http:AppHttp,
    ){
        for(let i = 1;i<63;i++){
            if(i<10){
                this.images.push('0'+i);
            }else{
                this.images.push(i);
            }
        }
    }

    httpGet(url: string, options?:any){
        return this.http.get(url,options).then((data)=>{
            setTimeout(()=>{
                this.apf.tick(); //不要这么做，先应急
            })
            return data;
        })
    }

    vote(param){
    return this.httpGet('/api/user/sns/vote',{params:param});
}

    attentionExpert(memberId) {
    return this.httpGet('/api/uc/interpretation/attentExpert/'+memberId);
}

    likeMsg(msgId,opt) {
    return this.httpGet('/api/user/sns/likeMsg',{params:{msgId:msgId,likeFlag:opt}});
}
    reportSubmit(msgId,foulType){
    return this.httpGet('/api/user/sns/report',{params:{msgId:msgId,foulType:foulType}});
}
    deleteSubmit(msgId,foulType?){
    return this.httpGet('/api/user/sns/cutPosts',{params:{msgId:msgId,foulType:foulType}});
}
   forbidUserSubmit(memberId,foulType){
    return this.httpGet('/api/user/sns/pullBlack',{params:{pullBlackId:memberId,blackReason:foulType}});
}
    gagUserSubmit(memberId,foulType,day?){
    return this.httpGet('/api/user/sns/gagUser',{params:{forbidId:memberId,forbidType:foulType,forbidDays:day||7}});
}

    toTop(msgId,type){
    return this.httpGet('/api/user/sns/topFlag',{params:{msgId:msgId,operationType:type}});
}
    checkSnsMemberStatus(){
        return this.httpGet('/api/user/sns/checkSnsMemberStatus');
    }

    queryThemeInfo (param) {
        return this.httpGet("/api/sns/queryThemeInfo",{params:param});
    }

    queryInformationRecommendList(id) {
        return this.httpGet('/api/expert/replay/information/' + id);
    }

    //查询评论详情页接口
    queryReplyDetail(msgId){
        return this.httpGet('/api/querySnsNoteDetail/'+msgId);
    }
}
