import {NgModule} from "@angular/core";
import {CookieService} from '../services/cookie.service';
import {AppHttp} from "../services/app-http.service";
import {Events} from "../services/events.service";
import {AppConfig} from "../services/app-config.service";
import {StorageService} from "../services/storage.service";
import {AlertService} from "../services/alert.service";

import {SportdataHttp} from "../services/sportdata-http.service";
import {AuthGuard} from "../services/auth-guard.service";
import {ExpertSearchService} from "../template/expert/expert-search/shared/expert-search.service";
import {ExpertService} from "../template/expert/shared/expert.service";
import {MatchDetailService} from "../template/match/football/match-detail/matchDetail.service";
import {SettingService} from "../template/match/football/match-list/setting/setting.service";
import {RootService} from "../services/root.service";
import {UserCenterService} from "../template/user-center/shared/user-center.service";
import {NavCtrlService} from "../services/nav-controller.service";
import {HomeService} from "../template/home-new/shared/home.service";
import {TjInterpretationService} from "../template/plan-jiedu/shared/interpretation.service";
import {UserMoneyService} from "../template/user-center/shared/user-money.service";
import {BkMatchDetailService} from '../template/match/basketball/bk-match-detail/bk-match-detail.service';
import {SWIPER_CONFIG, SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {MsgService} from "../template/message/msg-service";
import {BtDataService} from "../template/data/basketball/bt-data.service";
import {LoginRegisterService} from "../template/account/login.service";
import {JsAutoLoadGuard} from "../services/JsAutoLoadGuard";
import {HomeGuard} from "../services/home-guard.service";
import {ClientPlatform} from "../services/client-platform.service";
import {JdSdkService} from "../services/jd-sdk.service";
import {SessionStorageService} from "../services/sessionStorage.service";
import {JsBridgeService} from "../services/jsBridge.service";
import {LoginCacheService} from "../template/account/login/login-cache.service";
import {LoginGuard} from "../template/account/login/login-guard.service";
import {ThirdLoginService} from "../template/account/login/third-login.service";
import {Browser} from "../services/browser.service";
import {PageRouterService} from "../services/page-router.service";
import {SnsService} from "../template/message/shared/sns.service";
import {MessageService} from "../template/message/shared/message.service";
import {MatchRedPacketsService} from "../template/match/match-red-packets/match-red-packets.service";
import {TxStatisticsService} from "../services/txStatistics.service";
import {ScrollTabAreaSubscribeService} from "../services/scrollTabAreaSubscribe.service";
import {EncryptService} from "../services/encrypt.service";
import {CouponService} from "../template/user-center/coupon-list/coupon-confirm/coupon.service";
import {AppStartConfirmService} from "../services/appStartConfirm.service";
import {PhotoSwipeService} from "../component/photo-swipe/photo-swipe.service";
import {JcobUtilService} from "../services/jcobUtil.service";
import {SharePageService} from "../template/share-component/share-page/share-page.service";
import {Html2canvasService} from "../services/html2canvas.service";
import {ExpertNoteService} from "../template/expert/expert-note/shared/expert-note.service";
import {ShareService} from "../services/share.service";
import {DataGuard} from "../services/data-guard.service";
import {ResourceLoadErrService} from '../services/resourceLoadErr.service';
import {PhoneMessageCenterService} from "../services/phone-message-center.service";
import {EchartsService} from '../services/echarts.service';
import {CreatePaymentService} from "../services/plugin-service/create-payment.service";
import {JcobStatisticsService} from '../services/jcobStatistics.service';
import {SearchService} from "../template/data/country-list/search-page/search.service";
import {AppConstConfigService} from '../services/app-const-config.service';
import {FileDownloadService} from '../services/plugin-service/fileDownload.service';
import {ClipboardService} from '../services/clipboard.service';
import {WechatGuard} from '../services/wechat-guard.service';
import {UserHabitService} from '../services/user-habit.service';
import {ImageUploadService} from "../services/image-upload.service";
import {JsAutoUnLoadGuard} from '../services/JsAutoUnLoadGuard';

/**
 *将服务定义于此处，保证core.module只被import一次，服务就是单例的
 */
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    // keyboardControl: true
};
@NgModule({
    providers:[
      CookieService,
      ClientPlatform,
      AppHttp,
      Events,
      AppConfig,
      StorageService,
      SessionStorageService,
      LoginCacheService,
      AuthGuard,
      AlertService,

      ExpertSearchService,
      ExpertService,
      UserCenterService,
      SportdataHttp,

      NavCtrlService,
      MatchDetailService,
      SettingService,
      RootService,
      HomeService,
      TjInterpretationService,
      UserMoneyService,
      BkMatchDetailService,
      LoginGuard,
      MsgService,
      SnsService,
      BtDataService,
      LoginRegisterService,
      JsAutoLoadGuard,
      JsAutoUnLoadGuard,
      HomeGuard,
      JdSdkService,
      JsBridgeService,
      ThirdLoginService,
      Browser,
      PageRouterService,
      MatchRedPacketsService,
      MessageService,
      TxStatisticsService,
      ScrollTabAreaSubscribeService,
      EncryptService,
      AppStartConfirmService,
      CouponService,
      PhotoSwipeService,
      SharePageService,
      Html2canvasService,
      JcobUtilService,
      ExpertNoteService,
      ShareService,
      SearchService,
      DataGuard,
        ClipboardService,
        AppConstConfigService,
        FileDownloadService,
        ResourceLoadErrService,   //wepack加载js错误时提示
        PhoneMessageCenterService,  //验证码中心
        EchartsService,       //图表服务
        JcobStatisticsService,
      CreatePaymentService,
      WechatGuard,
        UserHabitService,
        ImageUploadService,
      {provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG}
    ]
})
export class CoreModule {}
