import {Injectable} from '@angular/core';
import {JdSdkService} from "../../../services/jd-sdk.service";
import {AppConfig} from "../../../services/app-config.service";
import {LoginCacheService} from "./login-cache.service";
import {LoginRegisterService} from "../login.service";
import {AlertService} from "../../../services/alert.service";
import {RootService} from "../../../services/root.service";
import {UserCenterService} from "../../user-center/shared/user-center.service";
import {CookieService} from "../../../services/cookie.service";
import {AppHttp} from "../../../services/app-http.service";

/**
 * 第三方登录相关服务
 */

declare  var JWeixin:any;

@Injectable()
export class ThirdLoginService {

  constructor(
    private jd: JdSdkService,
    private appConfig: AppConfig,
    private loginRegisterService:LoginRegisterService,
    private alertService: AlertService,
    private loginCache: LoginCacheService,
    private rootService:RootService,
    private userCenterService:UserCenterService,
    private cookieService:CookieService,
    private http:AppHttp
  ) {

  }

  /**
   * 第三方登录
   */
  public thirdLogin() {

    //微信登录
    if(this.appConfig.isWechat) {

      this.wechatLogin(this.loginCache.fullBackUrl);
    }else if(this.appConfig.isCNB){//中国足球信息网-微信登录
      this.wechatLogin(this.loginCache.fullBackUrl);
    }else if(this.appConfig.isWB) {
      //微博登录

      this.weiboLogin(this.loginCache.fullBackUrl);

    }else if(this.appConfig.isJD) {
      //京东登录
      this.jdLogin();
    }else if(this.appConfig.isTq){
      this.tqLogin();
    }

  }

  /**
   * 项目初始化后进行自动登录
   */
  public thirdLoginAfterInit() {

    let time = 0;

    if(this.appConfig.isJD) time = 100;   //京东登录延迟一会，为了等待父窗口初始化完成
    if(!this.appConfig.isJD && this.loginCache.isLogin()) return;    //微信 已登录则不重新自动登录

    this.loginCache.backUrl = self.location.href;         //设置回调地址
    setTimeout(()=>{
      this.thirdLogin();
    },time);

  }


  /**
   * 京东登录
   * 直接获取京东 ticket ，然后请求接口进行ajax自动登录
   */
  private jdLogin() {

    this.jd.jdLogin();
  }

  /**
   * 微信登录
   * 跳转到微信登录站点，使用auth2.0协议登录，登录成功返回到盈球
   * @param callback  登录成功后返回的地址
   */
  private wechatLogin(callbackUrl) {
    let opt = this.http.generateOption({url:callbackUrl,share:this.appConfig.share});
    JWeixin.wechatLogin(opt);

  }
  /**
   * 微博登录
   * 跳转到微博登录站点，使用auth2.0协议登录，登录成功返回到盈球
   * @param callback  登录成功后返回的地址
   */
  private weiboLogin(callback) {

    this.loginRegisterService.wbLogin(callback,this.appConfig.share).then((data)=>{
      self.location.href = data.url;
    }).catch((data)=>{
      this.alertService.msg('微博登录异常，请联系客服');
    })

  }

  /**
   * 推球ajax登录
   */
  private tqLogin(){
    let uid = this.rootService.getUrlParams("uid");
    if(!!uid) {
      let sign = this.rootService.getUrlParams("sign");
      let tq_uid = this.cookieService.get("tq_uid");
      if (uid == tq_uid&&this.userCenterService.isLogin()) {
        //this.cookieService.delCookie("tq_uid")
        return;
      }
      this.loginRegisterService.tqLogin(uid, sign).then((data) => {
        setTimeout(() => {
          this.userCenterService.index();
        }, 50);
      }).catch();
    }else{
      //alert("tuiqiu://bet?stateParams={'returnUrl':'"+encodeURIComponent(window.location.href)+"'}&stateName=LoginPage");
      //window.location.href = "tuiqiu://bet?stateParams={'returnUrl':'"+encodeURIComponent(window.location.href)+"'}&stateName=LoginPage";
      this.loginRegisterService.getTqLoginUrl().then((data)=>{
        if(data.success){
          let url =data.loginUrl;
          let appName = this.cookieService.get("tq_name")
            if(!!appName&&appName!=''){
              url = url.replace("tuiqiu://",appName+"://");
            }
            window.location.href = url;
          //window.location.href = 'tuiqiu://bet?stateParams={"returnUrl":"http://m.baidu.com"}&stateName=LoginPage&decodeURIComponent=cd';
        }
      })
    }
  }


}
