import {Injectable} from "@angular/core";
import {AppHttp} from "../../../services/app-http.service";
import {SportdataHttp} from "../../../services/sportdata-http.service";
/**
 * Created by hh on 2018/3/14.
 */
@Injectable()
export class TjInterpretationService {

    // doPayAgain:any = false;

    constructor(public http: AppHttp,public sportdataHttp:SportdataHttp) {

    }

    look (id) {
        return this.http.get('/api/interpretation/look/'+id);
    }
    pay (id) {
        return this.http.get('/api/uc/interpretation/pay/'+id);
    }
    /*isAttentionExpert (expertId) {
        return this.http.get('/api/interpretation/isAttentExpert/'+expertId);
    }*/
    attentionExpert (expertId) {
        return this.http.get('/api/uc/interpretation/attentExpert/'+expertId);
    }
    /*queryReadings (option,pageNo) {
         return this.http.post('/api/interpretation/list/'+pageNo,option);
    }
    getCpzJdList(pageNo,option){
        return this.http.post('/api/query/cpz/more/jdList/'+pageNo,option);
    }*/
    queryJd (option,pageNo){
      //5.3.0使用新版接口
      return this.http.post('/api/query/interpretation/list/'+pageNo,option);
    }
    queryExpertScore(expertId){
      return this.http.get('/api/inter/score/'+expertId);
      // return this.http.get('/api/interpretation/score/'+jieduId);
    }

    queryPayAd(type){
        return this.http.get("/api/uc/pay/ad",{params:{adType:type}});
    }

/*  queryMemberWalletCanPay(id){
        return this.http.get('/api/uc/interpretation/queryMemberWalletCanPay/'+id);
    }*/

    //payType 2套餐券 3优惠券
    payFromOffer(interId,offerId,payType){
      return this.http.get('/api/uc/inter/offer/pay',{params:{interId,offerId,payType}});
    }

    //interId  解读id  raceType 解读类型
    queryOfferData(interId,raceType){
      return this.http.get('/api/uc/inter/setMeal',{params:{interId,raceType}});
    }

    querybetGameIssue(size) {
        return this.http.get('/api/query/sports/lotteryNums',{params:{batchSize:size}});
    }

    querySFCReturnData(issueNo,raceType) {
        return this.http.get('/api/query/sports/SFCReturn',{params:{issueNo:issueNo,playType:raceType}});
    }

    queryAwardsUnOpenData(gameId,issueNo) {
        return this.http.get('/api/query/sports/awardsUnOpen',{params:{gameId:gameId,issueNo:issueNo}});
    }

    //足彩开奖详情
    getLotteryDraw(issueNo){
      return this.sportdataHttp.post({"apiName": "getLotteryDraw", "issueNo": issueNo, "gameId": 401});
    }

    evaluateJieDu (id,evaluateData,qiuFlag,expertId) {
        return this.http.post('/api/interpretation/evaluate/'+id,{evaluateData:evaluateData,qiuFlag:qiuFlag,expertId:expertId});
    }

    // 查询我包月的专家
    queryMyBaoYueExpertList(pageNo, pageSize, day, qiuFlag, status) {
        return this.http.post('/api/query/queryMyBaoYueExpertList/' + pageNo, {pageSize, day, qiuFlag, status});}

    //查询某专家今天除该解读外的私推
    queryPrivatePushToday(expertMemberId,currentInterId) {
        return this.http.get('/api/query/queryPrivatePushToday', {params: {expertMemberId,currentInterId}});
    }

    //检查用户是否可参与评论送券
    checkEvaluateHd(interId?) {
        return this.http.get('/api/check/evaluate/send/coupon',{params: {interId}});
    }

    formatCt(content) {
        let ct = {};
        if (!content) {
            return ct;
        }
        let arr = content.split('~');

        arr.forEach((f)=>{
            let gameId = f.match('\\{(.+?)\\}')[1];
            let concede = f.match('\\[(.+?)\\]') === null ? '-' : f.match('\\[(.+?)\\]')[1];
            let sp = f.match('\\((.+?)\\)') == null ? 'null' : f.match('\\((.+?)\\)')[1];
            sp = this.resetSp(sp);
            let sp_arr:any;
            switch (gameId) {
                case '4071':
                    //胜平负
                    if (sp !== 'null') {
                        sp_arr = sp.split('-');
                        ct[gameId] = {};
                        ct[gameId].sp = { '3': sp_arr[0], '1': sp_arr[1], '0': sp_arr[2] };
                    }
                    break;
                case '4073':
                    //全场比分
                    if (sp !== 'null') {
                        sp_arr = sp.split('-');
                        ct[gameId] = {};
                        ct[gameId].sp = {
                            '90': sp_arr[0], '10': sp_arr[1], '20': sp_arr[2], '21': sp_arr[3],
                            '30': sp_arr[4], '31': sp_arr[5], '32': sp_arr[6], '40': sp_arr[7], '41': sp_arr[8],
                            '42': sp_arr[9], '50': sp_arr[10], '51': sp_arr[11], '52': sp_arr[12],
                            '99': sp_arr[13], '00': sp_arr[14], '11': sp_arr[15], '22': sp_arr[16], '33': sp_arr[17],
                            '09': sp_arr[18], '01': sp_arr[19], '02': sp_arr[20],
                            '12': sp_arr[21], '03': sp_arr[22], '13': sp_arr[23], '23': sp_arr[24], '04': sp_arr[25],
                            '14': sp_arr[26], '24': sp_arr[27], '05': sp_arr[28], '15': sp_arr[29], '25': sp_arr[30]
                        };
                    }
                    break;
                case '4072':
                    //总进球
                    if (sp !== 'null') {
                        sp_arr = sp.split('-');
                        ct[gameId] = {};
                        ct[gameId].sp = {
                            '0': sp_arr[0],
                            '1': sp_arr[1],
                            '2': sp_arr[2],
                            '3': sp_arr[3],
                            '4': sp_arr[4],
                            '5': sp_arr[5],
                            '6': sp_arr[6],
                            '7': sp_arr[7]
                        };
                    }
                    break;
                case '4074':
                    //半全场胜平负
                    if (sp !== 'null') {
                        sp_arr = sp.split('-');
                        ct[gameId] = {};
                        ct[gameId].sp = {
                            '33': sp_arr[0],
                            '31': sp_arr[1],
                            '30': sp_arr[2],
                            '13': sp_arr[3],
                            '11': sp_arr[4],
                            '10': sp_arr[5],
                            '03': sp_arr[6],
                            '01': sp_arr[7],
                            '00': sp_arr[8]
                        };
                    }
                    break;
                case '4076':
                    //让球胜平负
                    ct[gameId] = { concede: Number(concede) > 0 ? '+' + concede : concede };
                    if (sp !== 'null') {
                        sp_arr = sp.split('-');
                        ct[gameId].sp = { '3': sp_arr[0], '1': sp_arr[1], '0': sp_arr[2] };
                    }
                    break;
                case '4061':
                    //胜负
                    ct[gameId] = {};
                    if (sp !== 'null') {
                        sp_arr = sp.split('-');
                        ct[gameId].sp = { '1': sp_arr[1], '2': sp_arr[0] };
                    }
                    break;
                case '4062':
                    //让分胜负
                    ct[gameId] = { concede: Number(concede) > 0 ? '+' + concede : concede };
                    if (sp !== 'null') {
                        sp_arr = sp.split('-');
                        ct[gameId].sp = { '1': sp_arr[1], '2': sp_arr[0] };
                    }
                    break;
                case '4063':
                    //胜分差
                    ct[gameId] = {};
                    if (sp !== 'null') {
                        sp_arr = sp.split('-');
                        ct[gameId].sp = {
                            '11': sp_arr[0], '12': sp_arr[2], '13': sp_arr[4], '14': sp_arr[6], '15': sp_arr[8], '16': sp_arr[10],
                            '01': sp_arr[1], '02': sp_arr[3], '03': sp_arr[5], '04': sp_arr[7], '05': sp_arr[9], '06': sp_arr[11]
                        };
                    }
                    break;
                case '4064':
                    //大小分
                    ct[gameId] = { advancedScore: concede };
                    if (sp !== 'null') {
                        sp_arr = sp.split('-');
                        ct[gameId].sp = { '1': sp_arr[0], '2': sp_arr[1] };
                    }
                    break;
                case '4078':
                    sp_arr = sp.split('-');
                    ct[gameId] = { concede: concede };
                    if (sp != null) {
                        ct[4078].sp = { '3': sp_arr[0], '0': sp_arr[1] };
                        if (Number(concede) < 0) {
                            ct[4078].tapDesc = '受' + this.asiaTapeMap[Math.abs(Number(concede))];
                        } else {
                            ct[4078].tapDesc = this.asiaTapeMap[Math.abs(Number(concede))];
                        }
                    }
                    break;
                case '4079':
                    sp_arr = sp.split('-');
                    ct[gameId] = { concede: concede };
                    if (sp != null) {
                        ct[4079].sp = { '3': sp_arr[0], '0': sp_arr[1] };
                        ct[4079].tapDesc = this.scoreTape[concede];
                    }
                    break;
            }
        });

        return ct;
    }

    asiaTapeMap:any = { "0": "平手", "2500": "平手/半球", "5000": "半球", "7500": "半球/一球", "10000": "一球", "12500": "一球/球半", "15000": "球半", "17500": "球半/两球",
        "20000": "两球", "22500": "两球/两球半", "25000": "两球半", "27500": "两球半/三球", "30000": "三球", "32500": "三球/三球半", "35000": "三球半",
        "37500": "三球半/四球", "40000": "四球", "42500": "四球/四球半", "47500": "四球半/五球", "50000": "五球", "52500": "五球/五球半", "55000": "五球半",
        "57500": "五球半/六球", "60000": "六球", "62500": "六球/六球半", "65000": "六球半", "67500": "六球半/七球", "70000": "七球", "72500": "七球/七球半",
        "75000": "七球半", "77500": "七球半/八球", "80000": "八球", "82500": "八球/八球半", "85000": "八球半", "87500": "八球半/九球", "90000": "九球", "92500": "九球/九球半", "95000": "九球半",
        "97500": "九球半/十球", "100000": "十球" };

    scoreTape:any = { "2500": "0.5球", "5000": "0.5球", "7500": "0.5/1球", "10000": "1球", "12500": "1/1.5球", "15000": "1.5球", "17500": "1.5/2球", "20000": "2球", "22500": "2/2.5球", "25000": "2.5球",
        "27500": "2.5/3球", "30000": "3球", "32500": "3/3.5球", "35000": "3.5球", "37500": "3.5/4球", "40000": "4球", "42500": "4/4.5球", "45000": "4.5球", "47500": "4.5/5球", "50000": "5球", "52500": "5/5.5球",
        "55000": "5.5球", "57500": "5.5/6球", "60000": "6球", "62500": "6/6.5球", "65000": "6.5球", "67500": "6.5/7球", "70000": "7球", "72500": "7/7.5球", "75000": "7.5球", "77500": "7.5/8球", "80000": "8球",
        "82500": "8/8.5球", "85000": "8.5球", "87500": "8/9球", "90000": "9球", "92500": "9/9.5球", "95000": "9.5球", "97500": "9.5/10球", "100000": "10球" };

    /**
     *
     *重置SP计分
     */
    resetSp(sp){
        if(!sp){
            return sp;
        }
        if(sp.indexOf('.') > 0){
            return sp;
        }
        let next = sp.split('-');
        if(!next || next.length == 0){
            return sp;
        }
        let tmp = '';
        for(let item of next){
            tmp += '-';
            tmp += (Number(item)/10000).toFixed(2)
        }
        return tmp.substring(1);
    }

    //第三方公众号直付
    weixinDerectPay(playType, planId, planNo,feeId){

        return this.http.post("/api/preDualPay/",{playType:playType,planId:planId,planNo:planNo,returnUrl:location.href,feeId});
    }
}
