import {Directive, HostListener, Input} from '@angular/core';
import {TxStatisticsService} from '../../services/txStatistics.service';
import {LoginCacheService} from '../../template/account/login/login-cache.service';

/**
 * 快捷添加埋点，
 * 如果新添加埋点，需要先在腾讯后台进行添加，然后在 MTA_EVENTS_CONFIG添加map
 *
 * [mta]="code" [params]="{'key':value}" or [params]="{key}"
 * [mta]="101" [params]="{'101':1}" or [params]="101"，这种情况value默认为1
 * [params]默认值为 {'101':1}
 *
 * @fixme 注意app和h5埋点有很大不同
 */

//是否登录
let isLogin,mtaClick,loudouClick;

@Directive({
    selector:"[mta]",
})
export class TxStatisticsDirective{

    @Input('mta') mta:number;
    @Input('params') params:any;

    constructor(
            private txStatistics:TxStatisticsService,
            private login:LoginCacheService,

    ) {
    }

    @HostListener("click")
    onClick(){

        // console.log("mat click",this.mta);

        //一些全局参数设置
        isLogin = this.login.isLogin();
        mtaClick = this.txStatistics.mtaClick.bind(this.txStatistics);
        loudouClick = this.txStatistics.loudouClick.bind(this.txStatistics);

        //获取对应的配置
        let config = MTA_EVENTS_CONFIG[this.mta];

        if(!config) {
            console.error("请在“MTA_EVENTS_CONFIG”配置对应埋点的行为");
            return;
        }

        //如果只传一个key的情况,将它组装成object
        if (typeof this.params === 'string' || typeof this.params === 'number') {
            this.params = {[this.params]: 1};
        }

        //如果配置了自定义方法
        if(typeof config === 'function') {
            config(this.params);
        } else if(typeof config === 'string'){
            //否自直接执行点击上报
            mtaClick(config,this.params || {101:1});
        }
    }

}

const MTA_EVENTS_CONFIG = {

    /******************页面的某位置的点击事件---------******************/

    /*tabs页---start*/
    //首页tabs - 发现tab
    101: function (params?) {
        loudouClick(isLogin ? "loudou01" : "loudou05", "h5homepageopenpage");
    },
    //首页tabs - 比分tab
    102: function (params?) {
        loudouClick(isLogin ? "loudou01" : "loudou05", "h5scorepageopenpage");
    },
    /*tabs页---end*/

    /*赛事详情页----start*/
    //赛事详情页 - tab
    136: function (params?) {
        checkLoudou(params) && loudouClick(isLogin ? "loudou01" : "loudou05", "h5matchread");
    },
    /*赛事详情页----end*/

    /*推荐/解读情页----start*/
    //解读详情页 - 购买推荐
    139: function (params?) {
        loudouClick(isLogin ? "loudou01" : "loudou05", "h5expert4buyinterpretation");
    },
    /*推荐/解读详情页----end*/

    /*****************页面的某位置的点击事件*****************end*/

    /******************跳转“某页面”的入口点击事件*****************start*/

    //跳转解读详情页
    204: function (params?) {
        checkLoudou(params) && loudouClick(isLogin ? "loudou01" : "loudou05", "h5expert4interpretationpage");

    },
    206: function (params?) {
        //漏斗事件
        checkLoudou(params) && loudouClick(isLogin ? "loudou01" : "loudou05", "h5matchopenpage");
    },
    /******************跳转“某页面”的入口点击事件*****************end*/
    //21年新年会员购买活动
    2102:function () {
        mtaClick('h5vipbuy_btn');
    }
};

function checkLoudou(obj) {
    let flag = false;
    if(obj && obj['needLoudou']) {
        flag = true;
        delete obj['needLoudou'];
    }
    return flag;
}