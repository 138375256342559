import {Injectable} from '@angular/core';
import {ClientPlatform} from "./client-platform.service";

/**
 * 与盈球app交互 相关服务
 */
@Injectable()
export class JsBridgeService {

  constructor(
    private platform: ClientPlatform,
  ) {
  }

  /**
   * 关闭当前h5页面
   * 在盈球app里面使用
   */
  public closePage() {
    this.toJcobApp();
  }

  /**
   * 跳转app登录页
   * @oaram backUrl 登录成功返回的页面
   */
  public toAppLoginPage(backUrl) {

    backUrl = encodeURIComponent(backUrl);

    this.toJcobApp("loginPage",{backH5Url:backUrl})

  }

  /**
   * 跳转盈球app
   * @param page 需要跳转的页面 盈球页面标志 如 HomePage
   * @param params 参数对象 如{id:1}
   */
  public toJcobPage(page?:string,params?:object) {
    this.toJcobApp(page,params);
  }

  /**
   * 使用schema跳转盈球app
   * @param page 需要跳转的页面 盈球页面标志 如 HomePage
   * @param params 参数对象 如{id:1}
   */
  private toJcobApp(page?:string,params?:object) {

    let path = '';

    if(page && !params) {
      path = `stateName=${page}`;
    } else if(page && params) {

      let p = JSON.stringify(params);
      path = `stateName=${page}&stateParasms=${p}`;
    }

    console.log('跳转app',page);
    if(this.platform.isIOS) {
      path = 'ttyingqiu://'+path;
      window.location.href = path;
    }else {
      path = 'jcob://'+path;

      let ifr = document.createElement('iframe');
      ifr.src = path;
      ifr.style.display = 'none';
      document.body.appendChild(ifr);
      setTimeout(function(){
        document.body.removeChild(ifr);
      }, 5000);
    }
  }

  /**
   * 使用schema跳转盈球app
   * 使用默认口令跳转
   *
   * 使用剪切板口令的方式来跳转
   * * 注意：剪切板复制需要先手动触发事件
   *
   * @param page 需要跳转的页面 盈球页面标志 如 HomePage
   * @param params 参数对象 如{id:1}
   */
  /*public jumpJcobWithDefaultCode(page:string,params?:object) {

    let obj:any = {
      page:page
    };
    if(params)
      obj.params = params;

    let str = JSON.stringify(obj);
    let code = `&jcob&${str}&jcob&`;

    this.clipboardService.copyFromContent(code);

    console.log(code);

    setTimeout(()=>{
      this.toJcobPage();
    },50);
  }*/

  /**
   * 使用schema跳转盈球app
   * 使用指定口令跳转
   *
   * 使用剪切板口令的方式来跳转
   *
   * 注意：剪切板复制需要先手动触发事件
   *
   * @param code 口令
   */
/*
  public jumpJcobWitCode(code:string) {

    this.clipboardService.copyFromContent(code);
    setTimeout(()=>{
      this.toJcobPage();
    },50);
  }
*/

}
