/**
 * Created by hh on 2019/9/11.
 */
export class SwipePhoto {

    constructor(public src:string,public w:number=0,public h:number=0){

    }


}