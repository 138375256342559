/**
 * Created by hh on 2017/8/24.
 */

import {Injectable} from '@angular/core';
import {AppHttp} from "../../../services/app-http.service";
import {Events} from "../../../services/events.service";
import {RootService} from "../../../services/root.service";
import {NavCtrlService} from "../../../services/nav-controller.service";
import {SportdataHttp} from "../../../services/sportdata-http.service";
import {PageRouterService} from "../../../services/page-router.service";

@Injectable()
export class HomeService {
  constructor(
    public navCtrl:NavCtrlService,
    public events:Events,
    public http:AppHttp,
    public rootService:RootService,
    public dataHttp: SportdataHttp,
    public pagerRouter: PageRouterService,
  ) {
  }

  stateGo(navCtrl: NavCtrlService,type,innerId,id,params?){
   this.pagerRouter.stateGo(navCtrl,type,innerId,id,params);
  }

  followSnsMsgGo(navCtrl: NavCtrlService,msgType,id) {
    this.pagerRouter.followSnsMsgGo(navCtrl,msgType,id);
  }

  //首页数据缓存
  cacheTime = 3 * 60 * 1000;
  firstCacheTime = null;
  homeDatas = {};

  //资讯页数据缓存
  firstInformationCacheTime = null;
  homeInformationDatas = {};

    todayMatchSelected;         //今日赛事模块选中的比赛

  //查询资讯页数据模版
  queryInformation(templateId,typeId,isCache,successFun,errorFun){
    //所有模版缓存3分钟
    if(this.firstInformationCacheTime){
      if((new Date().getTime()-this.firstInformationCacheTime)>this.cacheTime) {
        this.homeInformationDatas = {};
        this.firstInformationCacheTime = new Date().getTime();
      }
    }else{
      this.firstInformationCacheTime = new Date().getTime();
    }

    //有缓存就返回缓存数据
    let id = -1;
    if(templateId!=null)id=templateId;
    if(isCache && !typeId && this.homeInformationDatas[id]){
      successFun(this.homeInformationDatas[id]);
      return;
    }
    let params = {
      params:{
        templateId:templateId
      }
    };
    if(typeId){
      params.params['typeId'] = typeId;
    }
    //拿时时数据
    this.http.get("/api/queryInformationHome",params).then((data)=>{
      id = data.templateId;
      this.homeInformationDatas[id] = data;
      successFun(this.homeInformationDatas[id]);
    }).catch((data)=>{
      errorFun(data);
    });
  }

  //查询首页框架模版
  queryTemplate(templateId,needTab,leagueId,raceType,isCache){
    return new Promise((rs,rj)=>{

      //所有模版缓存5分钟
      if (this.firstCacheTime) {
        if ((new Date().getTime() - this.firstCacheTime) > this.cacheTime) {
          this.homeDatas = {};
          this.firstCacheTime = new Date().getTime();
        }
      } else {
        this.firstCacheTime = new Date().getTime();
      }

      //有缓存就返回缓存数据
      let id = -999;
      if (templateId || templateId == 0) id = templateId;
      if (isCache && this.homeDatas[id]) {
        rs(this.homeDatas[id]);
        return;
      }
      let params = {
        params: {
          templateId: templateId,
          needTab: needTab
        }
      };
      if (leagueId) {
        params.params['leagueId'] = leagueId;
        params.params['raceType'] = raceType;
      }
      //拿时时数据
      this.http.get("/api/new/home", params).then((data) => {
        // this.http.get("/api/newHome", params).then((data) => {
        id = data.templateId;
        this.homeDatas[id] = data;
        rs(this.homeDatas[id]);
      }).catch((data) => {
        rj(data);
      });

    });
  }

  //查询首页推荐排序
  queryHomePlanData(modelId,sortType){
    return this.http.get("/api/queryHomePlanData",{params:{modelId:modelId,sortType:sortType},timeout:8000});
  }
  //查询首页子模块模版
  queryChildModuleData(modelId,sortType){
    return this.http.get("/api/queryChildModuleData",{params:{modelId:modelId,sortType:sortType},timeout:8000});
  }
  //查询用户等级
  userLevel(){
    return this.http.get("/api/home/userLevel");
  }

  //查询足彩时时数据
  queryMatchByIds(ids){
    let idValues = '';
    if(ids.length>0){
      for(let i=0;i<ids.length;i++){
        idValues += ids[i] + ",";
      }
      idValues = idValues.substring(0,idValues.length-1);
    }
    return this.http.get('/api/home/queryMatchByIds?ids='+idValues);
  }
  //查询篮彩时时数据
  queryBkMatchByIds(ids){
    let idValues = '';
    if(ids.length>0){
      for(let i=0;i<ids.length;i++){
        idValues += ids[i] + ",";
      }
      idValues = idValues.substring(0,idValues.length-1);
    }
    return this.http.get('/api/matchcenter/queryHomeBkMatchByIds?ids='+idValues);
  }
  //查询资讯更多数据
  queryMoreInformation(clientModuleId,lastMsgId){
    return this.http.get('/api/queryMoreInformation',{params:{clientModuleId:clientModuleId,lastMsgId:lastMsgId}});
  }

  //为了样式显示百分比
  minuteRound(match){
    let matchStartedMinutes = match.matchStartedMinutes;
    if(matchStartedMinutes.indexOf('90+') != -1){
      matchStartedMinutes = 90;
    }else if(matchStartedMinutes.indexOf('45+') != -1){
      matchStartedMinutes = 45;
    }
    if(match.status==2){
      matchStartedMinutes = 45;
      match.matchStartedMinutes = 45;
    }
    if(matchStartedMinutes > 90) {
      matchStartedMinutes = 90
    }
    if(match.status == 4){
      matchStartedMinutes = 90;
      match.matchStartedMinutes = 90;
    }
    if(!matchStartedMinutes){
      matchStartedMinutes = 0;
      match.matchStartedMinutes = 0;
    }
    match.matchStartedMinutesTime = matchStartedMinutes;
    match.round1 = matchStartedMinutes > 45?180:matchStartedMinutes*4;
    match.round2 = matchStartedMinutes > 45?(matchStartedMinutes*4-180):0;
    return match;
  }
  //为了样式显示百分比
  bkMinuteRound(match){
    let total = 10;
    if(match.leagueId == 1 || match.leagueId == 3){
      total = 12;
    }else if(match.status>55){
      //加时
      total = 5;
    }
    let matchTime = match.formateRemainTime.split(":")[0];
    let leftMinutest = parseInt(matchTime);
    let startMinutes = total - leftMinutest;
    let avg = startMinutes*360/total;
    let status = match.status;
    let round1 = 0;
    let round2 = 0;
    if(avg > 180){
      round1=180;
      round2 = avg-180;
    }else{
      round1 = avg;
      round2 = 0;
    }
    if(status == 55){
      round1 = 0;
      round2 = 0;
    }
    match.round1 = round1;
    match.round2 = round2;
    match.startMinutes = startMinutes;
    match.leftMinutest = leftMinutest;
    return match;
  }
  //根据比赛id列表拿比赛及时信息
  queryTimelyMatchInfo(aicaiLeagueId) {
    return this.dataHttp.post({apiName: 'getLeagueScoreFlag', aicaiLeagueId: aicaiLeagueId});
  }


  //查首页解读数据
  getCpzJdData(sortType,qiuFlag=1){
    // return this.http.get('/api/query/cpz/jdList',{params:{sortType:sortType,qiuFlag}});
    return this.http.get('/api/query/home/jdList',{params:{sortType:sortType,qiuFlag}});
  }
  //查询首页专家数据
  getCpzExpertData(qiuFlag=1){
    return this.http.get('/api/query/cpz/expertList',{params:{qiuFlag}});
  }

  //获取首页的轮播图
  getSwiperList(){
      return this.http.get("/api/home/swiper");
  }

  /**
   * 查询首页模块解读数据
   * @param modelId
   * @param raceType
   * @param sortType
   * @returns {any}
   */
  queryHomeInterpretationData(modelId, raceType, sortType) {
    return this.http.get("/api/home/queryHomeInterpretationData", {params: {modelId, sortType, raceType}, timeout: 8000});
  }

  /**
   * 查询首页模块推荐数据
   * @param modelId
   * @param gameId
   * @param sortType
   * @returns {any}
   */
  queryHomePlanDataNew(modelId, gameId, sortType) {
    return this.http.get("/api/home/queryHomePlanDataNew", {params: {modelId, sortType, gameId}, timeout: 8000});
  }
  //查询专家首页框架模版
  queryExpertTemplate(raceType) {

    return this.http.get("/api/new/expert/home", {params:{raceType}});
  }

    //获取我包月的专家
    getExpertMohthList() {
        return this.http.get('/api/private/expert/list');
    }

  //搜索热词
  queryHomeHotSearch(){
    return this.http.get('/api/search/hotWord')
  }
  //今日热搜
  searchByWords(words){
    return this.http.post('/api/search/wordList',{keyWord:words});
  }
  //搜索内容 详情
  searchForContent(words,searchType,pageNo){
    return this.http.post("/api/search/searchContent",{keyWord: words,pageNo:pageNo,searchType:searchType});
  }
  addCache (key,value){
    let searchCache:any = localStorage.getItem('words');
    if(searchCache){
      searchCache = JSON.parse(searchCache);
    }else{
      searchCache = {};
    }
    searchCache[key] = value;
    localStorage.setItem('words',JSON.stringify(searchCache));
  }


  getCache () {

    let searchCache:any = localStorage.getItem('words');
    if(searchCache){
      searchCache = JSON.parse(searchCache);
    }else{
      searchCache = {};
    }
    return searchCache;
  }
  getHistoryWord (key) {
    return this.getCache()[key] || [];
  }

  cacheWord (key,word) {
    if(!word){
      return;
    }
    let cache = this.getCache();
    let words = cache[key] || [];
    for(let i in words){
      if(word == words[i]){
        words.splice(i,1);
      }
    }
    words.unshift(word);
    if(words.length > 6){
      words.pop();
    }
    this.addCache(key,words);
  }
  clearHistoryWord (key) {
    this.addCache(key,[]);
  }
}
