import {Injectable} from "@angular/core";

import {ModalController} from '@ionic/angular';
import {AppHttp} from "../../../../services/app-http.service";
import {RootService} from "../../../../services/root.service";


@Injectable()
export class SearchService{

    catchKeys = {ftCatch:"ftDataSeach",bkCatch:"bkDataSeach"};

    constructor(
        public appHttp:AppHttp,
        public rootService: RootService,
    ){}

    addCache (type,key,value){
        let catchKey;
        if (type == 1) {
            catchKey = this.catchKeys.ftCatch;
        }else {
            catchKey = this.catchKeys.bkCatch;
        }
        let searchCache:any = localStorage.getItem(catchKey);
        if(searchCache){
            searchCache = JSON.parse(searchCache);
        }else{
            searchCache = {};
        }
        searchCache[key] = value;
        localStorage.setItem(catchKey,JSON.stringify(searchCache));
    }


    getCache (type) {
        let catchKey;
        if (type == 1) {
            catchKey = this.catchKeys.ftCatch;
        }else {
            catchKey = this.catchKeys.bkCatch;
        }

        let searchCache:any = localStorage.getItem(catchKey);
        if(searchCache){
            searchCache = JSON.parse(searchCache);
        }else{
            searchCache = {};
        }
        return searchCache;
    }
    getHistoryWord (type) {
        return this.getCache(type).words || [];
    }

    /**
     * type  足篮球
     * @param type
     * @param word
     */
    cacheWord (type,word) {
        if(!word){
            return;
        }
        let cache = this.getCache(type);
        let words = cache.words || [];
        for(let i in words){
            if(word == words[i]){
                words.splice(i,1);
            }
                //return;
        }
        words.unshift(word);
        if(words.length > 5){
            words.pop();
        }
        this.addCache(type,"words",words);
    }
    clearHistoryWord (type) {
        this.addCache(type,"words",[]);
    }
    getHotSearch(ballType) {
        return this.appHttp.get('/api/league/hotSearch', {params:{ballType:ballType}});
    }
}
