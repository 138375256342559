import {NgModule} from '@angular/core';
import {ClickReport} from "./click-report.directive";

@NgModule({
    declarations: [
        ClickReport,
    ],

    imports: [

    ],
    exports: [
        ClickReport,
    ]
})

export class ClickReportModule {
}
