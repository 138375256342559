import {Directive, ElementRef, HostListener, Input} from "@angular/core";
import {JcobStatisticsService} from "../../services/jcobStatistics.service";


@Directive({
    selector:"[rfc]"
})
export class ClickReport{

    @Input() rfc;
    @Input() jsonParams;

    constructor(public el: ElementRef,
                public statisticsService:JcobStatisticsService,
    ) {
    }

    @HostListener("click",["$event"])
    onclick(event){
        if(this.rfc) {
            this.statisticsService.reportStatisticForClick(this.rfc, this.jsonParams);
        }else{
            console.error("key值为空");
        }
    }


}
