import {EventEmitter, Injectable} from '@angular/core';
import {JsAutoLoadGuard} from './JsAutoLoadGuard';

/**
 * echarts 图表服务
 * 这里直接引入 echarts.min.js，是因为使用模块引入方式(import)会导致打包体积大很多
 * 因为echarts的模块化做的不好，所以这里先直接引入js
 */

@Injectable()
export class EchartsService {

    private echarts;

    //任务列表，主要用来缓存任务
    private loadingWtacher;        //加载中标志

	constructor() {
	}

    /**
     * 加载echart 并进行init
     * @return {Promise<any>}
     */
	public loadEchartsAndinit(ele,options):Promise<any> {
        
	    return this.getEcharts().then(()=>{
	        return  this.init(ele,options);
        });

    }

    /**
     * 获取echarts 对象
     * @return {Promise<any>}
     */
    public getEcharts():Promise<any>{
        return new Promise((rs,rj)=>{
            if(!this.echarts) {

                //js加载中，接口并发的情况
                if(this.loadingWtacher) {
                    const sub = this.loadingWtacher.subscribe(()=>{
                        rs(this.echarts);
                        sub.unsubscribe();
                    });
                    return;
                }

                this.loadingWtacher = new EventEmitter();
                new JsAutoLoadGuard().dynamicLoading("/assets/js/echarts.min.js").then(()=>{

                    this.echarts = (<any>window).echarts;
                    rs(this.echarts);

                    this.loadingWtacher.emit();
                    this.loadingWtacher = null;

                }).catch((e)=>{
                    rj("加载echarts失败")
                });
            }else {
                rs(this.echarts);
            }
        });
    }

    //图表init
    private init(ele,options) {

	    let echarts = this.echarts.init(ele);
        echarts.setOption(options);

        return echarts;
    }
}
