import {Injectable} from "@angular/core";
import {StorageService} from "../../../services/storage.service";
import {RootService} from "../../../services/root.service";
import {AppHttp} from "../../../services/app-http.service";
import {AppConfig} from "../../../services/app-config.service";


@Injectable()
export class MessageService{
  // private startTime;
  // private lock = true;
  private id;

  private num ={commentNum:0, likeNum:0, followNum:0};

  constructor(private storageService:StorageService,
              public rootService: RootService,
              public appConfig:AppConfig,
              private http:AppHttp){

  }

  setMessageCache(key,value){
    if (!this.rootService.getRoot().userInfo) return;
    this.id = this.rootService.getRoot().userInfo.id;
    // if (key == "followTime" && !this.lock){// 解决新增关注页面切换或刷新后无数据
    //   this.startTime = this.getMessageCache (key);
    //   this.lock = true;
    // }

    let messageCache:any = this.storageService.get(this.id + "messageCache");
    if(messageCache){
      messageCache = JSON.parse(messageCache);
    }else{
      messageCache = new MessageCache();
    }
    messageCache[key] = value;
    localStorage.setItem(this.id + "messageCache",JSON.stringify(messageCache));
  }

  getMessageCache (key) {
    if (!this.rootService.getRoot().userInfo) return 0;
    this.id = this.rootService.getRoot().userInfo.id;
    let messageCache:any = this.storageService.get(this.id + "messageCache");
    if(messageCache){
      messageCache = JSON.parse(messageCache);
    }else{
      messageCache = new MessageCache();
      localStorage.setItem(this.id + "messageCache",JSON.stringify(messageCache));
    }
    return messageCache[key];
  }

  /**
   * 查询消息数量
   * @return commentNum、followNum、likeNum
   */
  getMessageNum() {
    return this.http.post('/api/sns-new/message/getMessageNum', {
      commentTime: this.getMessageCache("commentTime"),
      likeTime: this.getMessageCache("likeTime"),
      followTime: this.getMessageCache("followTime")
    });
  }

  queryAttentionMeWithMember(expertId, pageNo) {
    // let time = new Date(this.startTime);
    // time.setMonth(time.getMonth()-3);
    return this.http.post('/api/sns-new/message/attentionMe' ,{
      expertId:expertId,
      pageNo:pageNo,
      startTime:0
    });
  }

  isFollow(memberId) {
    return this.http.get("/api/sns/isFollow", {params: {memberId: memberId}});
  }

  // unLock(){// 解决新增关注页面切换或刷新后无数据,释放锁
  //   this.lock=true;
  // }

  setNum(num:any){
    this.num = num;
  }

  getNum(){
    return this.num;
  }

}

class MessageCache {
  commentTime;
  likeTime;
  followTime;
  constructor() {
    let now = new Date().getTime();
    this.commentTime = now;
    this.likeTime = now;
    this.followTime = now;
  }
}

