import {Injectable} from '@angular/core';
import {AppHttp} from "./app-http.service";
import {StorageService} from "./storage.service";
import {Events} from "./events.service";
import {ClientPlatform} from "./client-platform.service";
import {SessionStorageService} from "./sessionStorage.service";
import {AppConfig} from "./app-config.service";
import {UserCenterService} from "../template/user-center/shared/user-center.service";
import {LoginCacheService} from "../template/account/login/login-cache.service";
import {EncryptService} from "./encrypt.service";

/**
 * 京东服务
 */
declare var encryptPwdUtil:any;
declare  var JWeixin:any;
@Injectable()
export class JdSdkService {

  private jd = (<any>window).jdSdk;
  userValidate = (<any>window).userValidate;

  public _jdData;
  public tryCount = 0;    //登陆失败重试次数统计

  constructor(
    public http:AppHttp,
    public storageService: StorageService,
    public session: SessionStorageService,
    public event: Events,
    public appConfig:AppConfig,
    public platform:ClientPlatform,
    public userCenterService:UserCenterService,
    public loginCache: LoginCacheService,
    public encrypt:EncryptService,
  ) {

  }

  /**
   * 京东登录
   * @returns {Promise<any>}
   */
  public jdLogin(){

      this.jdData.then((data1:any)=>{
        //如果已登录 且ticket相同，不再重新登陆
        if(this._jdData && this._jdData.ticket == data1.ticket&&this.checkLogin()) {
          console.log("已经登录");
        }else {

          let ticket = this.encrypt.encrypt(data1.ticket);
          //登陆
          this.http.post("/api/jd/h5/login/",{ticket}).then((data)=>{

            this.loginCache.loginSuccessAfter(data.data.token,'jingdong');
            setTimeout(()=>{
              this.userCenterService.index();
            },50);
            this.storageService.set("jcob_uuid",data.data.uuid);

            this._jdData = data1;

          }).catch((res)=>{

            this.tryLogin();

          });
        }
      }).catch(()=>{
        this.tryLogin();
      });

  }

  //获取京东数据
  get jdData() {
    return new Promise((rs,rj)=>{

      //先调用京东登录方法 获取用户信息
      this.jd.JDCYLogin((data)=>{
        // console.log(data);
        if(data && data.ticket) {
          rs(data);
        }else {
          rj();
        }
      });

    })
  }
  //获取币种信息 如果获取失败 默认返回1
  get currency() {
    if(this._jdData)
      return this._jdData.currency;
    return 1;
  }

  //尝试重新登录
  private tryLogin() {
    console.log("登录失败,重试:"+this.tryCount);
    if(this.tryCount < 3) {
      this.tryCount++;
      setTimeout(()=>{
        this.jdLogin();
      },500);
    }else {
      this.tryCount = 0;
    }
  }

  //京东支付 跳转支付页面
  public jdPay(payUrl) {
    this.jd.JDCYPay(payUrl);
  }

  //检查登陆
  checkLogin() {
    return this.loginCache.isLogin();
  }

}
