import { Injectable } from '@angular/core';
import {AlertService} from "../../services/alert.service";
import {CaptchaValidateComponent} from "./captcha-validate.component";

/**
 * 滑块验证指令服务
 */
@Injectable()
export class CaptchaValidateService {

    constructor(
        private alert:AlertService,
    ) {}

    /**
     *  使用滑块验证码做验证，如果成功返回rs(),失败返回rj()
     * @return {Promise<boolean>}
     */
    public checkWithCaptcha():Promise<boolean> {

        return new Promise((rs,rj)=>{

            this.alert.createModal2(CaptchaValidateComponent).then((data:any)=>{
                if(data) {
                    rs(true);
                }else {
                    rj(false);
                }
            });

        });
    }

}
