import {CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot} from "@angular/router";
import {Injectable} from "@angular/core";
import {AppConfig} from "./app-config.service";
import {LoginCacheService} from "../template/account/login/login-cache.service";

/**
 * 微信端屏蔽页面，跳转首页
 * 搜索 http://localhost:8100/#/expert-search;type=
 * 专家tab http://localhost:8100/#/tabs/plan
 * 圈子首页 http://localhost:8100/#/sns/index/1
 * 圈子详情页 http://localhost:8100/#/sns/detail/24304529;reply=false
 * 圈子专题页 http://localhost:8100/#/sns/hot-topic-detail;title=%E5%9C%88%E5%AD%90%E5%86%85%E8%B0%A8%E9%98%B2%E5%B9%BF%E5%91%8A%E8%AF%88%E9%AA%97%E8%A1%8C%E4%B8%BA%E5%85%AC%E5%91%8A;topicPicture=https:%2F%2Frm.aicai.com%2Fupload%2Fjcob%2Fsns%2Fmsg%2F2020%2F05-31%2F73b6ea15e3814dbfadb40a6553481d05_255_255.png;msgId=21990515;hotDiscuss=%E8%BF%91%E6%9C%9F%E6%AF%94%E8%B5%9B%E6%81%A2%E5%A4%8D%E5%B9%BF%E5%91%8A%E5%85%9A%E5%A4%9A%E4%BB%A5%E8%AF%88%E9%AA%97%E4%B8%BA%E7%9B%AE%E7%9A%84
 * 专家主页 http://localhost:8100/#/public/expertHome/63952
 * 解读详情页 http://localhost:8100/#/tjInterpretation/1084308
 * 推荐详情页 http://localhost:8100/#/plan-detail/202012145036686
 * 会员首页 http://localhost:8100/#/vip/buy;type=2
 * 会员频道 http://localhost:8100/#/vip/index;type=3
 * 发布推荐 http://localhost:8100/#/publish/zqtj/2c1
 * 我的会员 http://localhost:8100/#/user/vip
 */

@Injectable()
export class WechatGuard implements CanActivate {

  constructor(
    private router: Router,
    public appConfig:AppConfig,
    public loginCache: LoginCacheService,
  ) {

  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // if(this.appConfig.isWechat) {
    //   this.router.navigate(['/tabs/home']);
    //   return false;
    // }

    return true;

  }
}
