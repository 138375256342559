import {CanLoad, Route} from "@angular/router";
import {Observable} from "rxjs/Observable";

/**
 * flexible.750.min.js 动态加载
 * 可以在加载子模块的地方添加守护路由，注意离开页面时需要使用JsAutoUnLoadGuard 删掉此js
 */
export class JsAutoLoadGuard implements CanLoad {


  canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {

    return new Promise((rs)=>{

      this.dynamicLoading("/assets/js/flexible.750.min.js").then(()=>{

        rs(true);

      }).catch(()=>{
        rs(false);
      });

    });
  }

  //动态加载js
  dynamicLoading(path):Promise<any> {

    return new Promise((rs,rj)=>{
      if(this.checkJsExist(path)) {
        return rs();
      }
      if(!path || path.length === 0){
        console.error("css路径为空")
      }
      let head = document.getElementsByTagName('head')[0];
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = path;

      script.onload = ()=>{
        rs();
      };

      script.onerror = ()=>{
        return rj();
      };

      head.appendChild(script);
    });


  };

  //检查js是否存在
  checkJsExist(url) {
    try {

      let host = window.location.protocol+'//'+window.location.host;

      if(url.indexOf('http') == -1)
        url = host + url;

      let scripts = window.document.getElementsByTagName("script");

      if(scripts && scripts.length > 0) {

        for (let key in scripts) {
          if(scripts[key] && scripts[key].src &&  scripts[key].src == url) {
            return true;
          }
        }
      }
    }catch (e){
      return false;
    }

    return false;
  }



}
