import {Injectable} from "@angular/core";
import {AppHttp} from "../../../../services/app-http.service";
import {StorageService} from "../../../../services/storage.service";
import {RootService} from "../../../../services/root.service";
import {Events} from "../../../../services/events.service";


/*
 * 专家留言服务
 */
@Injectable()
export class ExpertNoteService {
    id: number;

    constructor(public http: AppHttp,
                public storageService: StorageService,
                public rootService: RootService,
                public events: Events) {

    }

    // 查询留言列表
    queryNoteList(pageNo: number) {
        return this.http.get("/api/expert/noteList/" + pageNo);
    }

    // 查询未读留言
    queryNewExpertNoteCount() {
        return this.http.get("/api/expert/queryNewExpertNoteCount", {params: {beginTime: this.getCache()}});
    }

    // 回复留言
    submitReply(noteId: number, content: string, display: number, reply:number) {
        return this.http.post("/api/expert/replyTjInterNote", {noteId, content, display, reply});
    }

    // 回复留言前检查
    checkTjInterNote(noteId) {
        return this.http.get("/api/expert/checkTjInterNote/" + noteId);
    }

    // 修改留言展示状态
    changeTjInterNoteDisplay(noteId: number, display: number) {
        return this.http.post("/api/expert/changeTjInterNoteDisplay", {noteId, display});
    }

    setCache() {
        if (!this.rootService.getRoot().userInfo) return;
        this.id = this.rootService.getRoot().userInfo.id;
        this.storageService.set(this.id + 'expert-note-num', JSON.stringify(new Date().getTime()));
    }

    getCache() {
        if (!this.rootService.getRoot().userInfo) return 0;
        this.id = this.rootService.getRoot().userInfo.id;
        let cache: any = this.storageService.get(this.id + 'expert-note-num');
        if (!cache) {
            this.setCache();
            return null;
        }
        return JSON.parse(cache);
    }
}
