import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
// import {Alert} from "../component/alert.component";
// import {Msg} from "../component/msg.component";
// import {Loading} from "../component/loading.component";
import {JsAutoLoadGuard} from '../services/JsAutoLoadGuard';
import {HomeGuard} from '../services/home-guard.service';
import {LoginGuard} from '../template/account/login/login-guard.service';
import {HomeFit} from '../template/home-new/home-fit';
import {PlatformRedirectGuard} from '../services/platform-redirect-guard.service';
// import {TabsPage} from '../template/tabs/tabs';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TxStatisticsModule} from '../directives/tx-statistics/tx-statistics.module';
import {ClickReportModule} from "../directives/click-report/click-report.module";
// import {JsAutoUnLoadGuard} from '../services/JsAutoUnLoadGuard';
// import {HomeTitanModule} from "../template/home-new/home-titan/home-titan.module";
// import {WechatGuard} from '../services/wechat-guard.service';

const routes: Routes = [
  {
    path: 'home',
    component: HomeFit,   // 首页适配页面
    canActivate: [HomeGuard]
  },
  {
      path: 'tabs',
      // component: TabsPage,
      children: [
          {
              path: 'home',
              loadChildren: () => import('../template/home-new/home-titan/home-titan.module').then(m => m.HomeTitanModule),
          },
          {
              path: 'match',
              loadChildren: () => import('../template/match/football/match-list/zq-match-list.module').then(m => m.ZqMatchListPageModule),
          },
          {
              path: 'match-bk',
              loadChildren: () => import('../template/match/basketball/lq-match-list.module').then(m => m.LqMatchListPageModule),
          },
          {
              path: 'user',
              loadChildren: () => import('../template/user-center/user.module').then(m => m.UserModule),
          },
          // {
          //     path: 'news',
          //     loadChildren: '../template/information/information.module#InformationPageModule',
          // },
          {
              path: '',
              redirectTo: 'home',
              pathMatch: 'full'
          },
          {
              path: '**',
              redirectTo: 'home',
          }
      ]
  },
   /*APP首页 -- tabs页*/
  /*{
      path: 'tabs',
      component: TabsPage,
      children: [
          {
              path: 'home',
              loadChildren: () => import('../template/home-new/home-discovery/home-discovery.module').then(m => m.HomeDiscoveryModule),
          },
          {
              path: 'match',
              loadChildren: () => import('../template/match/football/match-list/zq-match-list.module').then(m => m.ZqMatchListPageModule),
          },
          {
              path: 'match-bk',
              loadChildren: () => import('../template/match/basketball/lq-match-list.module').then(m => m.LqMatchListPageModule),
          },
          {
              path: 'plan',
              loadChildren: () => import('../template/home-new/home-plan/home-plan.module').then(m => m.HomePlanModule),
          },
          {
              path: 'user',
              loadChildren: () => import('../template/user-center/user.module').then(m => m.UserModule),
          },
          {
              path: 'data',
              loadChildren: () => import('../template/data/data.module').then(m => m.DataModule),
          },
          {
              path: 'jd',
              loadChildren: () => import('../template/user-center/user-center-module/user-center.module').then(m => m.UserCenterModule),
          },
          {
              path: '',
              redirectTo: 'home',
              pathMatch: 'full'
          },
          {
              path: '**',
              redirectTo: 'home',
          }
      ]
  },*/

  {
    path: 'home-discovery',
      redirectTo: '/tabs/home',
  },
  {
      path: 'user/center',
      redirectTo: '/tabs/user/center',
      // loadChildren: () => import('../template/user-center/user.module').then(m => m.UserModule),
  },
  {
    path: 'user',
    loadChildren: () => import('../template/user-center/user.module').then(m => m.UserModule),
    canLoad: [JsAutoLoadGuard]
  },


  {
    path: 'public',
    loadChildren: '../template/public/public.module#PublicModule'
  },
    {
    path: 'service/ios',
    loadChildren: '../template/user-center/service-center/for-ios-main2/service-center-ios.module#ServiceCenterIosModule'
  },
  {
    path: 'data',
    loadChildren: '../template/data/data.module#DataModule',
  },
  {
    path: 'sns',
    loadChildren: '../template/message/shared/sns.module#SnsPageModule',
  },
  {
    path: 'login',
    loadChildren: '../template/account/login/login.module#LoginModule',
    canActivate: [LoginGuard],
  },
  {
    path: 'register',
    loadChildren: '../template/account/register/register.module#RegisterModule',
  },
  {
    path: 'retrieve',
    loadChildren: '../template/account/password/find-password.module#FindPasswordPageModule',
  },
  {
    path: 'userHuoyanDetail',
    loadChildren: '../template/user-center/jinbi-detail/jinbi-detail.module#JinbiDetailPageModule',
  },
  {
    path: 'userMoneyDetail',
    loadChildren: '../template/user-center/money-detail/money-detail.module#MoneyDetailPageModule',
  },
  {
    path: 'userDrawMoneyDetail/:id',
    loadChildren: '../template/user-center/draw-detail/draw-detail.module#DrawDetailPageModule',
  },
  {
    path: 'userDrawMoney',
    loadChildren: '../template/user-center/draw-money/draw-money.module#DrawMoneyPageModule',
  },
  {
    path: 'pay',
    loadChildren: '../template/user-center/charge/charge.module#ChargePageModule',
  },
  {
    path: 'userChargeFinish',
    loadChildren: '../template/user-center/charge/charge.module#ChargePageModule',
  },
  {/*专家套餐订购记录*/
    path: 'set-meal-record',
    loadChildren: '../template/user-center/set-meal-record/set-meal-record.module#SetMealRecordModule',
  },
  {/*专家套餐列表*/
    path: 'set-meal-list',
    loadChildren: '../template/set-meal/set-meal-list.module#SetMealListModule',
  },
  /*推球用户中心*/

  {
      path: 'special-home',
      loadChildren: '../template/home-new/special/special.module#SpecialPageModule',
  },
  {
    path: 'expert',
    loadChildren: '../template/expert/expert-list/expert-wb.module#ExpertWbModule',
  },
  {
    path: 'expertList',
      redirectTo:'expertList',  //老的排行榜不要了，直接重定向
  },
  {
    path: 'expertListDetail',
      redirectTo:'expertList',  //老的排行榜不要了，直接重定向
  },
{
    path: 'match-packet/packet',
    loadChildren: '../template/match/match-red-packets/match-red-packets.module#MatchRedPacketsModule'
  },
  {/*足球赛事列表*/
    path: 'match-list',
    // loadChildren: '../template/match/football/match-list/zq-match-list.module#ZqMatchListPageModule',
      redirectTo: '/tabs/match',

  },
  {/*篮球赛事列表*/
    path: 'match-list-bk',
      redirectTo: '/tabs/match-bk',
    // loadChildren: '../template/match/basketball/lq-match-list.module#LqMatchListPageModule',
  },
  {
    path: 'bk-handicap-detail',
    loadChildren: '../template/match/basketball/bk-odds-detail/match-detail-bk-handicap-change.module#MatchDetailBkHandicapChangeModule',
  },
  {/*足球赛事详情*/
    path: 'match-detail',
    loadChildren: '../template/match/football/match-detail/match-detail-football.module#MatchDetailFootballModule',
  },
  {/*足球赛事详情*/
    path: 'recommend',
    loadChildren: '../template/match/football/match-detail/match-detail-football.module#MatchDetailFootballModule',
  },
  {/*篮球赛事详情*/
    path: 'bk-match-detail',
    loadChildren: '../template/match/basketball/bk-match-detail/bk-match-detail.module#BkMatchDetailModule',
  },
  {/*篮球名词解释*/
    path: 'bk-explanation',
    loadChildren: '../template/match/basketball/explanation/explanation.module#ExplanationModule',
  },
  {
    path: 'odds-detail',
    loadChildren: '../template/match/football/match-detail/odds/odds-detail/handicap-change.module#HandicapChangeModule',
  },
  /*expert*/
  {
    path: 'expert-article',
    loadChildren: '../template/expert/expert-article/expert-article.module#ExpertArticleModule',
  },

  {
    path: 'expert-replay',
    loadChildren: '../template/expert/expert-replay/expert-replay.module#ExpertReplayModule',
  },
  {
    path: 'expertReplayDetail',
    loadChildren: '../template/expert/expert-replay/expert-replay.module#ExpertReplayModule',
  },
  {
    path: 'expert-search',
    loadChildren: '../template/expert/expert-search/expert-search.module#ExpertSearchPageModule',
    // canActivate: [WechatGuard]
  },
  {
    path: 'expert-note',
    loadChildren: '../template/expert/expert-note/expert-note.module#ExpertNoteModule',
  },
  {
    path: 'leagueRecord',
    loadChildren: '../template/expert/expert-home/league-record/league-record.module#LeagueRecordPageModule',
  },
  {
    path: 'leagueRecordDetail',
    loadChildren: '../template/expert/expert-home/league-record/league-record-detail.module#LeagueRecordDetailPageModule',
  },
  /*jiedu*/
  {
    path: 'tjInterpretation',
    loadChildren: '../template/plan-jiedu/jiedu-detail/jiedu-detail.module#JieduDetailModule',
  },
  {
    path: 'my-look-jiedu',
    loadChildren: '../template/plan-jiedu/my-look-jiedu/my-look-jiedu.module#MyLookJieduModule',
  },
  {
    path: 'my-post-jiedu',
    loadChildren: '../template/plan-jiedu/my-post-jiedu/my-post-jiedu.module#MyPostJieduModule',
  },
  {
    path: 'jiedu-list',
    loadChildren: '../template/plan-jiedu/jiedu-list/jiedu-list.module#JieduListModule',
  },
  {
    path: 'cpzJieduList',
    redirectTo: 'jiedu-list'
  },
  {
    path: 'lcPlanList',
    redirectTo: 'plan-list/2/all',
  },
  {
    path: 'zcPlanList',
    redirectTo: 'plan-list/1/all',
  },
  /*用户修改实名认证页面*/
  {
    path: 'change/userInfoRecomplete',
    loadChildren: '../template/user-center/user-info/user-info-recomplete/userInfoRecomplete.module#UserInfoRecompleteModule',
  },
  /*直播页面*/
  {
    path: 'home/live',
    loadChildren: '../template/home-new/home-live/home-live.module#HomeLiveModule',
  },
  /*专家页面*/
  {
    path: 'home/plan',
      redirectTo: '/tabs/plan',
    // loadChildren: '../template/home/home-plan/home-plan.module#HomePlanModule',
  },
  /*我的vip*/

  {
    path: 'message',
    loadChildren: '../template/message/message.module#MessageModule',
  },
  {
    path:'expert-push',
    loadChildren: '../template/expert/expert-push/expert-push.module#ExpertPushModule',
  },
  {
    path: 'my-expert-month',
    loadChildren: '../template/plan-jiedu/private-jd/my-expert-month/my-expert-month.module#MyExpertMonthModule',
  },

  {
    path:'home-search',
    loadChildren: () => import('../template/home-new/home-search/home-search.module').then(m => m.HomeSearchModule),
  },
    {
    path:'match/long-page',
    loadChildren: () => import('../template/match/match-long-page/match-long-page.module').then(m => m.MatchLongPageModule),
  },
  // {
  //     path: 'information/ichild',
  //     loadChildren: '../template/information/information.module#InformationPageModule',
  // },
  {
    path: '',
    redirectTo: '/tabs/home',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/tabs/home',
  }
];

@NgModule({
  declarations: [
    // TabsPage,
    HomeFit,
  ],
    imports: [
        // SharedModule,    //@fixme 延迟加载
        TxStatisticsModule,   //主要tab用到了
        ClickReportModule,
        CommonModule,
        IonicModule,
        RouterModule.forRoot(routes),
    ],
  exports: [ RouterModule ],
  providers: [
    PlatformRedirectGuard,
  ]
})
export class AppRoutingModule { }
