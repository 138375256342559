import {Injectable} from '@angular/core';
import {AppHttp} from '../../services/app-http.service';

/**
 * 专家套餐 服务
 */

@Injectable()
export class SetMealService {


    constructor(public http: AppHttp) {

    }


    /**
     * 获取专家套餐列表
     * @param scope 套餐类型 @link SET_MEAL_SCOPE
     * @param pageNo
     * @param {number} pageSize
     */
    getSetMealList(scope, pageNo, pageSize = 10) {
        return this.http.get('/api/setMeal/list/'+scope, {params: {pageNo, pageSize}});
    }

    /**
     * 获取专家套餐页 展示的专家
     */
    getExpertList() {
        return this.http.get('/api/setMeal/expert/list');
    }

    /**
     * 获取现有套餐次数
     * * 套餐分层：使用新接口，查询竞彩和胜负彩套餐
     */
    getSetMealCount(expertId?) {
        return this.http.get('/api/setMeal/new/count', {expertId});
    }

    /**
     * 购买套餐
     */
    paySetMeal(pkgId) {
        return this.http.get('/api/setMeal/pay', {params: {pkgId}});
    }

    /**
     * 购买套餐，直付生成订单并跳转直付
     * chargeWay 支付方式 3支付宝wap 4微信wap 5微博wap
     */
    preDualSetMealPay(chargeWay, pkgId, returnUrl) {
        return this.http.post('/api/pay/preDualSetMealPay', {chargeWay, planId: pkgId, returnUrl});
    }

    //获取支付状态 id：支付订单id
    public getPayStatus(id) {
        return this.http.get('/api/check/pay/status', {params: {id}});
    }
    //获取广告位置
    public getAdImg(adLocation) {
        return this.http.get('/api/page/ad/location', {params: {adLocation}});
    }

}
