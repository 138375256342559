import {Injectable} from '@angular/core';
import JSEncrypt from 'jsencrypt/bin/jsencrypt.min.js'

/**
 * 数据加密服务
 */

@Injectable()
export class EncryptService {

    private encryptPwdUtil;

	constructor() {
        this.encryptPwdUtil = new JSEncrypt({});
        this.encryptPwdUtil.setPublicKey("MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQD02UHCRGl980BSL8nxp6kKmvkkmwLCy8DFSPc2khzwiWdf42RWx7LBk7T6u1mxtjnatazhdzbiwTDR7SybPSz6LX2J/f9yn0tUYVwQ5qnnB+G7e1sTE9CvHFwTh8YJpZgB79c06fLHNMiWqpzEvLoqhEsLHAUmu3WEYW8t7M9yQwIDAQAB");
	}

    /**
     * 数据加密
     * @param text 需要加密的串
     * @param salt 增强加密的“盐”
     */
    public encrypt (text,salt?) {

        let str = text;
        if(salt)
            str += salt;

        return this.encryptPwdUtil.encrypt(str);
    }

}
