import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from "../services/alert.service";

@Component({
  selector:'msg',
  template: `
<style>
       :host{
          height:100%;width:100%;z-index:1004;position:absolute 
       }
       .content{
          border-radius: 8px;
          background: rgba(0, 0, 0, 0.9);
          padding:18px;
          font-size: 16px;
          color: #fff;
          -webkit-animation-name: superScaleIn;
          animation-name: superScaleIn;
          -webkit-animation-duration: .2s;
          animation-duration: .2s;
          -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
       }
       .wrapper{
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          display: -webkit-flex;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          -moz-justify-content: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          -moz-align-items: center;
          align-items: center;
          position:absolute;
          }
          @keyframes superScaleIn{from{transform:scale(1.2);opacity:0}to{transform:scale(1);opacity:1}}
      </style>
       <div class="wrapper">
            <div class="content">{{msgContent}}
            </div>
       </div>
`
})
export class Msg implements OnInit{

  msgContent;

  constructor(public router: Router,
              public route:ActivatedRoute,
              public alertService:AlertService) {

  }

  ngOnInit(){
   /* let duration = 2000;
    this.route.params.forEach((v)=>{
      if(v.duration)
        duration = v.duration;
    });*/

    this.msgContent = this.alertService.content;
   /* if(!this.msgContent){
      this.router.navigate([{ outlets: { msg: null }}],{skipLocationChange:true});
      return;
    }
    setTimeout(()=>{
      this.router.navigate([{ outlets: { msg: null }}],{skipLocationChange:true});
      this.alertService.msgContent = null;
    },duration)*/
  }
}
