import {Injectable} from '@angular/core';
import {StorageService} from './storage.service';
import {LoginCacheService} from '../template/account/login/login-cache.service';
import {CouponService} from '../template/user-center/coupon-list/coupon-confirm/coupon.service';
import {AppConfig} from './app-config.service';
import {AlertService} from './alert.service';
import {NavCtrlService} from './nav-controller.service';
import {AppHttp} from './app-http.service';
import {RootService} from './root.service';
import {BaseType} from '../model/const-config.model';
import {PageRouterService} from './page-router.service';

interface BaoyueMemberRss{
    rssId:string,
    memberId:number,
    expertMemberId:number,
    expertNickName:string,
    dayMatchType:number,
    payAmount:number,
    endTimeDesc:string,
}
//弹窗规则
const PopTriggerType = {
    day:new BaseType(0,"每天弹"),
    timing:new BaseType(1,"定时弹"),
    once:new BaseType(2,"马上弹"),
};
/**
 * app启动弹窗调配 服务
 */
@Injectable()
export class AppStartConfirmService {


    constructor(
        private loginCache:LoginCacheService,
        public storage:StorageService,
        public storageService:StorageService,
        public alertService:AlertService,
        public navCtrl:NavCtrlService,
        public appConfig:AppConfig,
        public couponService: CouponService,
        public rootService:RootService,
        public http:AppHttp,
        public pageRouterService: PageRouterService,
                ) {
        //在派券提醒调用时进行刷新了
        // this.refreshCouponList();

      /*  this.loginCache.loginSuccessWatcher.subscribe(()=>{
            setTimeout(()=>{
                this.showLoginConfirm();
            },2000);
        });*/

    }

    /**
     * 显示app启动需要显示的弹窗
     */
    /*public async showAppStartConfirm() {
        return this.showLoginConfirm();
    }

    /!**
     * 显示登录成功需要显示的弹窗
     *!/
    public async showLoginConfirm() {

        //4、新券提醒（与5互斥）
        if(this.appConfig.showCoupon){
            console.info("弹窗检测：新券提醒");
            let showCouponDialog = await this.showNewCouponConfirm();
            if(showCouponDialog)return;
        }


        //5、包月到期提醒
        if(this.appConfig.showBaoyue){
            console.info("弹窗检测：包月到期提醒");
            let showBaoyueExpireAlert:boolean = await this.showExpertMonthPushExpireLog();
            if(showBaoyueExpireAlert)return;
        }


        //6、vip相关
        if(this.appConfig.showVip){
            console.info("弹窗检测：vip相关");
            let showVipDialog = await this.confirmVipWhenAppStartOrLogin();
        }

    }

    public async showWechatAttentionThenAdPop():Promise<boolean>{
        await this.showWechatAttentionConfirm();
        return this.showAdPop();
    }

    //广告弹窗
    async showAdPop():Promise<boolean> {
        let result: any = await this.http.get("/api/appVersion/checkUpdatePopAndAlert");

        if(!result.adId)return false;

        let adConfirmRule = result.popRule;//启动广告弹窗规则
        let contentText = result.content;//广告文字
        let picUrl = result.picUrl;//广告图片
        let urlRedirect = result.urlRedirect;
        let adConfirmCacheKey = 'ad_confirm_cache_key';//启动广告弹窗缓存key

        let confirm = (adCache,id)=>{
            //保存弹窗的时间
            adCache.time = new Date().getTime();
            adCache.id = id;
            this.storageService.setObject(adConfirmCacheKey,adCache);
            showAdConfirm();
        };
        //在某一个时间弹窗
        let confirmInTime = (time,adCache,id,loop=false)=>{
            try {
                let delayTime = time - new Date().getTime();

                if(isNaN(delayTime)) {
                    console.error("弹窗时间错误");
                    return;
                }

                //已经过去5分钟了
                if(delayTime < (-5 * 60 * 1000)) {
                    if(loop) {
                        time += (24 * 60 * 60 * 1000);
                        confirmInTime(time,adCache,id,loop);
                    }
                }else {

                    //到指定时间弹窗
                    setTimeout(()=>{
                        confirm(adCache,id);

                        if(loop) {
                            time += 24 * 60 * 60 * 1000;
                            confirmInTime(time,adCache,id,loop);
                        }

                    },delayTime);
                }
            }catch (e){
                console.log(e);
            }
        };
        let showAdConfirm = ()=>{
            let content;
            if(picUrl){
                content = `
                    <div class="popup-container popup_adIWrap popup-showing active zIndex999">
                      <div class="popup">
                        <div class="popup-head"><h3 class="popup-title"></h3></div>
                        <div class="popup-body">
                          <div class="popup_adI">
                            <a class="alert-submit"><img src="${picUrl}" ></a>
                            <a class="popup_adI_btn alert-close"></a>
                          </div>
                        </div>
                      </div>
                    </div>
                `;
            }else if(contentText){
                content = `
                    <div class="popup-container expert-popup popup-showing active zIndex999">
                      <div class="popup">
                        <div class="popup-head"><h3 class="popup-title"></h3></div>
                        <div class="popup-body">
                          <div class="popup_Box">
                            <div class="popup_Box_t"><p>${contentText}</p></div>
                          </div>
                        </div>
                        <div class="popup-buttons popup-bdt">
                          <a class="button button-default alert-close">取消</a>
                          <a class="button button-positive alert-submit">确认
                          </a>
                        </div>
                      </div>
                    </div>
                `;
            }
            return this.alertService.confirmNew(content,true,true).then((data)=>{
                if(data.type=='submit'){
                    this.pageRouterService.stateGo(this.navCtrl,urlRedirect.redirectType,urlRedirect.inner,urlRedirect.toId);
                }
            }).catch((e)=>{});
        };

        //根据规则判断是否需要弹窗
        let checkShowConfirm = (id):boolean=>{
            if(!adConfirmRule) return false;

            let adCache = this.storageService.getObject(adConfirmCacheKey) || {};
            //不是同一个弹窗 清空cache
            if(adCache.id != id) adCache = {};

            //只弹一次，已经弹过了
            if(!adCache.time && adConfirmRule.triggerType == PopTriggerType.once.index) {

                 confirm(adCache,id);
                 return true;

            }else if(adConfirmRule.triggerType == PopTriggerType.day.index) {
                //每天都弹

                //定时弹
                if(adConfirmRule.triggerDate) {

                    let now = new Date();     //当前时间
                    let dateStr = `${now.getFullYear()}/${now.getMonth()+1}/${now.getDate()} ${adConfirmRule.triggerDate}`;
                    let time = new Date(dateStr).getTime();

                    //今天弹过
                    if(adCache.time && this.isToday(adCache.time)) {
                        time += (24 * 60 * 60 * 1000);
                    }

                     confirmInTime(time, adCache, id, true);
                    return false;

                }else if(!adCache.time || !this.isToday(adCache.time)){
                    //今天没有弹过
                     confirm(adCache,id);
                    return true;
                }

            }else if(!adCache.time && adConfirmRule.triggerType == PopTriggerType.timing.index){
                //定时弹

                if(adConfirmRule.triggerDate) {
                    let dateStr = adConfirmRule.triggerDate.replace(/-/g,'/');
                     confirmInTime(new Date(dateStr).getTime(), adCache, id, false);
                    return false;
                }else {
                    //没有时间直接弹
                     confirm(adCache,id);
                    return true;
                }
            }
        };

         return checkShowConfirm(result.adId);
    }

    //微信关注弹窗
    async showWechatAttentionConfirm(){
        let content = `
            <div class="attentionPublic">
              <img src="assets/img/agency/attentionPub.png" alt="">
              <div class="btn border bdt bdc_eee">
                <a class="c333 alert-close"  (click)="weiChatStartConfirm()">知道了</a>
                <a class="blue border bdl bdc_eee alert-submit" (click)="changeWechatAttentionStatus()">我已关注</a>
              </div>
            </div>
        `;
        return this.alertService.confirmNew(content,true,true).then((data)=>{
            if(data.type=='submit'){
              console.info('我已关注');
              this.storageService.set("wechatAttentionFlag","true");
            }
        }).catch(()=>{
            console.info('知道了');
        });
        //微信弹完，会弹checkAdPop
    }

    //优惠券弹窗
    async showNewCouponConfirm(){
        return this.couponService.showNewCouponConfirm().then(()=>{
            return true;
        }).catch(()=>{
            return false;
        })
    }

    //会员弹窗
    async confirmVipWhenAppStartOrLogin(){
        return this.vipConfirm.confirmWhenAppStartOrLogin().then(()=>{
            return true;
        }).catch(()=>{
            return false;
        })
    }

    //包月到期弹窗
    async showExpertMonthPushExpireLog():Promise<boolean>{
        let data:any = await this.http.get("/api/push/checkAllExpire");
        if(!data){return false;}
        let threeExpireList:BaoyueMemberRss[] = data.three;
        if(threeExpireList){
            let id:string = this.checkExpireListAlertCache('three',threeExpireList);
            let alertRss:BaoyueMemberRss = threeExpireList.find(c=>c.rssId==id);
            if(alertRss){
                return this.showBaoyueExpireAlert(alertRss);
            }
        }
        let sevenExpireList:BaoyueMemberRss[] = data.seven;
        if(sevenExpireList){
            let id:string = this.checkExpireListAlertCache('seven',sevenExpireList);
            let alertRss:BaoyueMemberRss = sevenExpireList.find(c=>c.rssId==id);
            if(alertRss){
                return this.showBaoyueExpireAlert(alertRss);
            }
        }
        return false;
    }

    private showBaoyueExpireAlert(alertRss:BaoyueMemberRss):Promise<boolean>{
        let userNickName:string = this.rootService.getUserInfo() && this.rootService.getUserInfo().nickName||'用户';
        let expertNickName:string = alertRss.expertNickName+'';
        let expireTime:string = alertRss.endTimeDesc;
        let content = `
                        <div class="wd_zjby_expire">
                            <div class="top">
                                <i></i>
                                <h3>专家包月私推</h3>
                                <p>专属私人定制，最高享5折优惠</p>
                            </div>
                            <div class="bottom">
                                <div class="txt">
                                    <p>尊敬的${userNickName}：</p>
                                    <div class="mt13">您订购<span class="red">${expertNickName}</span>的包月VIP私推服务于<span class="red">${expireTime}</span>到期，请尽快续订，以免影响使用。</div>
                                </div>
                                <a class="btn alert-submit">立即续订</a>
                                <a class="btn no_bg alert-close">我知道了</a>
                            </div>
                        </div>`;
        return this.alertService.show(content,20).then((data)=>{
            if(data.type=='submit'){
                this.navCtrl.push("ExpertPushOrderPage",{expertId:alertRss.expertMemberId});
            }
            return true;
        })
    }

    private checkExpireListAlertCache(option:'three'|'seven',baoyueOrderList:BaoyueMemberRss[]):string{
        let map:{[key:string]:string[]} = this.storage.getObject("baoyue_expire_alert_map");
        if(!map){
            map = {'three':[],'seven':[]};
        }
        let alertedList:string[] = [...map[option]];
        let needAlertList:string[] = baoyueOrderList.filter((item:BaoyueMemberRss)=>{
            return alertedList.indexOf(item.rssId)==-1;
        }).map((c:BaoyueMemberRss)=>{
            return c.rssId;
        });
        if(needAlertList&&needAlertList.length>0){
            map[option].push(needAlertList[0]);
            this.storage.setObject("baoyue_expire_alert_map",map);
            return needAlertList[0];
        }
        return null;
    }

    //判断是不是今天
    private isToday(time) {
        return new Date(time).toDateString() == new Date().toDateString();
    }*/


}
