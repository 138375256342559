import {Injectable} from '@angular/core';
import {Subject} from "rxjs/Subject";
import {SettingService} from "../match-list/setting/setting.service";
import {AppHttp} from "../../../../services/app-http.service";
import {AlertService} from "../../../../services/alert.service";
import {SportdataHttp} from "../../../../services/sportdata-http.service";
import {NavCtrlService} from "../../../../services/nav-controller.service";
import {AppConfig} from "../../../../services/app-config.service";
import {FOOTBALL_STATUS_DESC, RACE_STSTUS, RaceType} from "../../../../model/const-config.model";

@Injectable()
export class MatchDetailService {

  socket:any;   //聊天室连接对象

  isPlaying=false;//是否正在播放视频或者动画
	public match: any = {};			//存放比赛详情对象

  public tabCache:any = {};   //详情页tab缓存
  public childTabCache:any = {};//详情页子tab
  public childTab:any = null;//当前选中的详情页子页面

  //获取缓存
  getChildTabCache(fxId) {
    if(!this.childTabCache[fxId])
      this.childTabCache[fxId] = {};
    return this.childTabCache[fxId];
  }

	public subject = new Subject();			//用于观察滚动事件

	public matchStatus = FOOTBALL_STATUS_DESC;

	constructor(public settingService: SettingService,public appHttp: AppHttp,
				public dataHttp: SportdataHttp,public alert:AlertService,public appConfig:AppConfig) {

	}

	//关闭聊天室
	public closeSocket() {
	  if(this.socket) {
	    console.log("关闭聊天室");
	    try {
            this.socket.close();
            this.socket = null;
        }catch (e){
	      console.log(e);
        }
      }
    }


  //比赛中
  matching(status,raceType = RaceType.JJ_ZC.index) {
    return RACE_STSTUS.matching(status, raceType);
  }
  //比赛取消或延迟
  matchCancel(status,raceType = RaceType.JJ_ZC.index) {
    return RACE_STSTUS.matchCancel(status, raceType);
  }

  //根据赛事拿解读数据
  queryTjByMatchIdAndStatus(matchId, status, matchType = 1,pageSize?) {
    // return this.AppHttp.get("/api/matchcenter/queryTjByMatchIdAndStatus", {
    return this.appHttp.get("/api/matchcenter/queryJdByMatchId", {  //5.3.0新接口
      params: {
        matchId,
        //raceStatus: status,
        pageSize,
        matchType
      }
    });
  }

  //根据赛事拿推荐数据
  queryPlanByMatchIdNew(matchId, pageNo, raceStatus, matchType, sortType) {
    // return this.appHttp.get('/api/matchcenter/queryPlanByMatchIdNew', {
    return this.appHttp.get('/api/matchcenter/queryPlanAndJdByMatchId', {		//5.3.0新接口
      params: {
        matchId: matchId,
        pageNo: pageNo,
        //raceStatus: raceStatus,
        matchType: matchType,
        sortType: sortType
      }
    });
  }

  // 查询解读命中数
  queryCountInterpretationByFxId(matchId, matchType) {
    return this.appHttp.get('/api/matchcenter/queryCountInterpretationByFxId', {params: {matchId: matchId, matchType: matchType,}});
  }

  //关注比赛
  followMatch (matchId,type,matchType){
    return this.appHttp.get('/api/user/followMatch',{params:{matchId:matchId,type:type,matchType:matchType}});
  }
  //根据赛事id或球探id拿赛事详情
  queryMatchInfoById(matchId,qtMatchId?) {

    if(matchId && this.tabCache[matchId]) {

     let data = this.tabCache[matchId].match;

     if(data) {
       return new Promise((rs,rj)=>{

         // console.log("~~~~~~来自缓存~~~~~");

         rs(data);
       });
     }else {
       return this.dataHttp.post({apiName: 'getMatchInfoById', leagueMatchId: matchId,qtMatchId});
     }

    }else {
      return this.dataHttp.post({apiName: 'getMatchInfoById', leagueMatchId: matchId,qtMatchId});
    }
  }

  //根据比赛id列表拿比赛及时信息
  queryTimelyMatchInfo(idList: any[]) {
    return this.dataHttp.post({apiName: 'getMatchListLiveScore', matchIdList: idList});
  }

  //根据比赛id列表拿进球等事件
  queryGaolEvent(idList: any[]){
    return this.dataHttp.post({apiName: 'getMatchEventByMatchIdList', matchIdList: idList});
  }

  //拿赔率数据
  queryOdds(type,matchId,company,pageNo=1,pageSize=10) {
    if(type==2)
      return this.queryStandardYpOdds(matchId,pageNo,pageSize);
    if(type==3)
      return this.queryStandardDxqOdds(matchId,pageNo,pageSize);
    return this.queryStandardEuOdds(matchId,company,pageNo,pageSize);
  }

  //拿赔率详情数据
  getOddDetail(oddId,matchId,type=1,pageNo,pageSize) {
    if(type == 2)
      return this.queryStandardYpOddDetail(matchId,oddId,pageNo,pageSize);
    if(type == 3)
      return this.queryStandardDxqOddDetail(matchId,oddId,pageNo,pageSize);
    return this.queryStandardEuOddDetail(matchId,oddId,pageNo,pageSize);
  }
  //拿篮球赔率详情数据
  getBkOddDetail(oddId,matchId,type=1,pageNo,pageSize) {
    if(type == 2)
      return this.getBkLetMatchOddsChangeDetail(matchId,oddId,pageNo,pageSize);
    if(type == 3)
      return this.getBkMatchBigsmallOddsChangeDetail(matchId,oddId,pageNo,pageSize);
    return this.getBkMatchEuropeOddsChangeDetail(matchId,oddId,pageNo,pageSize);
  }
  //篮球让分赔率详情
  getBkLetMatchOddsChangeDetail(matchId,oddsId,pageNo,pageSize){
    return this.dataHttp.post({apiName: 'getBkLetMatchOddsChangeDetail', matchId,oddsId,pageNo,pageSize});
  }
  //篮球大小赔率详情
  getBkMatchBigsmallOddsChangeDetail(matchId,oddsId,pageNo,pageSize){
    return this.dataHttp.post({apiName: 'getBkMatchBigsmallOddsChangeDetail', matchId,oddsId,pageNo,pageSize});
  }
  //篮球胜负赔率详情
  getBkMatchEuropeOddsChangeDetail(matchId,oddsId,pageNo,pageSize){
    return this.dataHttp.post({apiName: 'getBkMatchEuropeOddsChangeDetail', matchId,oddsId,pageNo,pageSize});
  }

  //根据比赛id和公司信息 取欧赔标准赔率
  queryStandardEuOdds(matchId,company,pageNo=1,pageSize=10) {
    return this.dataHttp.post({apiName: 'getOneMatchEuropeOdds', matchId,isPrimary:company,pageNo,pageSize});
  }
  //根据比赛id和公司信息 取亚盘赔率
  queryStandardYpOdds(matchId,pageNo=1,pageSize=10) {
    return this.dataHttp.post({apiName: 'getOneMatchAsiaPrimaryOdds', matchId,pageNo,pageSize});
  }
  //根据比赛id和公司信息 取大小球赔率
  queryStandardDxqOdds(matchId,pageNo=1,pageSize=10) {
    return this.dataHttp.post({apiName: 'getOneMatchBigSmallOdds', matchId,pageNo,pageSize});
  }

  //根据比赛id和type 取所有公司
  queryAllCompany(matchId,oddsType) {
    return this.dataHttp.post({apiName: 'getOddsProviderByMatch', matchId,oddsType});
  }


  //根据比赛id 取欧赔标准赔率升降详情
  queryStandardEuOddDetail(matchId,oddsId,pageNo,pageSize) {
    return this.dataHttp.post({apiName: 'getOneEuropeOddsDetail', matchId,oddsId,pageNo,pageSize});
  }
  //根据比赛id 取亚盘赔率升降详情
  queryStandardYpOddDetail(matchId,oddsId,pageNo,pageSize) {
    return this.dataHttp.post({apiName: 'getOneAsiaDetailOdds', matchId,oddsId,pageNo,pageSize});
  }
  //根据比赛id 取大小球赔率升降详情
  queryStandardDxqOddDetail(matchId,oddsId,pageNo,pageSize) {
    return this.dataHttp.post({apiName: 'getOneBigSmallDetailOdds', matchId,oddsId,pageNo,pageSize});
  }

  //根据比赛id和若干筛选条件 取交锋战绩信息
  queryTeamFightInfo(matchId,number=10,isHomeAway?,leagueId?) {
    return this.dataHttp.post({apiName: 'getTeamBoutExploits', matchId,number,isHomeAway,leagueId});
  }

  //根据比赛id和若干筛选条件 取比赛球队近期状态信息
  queryTeamNearStatus(matchId,number=10,isHomeAway?,leagueId?) {
    return this.dataHttp.post({apiName: 'getTeamNearStatus', matchId,number,isHomeAway,leagueId});
  }

  //根据比赛id 取比赛文字直播
  queryMatchTextLive(matchId) {
    return this.dataHttp.post({apiName: 'getMatchTextLive', matchId});
  }
  //根据比赛id 取比赛事件
  queryMatchEvent(matchId) {
    return this.dataHttp.post({apiName: 'getMatchEvent', matchId});
  }
  //根据比赛id 取比赛事件
  queryMatchEventBatch(matchIdList,second) {
    return this.dataHttp.post({apiName: 'getMatchEventByMatchIdList', matchIdList,second});
  }
  //根据比赛id 取比赛技术统计
  queryMatchStanging(matchId) {
    return this.dataHttp.post({apiName: 'getMatchStanding', matchId});
  }
  //根据比赛id 取比赛阵容数据
  queryMatchLineup(leagueMatchId) {
    return this.dataHttp.post({apiName: 'getMatchLineup', leagueMatchId});
  }
  //根据比赛id 拿积分排行数据
  queryMatchPointRank(leagueMatchId,hostGuest=null) {
    return this.dataHttp.post({apiName: 'getMatchPointRank', leagueMatchId,hostGuest});
  }

  getMatchPointRankNew(leagueMatchId){
    return this.dataHttp.post({apiName: 'getMatchPointRankNew', leagueMatchId});
  }

  //根据赛事id 取未来赛程数据
  queryTeamScheduleList(matchId) {
    return this.dataHttp.post({apiName: 'getMatchScheduleList',matchId});
  }

  //根据球队id 取技术统计数据
  queryTeamStanding(teamId,matchCount=10,locationType?,leagueType?) {
    return this.dataHttp.post({apiName: 'getTeamStanding', teamId,matchCount,locationType,leagueType});
  }

  //足球列表   设置赔率公司
  getMatchListByDate(simple,date:string,game:number,pageNo = 1,pageSize = 20,leagueIdList?,asiaList?,bigsmallList?,ids?,status?){
    let oddsCompany = this.settingService.setting.settingJson.settingByTab[game==-1?'0':game+''].provider.oddsCompany;
    if(game!=407&&!this.settingService.setting.settingJson.changed){
      oddsCompany = null;
    }
    return this.dataHttp.post({matchStatus:status,simple:simple,qtMatchIdList:ids, oddsCompany:oddsCompany,
      apiName: 'getMatchListByDate', date,game,pageNo,pageSize,leagueIdList,asiaList,bigsmallList});
  }

  getMatchAnalysisAbility(leagueMatchId){
    return this.dataHttp.post({apiName:'getMatchAnalysisAbility',leagueMatchId});
  }

  getMatchAnalysisNumber(isSameHomeaway,isSameLeague,leagueMatchId,pageSize?){
    pageSize = pageSize||10;
    return this.dataHttp.post({apiName:'getMatchAnalysisNumber',isSameHomeaway,isSameLeague,leagueMatchId,number:pageSize})
  }
  //投注比例弹层
  getBetPercent(leagueMatchId){
    return this.dataHttp.post({apiName:'getBetPercent',leagueMatchIds:[leagueMatchId]});
  }
  //离散弹层
  getFtDiscreteIndex(leagueMatchId){
    return this.dataHttp.post({apiName:'getFtDiscreteIndex',matchIds:[leagueMatchId]})
  }
  /*赛事提点信息*/
  getMatchTidianList(matchId,typeList){
    return this.dataHttp.post({apiName: 'getMatchTidianList', matchId,typeList});
  }
  /*赛事情报信息*/ // return this.dataHttp.post({apiName: 'getMatchInfomationList', matchId});
  getMatchInfomationList(matchId, matchStatus) {
    return this.dataHttp.post({apiName: 'getMatchInfomationApi', matchId});
    //return this.appHttp.get('/api/matchcenter/queryIntelligence', {params: {matchId, qiuFlag: 1, matchStatus}});
  }
  //根据id查询列表
  getMatchListById(qtMatchIdList){
    return this.dataHttp.post({apiName: 'getMatchListById', qtMatchIdList});
  }

  //是否关注和 解读数量
  queryFollowStatusAndTjNumber(ids,matchType){
    return this.appHttp.post('/api/matchcenter/queryFollowStatusAndTjNumber',{ids,matchType});
  }
  //关注列表
  queryUserFollowMatchIds(pn,matchType,ps=300){
    return this.appHttp.get('/api/user/queryUserFollowMatchIds',{params:{pn,ps:300,matchType}});
  }
    //关注列表
    queryUserFollowMatchIdsByMatchList(pn,matchType,ps=300){
        return this.appHttp.get('/api/match/queryUserFollowMatchIds',{params:{pn,ps:300,matchType}});
    }

	//查询凯利数据
	queryKLdata(nav:NavCtrlService,matchId,page,params){
		let that = this;
		this.dataHttp.post({apiName:'getkellyDetail',leagueMatchId:matchId}).then(
			(data:any)=>{
				if(data&&data.code==1) {
					//this.leagueMatch = data.leagueMatch;
					let matchKellyVOs = data.matchKellyVOs;
					if (matchKellyVOs && matchKellyVOs.length > 0) {
						nav.push(page,params)

            // nav.navigate([[page],params]);
						return ;
					}
				}
				that.alert.msg("暂无数据");
				return ;
			}).catch((data)=>{
				that.alert.msg(data.errorMsg);
		})
	}
	//查询必发数据
	queryBFdata(nav:NavCtrlService,matchId,page,params){
		let that = this;
		this.appHttp.get('/api/matchcenter/queryBFMatch',{params:{matchId:matchId}}).then(
			(data:any)=>{
				if(data.bfMatch.homeAmount>0&&data.bfMatch.drawAmount>0&&data.bfMatch.awayAmount>0){
					nav.push(page,params);

					//nav.navigate([page,params]);

					return;
				}else{
					that.alert.msg('暂无数据');
					return;
				}
			}
		).catch(
			(error:any) =>{
				this.alert.msg(error.errorMsg);
			}
		)

  }
  getFirstOdd(type,matchId,providerId){
    let apiName=null;
    if(type==1){
      apiName='getEuropeSameOddsDetail';
    }
    if(type==2){
      apiName='getAsiaSameOddsDetail';
    }
    if(type==3){
      apiName='getBigsmallSameOddsDetail';
    }
    return  this.dataHttp.post({num:100,matchId :matchId,leagueType:2,providerId:providerId,type:1,apiName:apiName});
  }
  getFirstOddAll(type,matchId,providerId){
    let apiName=null;
    if(type==1){
      apiName='getEuropeSameOddsDetail';
    }
    if(type==2){
      apiName='getAsiaSameOddsDetail';
    }
    if(type==3){
      apiName='getBigsmallSameOddsDetail';
    }
    return  this.dataHttp.post({num:100,matchId :matchId,leagueType:3,providerId:providerId,type:1,apiName:apiName});
  }
  getInTimeOdd(type,matchId,providerId){
    let apiName=null;
    if(type==1){
      apiName='getEuropeSameOddsDetail';
    }
    if(type==2){
      apiName='getAsiaSameOddsDetail';
    }
    if(type==3){
      apiName='getBigsmallSameOddsDetail';
    }
    return  this.dataHttp.post({num:100,matchId :matchId,leagueType:2,providerId:providerId,type:2,apiName:apiName});
  }
  getInTimeOddAll(type,matchId,providerId){
    let apiName=null;
    if(type==1){
      apiName='getEuropeSameOddsDetail';
    }
    if(type==2){
      apiName='getAsiaSameOddsDetail';
    }
    if(type==3){
      apiName='getBigsmallSameOddsDetail';
    }
    return  this.dataHttp.post({num:100,matchId :matchId,leagueType:3,providerId:providerId,type:2,apiName:apiName});
  }

  //篮球比赛即时比分
  refreshBkMatches(ids){
    return  this.dataHttp.post({matchIdList:ids,apiName:'getBkMatchListLiveScore'});
  }

  //足球是视频 动画
  getVideoAndCartoonInfo(matchIds){
    return  this.dataHttp.post({matchIds:matchIds,apiName:'getFtLeisuMatchVideo'});
  }
  //篮球视频 动画
  getBkVideoAndCartoonInfo(matchIds){
    return  this.dataHttp.post({matchIds:matchIds,apiName:'getBkLeisuMatchVideo'});
  }

  isWifi(){
    if(sessionStorage.getItem('hasShowConnection')){
      return true;
    }
    let wifi = true;
    let ua = window.navigator.userAgent;
    let con = (<any>(window.navigator)).connection;
    // 如果是微信
    if(/MicroMessenger/.test(ua)){
      // 如果是微信6.0以上版本，用UA来判断
      if(/NetType/.test(ua)){
        if(ua.match(/NetType\/(\S*)$/)[1] != 'WIFI'){
          wifi = false;
        }
        // 如果是微信6.0以下版本，调用微信私有接口WeixinJSBridge
      }else{
        let WeixinJSBridge =(<any>window).WeixinJSBridge;
        document.addEventListener("WeixinJSBridgeReady",function onBridgeReady(){
          WeixinJSBridge.invoke('getNetworkType',{},function(e){
            if(e.err_msg != "network_type:wifi"){
              wifi = false;
            }
          });
        });
      }
      // 如果支持navigator.connection
    }else if(con){
      let network = con.type;
      if(network != "wifi" && network != "2" && network != "unknown"){  // unknown是为了兼容Chrome Canary
        wifi = false;
      }
    }
    return wifi;
  }

  isMobile(){
    return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  }

  hideVideo(){
    // if(this.appConfig.isJD||this.appConfig.isWB||this.appConfig.isWechat||(location.href.indexOf("https://")!=-1&&this.appConfig.isSafari)){
    if(this.appConfig.isWB){
      return true;
    }
    return false;
  }

  //获取聊天室信息
  getChatroomInfo(roomType,roomTypeId,needNearMsgList){
    return  this.dataHttp.post(
      {roomType:roomType,
        roomTypeId:roomTypeId,
        needNearMsgList:needNearMsgList,
        apiName:'getChatroomInfoApi'});
  }
  //4.查询用户是否禁言和敏感字
  getChatroomUserContent(userId,ct){
    return  this.dataHttp.post(
      {userId:userId,
        ct:ct,
        apiName:'getChatroomUserContentApi'});
  }
  //关注比赛
  updateFollow(match, raceType) {
    let type = match.isFollow ? 2 : 1;
    this.followMatch(match.qtMatchId, type, raceType).then((data: any) => {
      if (data.isSuccess) {
        if (type == 2) {
          match.isFollow = false;
          (<any>window).Jcob.removeFollowId(match.matchId, 1);
        } else {
          match.isFollow = true;
          (<any>window).Jcob.addFollowId(match.matchId, 1);
        }

      } else {
        this.alert.msg(data.errorCode, 1000);
      }
    }, () => {

    });
  }
  //查询相关赛事的解读，小店合作用
  queryPlanByXdMatchId(issueNo,matchNo,raceType){
    return this.appHttp.get("/api/xd/getPlanByMatchId",{params:{issueNo,matchNo,raceType}});
  }
}
