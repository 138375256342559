import {NgModule} from '@angular/core';
import {ConfirmPage} from "./confirm";
import {AlertPage} from "./alert";

@NgModule({
    declarations: [
        ConfirmPage,
        AlertPage,
    ],
    entryComponents: [
        ConfirmPage,
        AlertPage,
    ],
})
export class AlertModule {
}
