import { Injectable } from '@angular/core';
import {ClientPlatform} from "./client-platform.service";
// import {JsBridgeService} from "./jsBridge.service";
import {Router} from "@angular/router";
import {getDefaultAgentId} from '../services/download-util';

@Injectable()
export class AppConfig {
    apiHost:string;
    agentId:number;
    jcobSessionId:string;//会话id,用于大数据统计时使用
    // version:string;
    platform:string;
    appVersion:string ="7.3.0"; //盈球是用appVersion
    fromPlatform:string = "wap";
    // isWB:boolean=false;
    share;      //是否来自分享链接，值为分享的用户id

    domain:string = window.location.protocol+'//'+window.location.hostname;     //站点域名

    //亚盘
    asiaDesc:string='让球';

    //本地系统和服务器系统时间偏移
    sysTimeExc = 0;

    constructor(
      public platformService: ClientPlatform,
      // public jsBridgeService: JsBridgeService,
      public router: Router,
    ) {
      let config:any = (<any>window).AppConfig;
      this.platform = "wap";
      // this.version = "6.9.0";
      this.apiHost = config.apiHost;
      this.agentId = config.agentId;

      this.initPlatform();
      // console.log((this.fromPlatform));
    }

    //初始化平台
    initPlatform() {
        this.fromPlatform = this.platformService.clientType;
        // this.fromPlatform = 'ttHw';
        //this.fromPlatform = 'vivoydzx';
    }

    reinitPlatformWithAgentId(){
        if(this.agentId==2335615){//中国足球信息网
            this.fromPlatform = 'cnball';
        }
    }

    get showYqUserInfo():boolean {
        return !this.isJD && !this.isCNB;
    }

    get showDonwload():boolean{
        return !this.isHiddenLogin()&&!this.isJD&&!this.isCNB&&!this.isWB&&!this.isTq;
    }

    get showSetMeal():boolean {
        return !this.isJD&&!this.isTq;
    }

    get showProxy():boolean {
        return !this.isCNB;
    }

    get showBk():boolean{
        return !this.isCNB;
    }

    get showBaoyue():boolean{
        return !this.isCNB && !this.isCNB && !this.isTq;
    }

    get showVip():boolean {
        return !this.isJD && !this.isCNB && !this.isTq && !this.isWechat;
    }

    get showRedPack():boolean{
        return !this.isJD && !this.isCNB && !this.isTq&&!this.isHiddenLogin();
    }

    get showCoupon():boolean {
        //非主站/微博/微信没有优惠券
        return false;//this.isJcob || this.isWechat || this.isWB;
    }

    get showSns():boolean{
        return !this.isCNB && !this.isTq && !this.isJD;
    }

    get showJinbi():boolean{
        return !this.isJD;
    }

    get showPlan():boolean{
        return !this.isCNB;
    }
    get showBackHeader():boolean{
        return this.isFastYQ;
    }

    //是否是safari浏览器
    get isSafari():boolean{
      return this.platformService.platform == "safari";
    }

    //是否来自微博
    get isWB():boolean {
      return this.fromPlatform == 'weibo';
      //return true;
    }

    //是否来自微信
    get isWechat():boolean {
     return this.fromPlatform == 'wechat';
    }
    //是否来自盈球 默认是盈球
    get isJcob():boolean {
      return this.fromPlatform == 'jcob';
    }
    //是否来京东
    get isJD():boolean {
      return this.fromPlatform == 'jingdong';
    }
    //推球平台
    get isTq():boolean{
      return this.fromPlatform == 'tq';
    }
    //体坛华为平台
    get isTtHw():boolean{
        return this.fromPlatform == 'ttHw';
    }
    //体坛华为平台
    get isVivoYdzx():boolean{
        return this.fromPlatform == 'vivoydzx';
    }
    //qq
    get isQQ():boolean{
      return this.fromPlatform == 'qq'|| this.fromPlatform =='qqbrowser';
    }
    //中国足球信息网
    get isCNB():boolean{
        return this.fromPlatform == 'cnball';
    }

    get isHd():boolean{//活动跳转
        if(location.href.indexOf("hd20")!=-1){
            return true;
        }
        return false;
    }
    //极速版盈球
    get isFastYQ():boolean{
        return this.agentId==2335620;
    }
    //小店合作
    get isXd(){
        return this.fromPlatform == "xiaodian";
    }
    //判断是否推球屏蔽的用户
    isShieldUsers(userId):boolean{

      return this.isTq && (userId == 2689654 || userId == 2689666);
    }
    //h5启动弹窗 默认 本站、微博、微信(另做处理)
    get isPopup(): boolean{
        return this.isWB || this.isJcob;
    }

    // don't fixme:h5差异代码
    /*
     * 判断是否为用户登录相关业务受限的渠道
     * 123456、234567：159
     * 2335621：淘彩
     */
    isHiddenLogin(){
      // let agentIds = ['123456','234567','2335621'];
      // return agentIds.indexOf(this.agentId.toString())!=-1;
      return false;
    }

    isShowDownloadHead(){
      if(this.agentId==2335620){
        return false;
      }
      return true;
    }

    //货币
    get currency() {
      if(this.isJD||this.isTq||this.isXd)
        return "元";
      return "金币";
    }

    //是否在微信内部浏览器
    get isWeiXin() {
        return navigator.userAgent.indexOf('MicroMessenger') == -1?false:true;
    }

    //合作站不提示绑定微信(包括微信内部)，走原先流程
    get noShowBindWx():boolean{
        return this.isWB || this.isTq || this.isJD || this.isCNB || this.isWechat;
    }
    get noNeedBindWx():boolean{
        return this.isWB || this.isTq || this.isJD || this.isCNB;
    }

  /**
   * 跳转下载页自动打开app
   * @param word 是否需要自动下载页打开app
   */
  public gotoDownload(){
      let agentId = this.agentId;
        // let url = '';
        // if (word){
        //   url = "/page/download/h5_ttyq.html?autoJump=1&agentId=";
        // }else {
        //   url = "/page/download/h5_ttyq.html?agentId=";
        // }
        //let url = "http://m.ttyingqiu.com/page/download/h5_ttyq.html?autoJump=1&agentId=";
        if(this.isWechat) {
            agentId = 2335094;
            this.router.navigate(['/download', {agentId: agentId}]);
        }else{
            getDefaultAgentId().then((ag)=>{

                agentId = ag || 2335037;
                this.router.navigate(['/download',{agentId:agentId}]);
            }).catch(()=>{
                agentId = 2335037;
                this.router.navigate(['/download',{agentId:agentId}]);
            })
        }
        // if(this.agentId==2335620){
        // this.router.navigate(['/download',{agentId:agentId}]);
        //   return;
        // }
        // location.href = url;
    }

    public isIos() {
      return this.platformService.isIOS;
    }

    //是否是精简版
    public simple = false;

}
