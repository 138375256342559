import { Injectable } from '@angular/core';

const canWriteToSessionStorage = () => {
  try {
    self.sessionStorage.setItem('_canWriteToLocalStorage', "1");
    self.sessionStorage.removeItem('_canWriteToLocalStorage');
    return true
  } catch (e) {
    return false
  }
};

/**
 * session存储域 服务
 */

@Injectable()
export class SessionStorageService {

  private session:any;

  constructor() {

    //判断如果浏览器不支持sessionStorage 则直接存储到内存
    if (typeof window !== 'undefined' && 'sessionStorage' in window && canWriteToSessionStorage()) {
      this.session = self.sessionStorage;
    }else{
      this.session = {};
    }
  }

  /**
   * 存储字符串数据
   * @param {string} key
   * @param {string} value
   */
  public set(key:string, value:string):void {
    this.session[key] = value;
  }

  /**
   * 获取字符串数据
   * @param {string} key
   * @returns {string}
   */
  public get(key:string):string {
    return this.session[key] || false;
  }

  /**
   * 存储对象数据
   * @param {string} key
   * @param value
   */
  public setObject(key:string, value:any):void {
    this.session[key] = JSON.stringify(value);
  }

  /**
   * 获取对象数据
   * @param {string} key
   * @returns {any}
   */
  public getObject<T>(key:string): any {
    let value: string = this.session[key];

    if (value && value != "undefined" && value != "null") {
      return <T>JSON.parse(value);
    }

    return null;
  }

  /**
   * 删除相应的key
   * @param {string} key
   * @returns {any}
   */
  public remove(key:string):any {
    delete this.session[key];
  }

  // clear() { //一般不需要全清
  //     this.session.clear();
  // }
}
