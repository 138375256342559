import {Injectable} from '@angular/core';
import {SportdataHttp} from '../../../../services/sportdata-http.service';
import {AlertService} from '../../../../services/alert.service';
import {AppHttp} from '../../../../services/app-http.service';


@Injectable()
export class BkMatchDetailService {

    public tabCache: any = {};   // 详情页tab缓存
    public childTabCache: any = {}; // 详情页子tab

    // 获取缓存
    getChildTabCache(fxId) {
        if (!this.childTabCache[fxId]) {
            this.childTabCache[fxId] = {};
        }
        return this.childTabCache[fxId];
    }

    constructor(
        public dataHttp: SportdataHttp,
        public appHttp: AppHttp,
        public alert: AlertService) {

    }

    /* // 获取比赛对阵详情
     getBkMatchDetail(matchId?,qtMatchId?){
         return this.dataHttp.post({
             matchId:matchId,
             qtMatchId:qtMatchId,
             apiName:'getBkMatchDetail'
         });
     }*/

    // 根据赛事id或球探id拿赛事详情
    getBkMatchDetail(matchId, qtMatchId?) {

        if (matchId && this.tabCache[matchId]) {

            let data = this.tabCache[matchId].match;

            if (data) {
                return new Promise((rs, rj) => {

                    // console.log("~~~~~~来自缓存~~~~~");

                    rs(data);
                });
            } else {
                return this.dataHttp.post({apiName: 'getBkMatchDetail', matchId, qtMatchId});
            }

        } else {
            return this.dataHttp.post({apiName: 'getBkMatchDetail', matchId, qtMatchId});
        }
    }

    // 获取提点
    getKeyPoint(matchId, typeList) {
        return this.dataHttp.post({
            matchId: matchId,
            apiName: 'getBkMatchTidianList'
        });
    }

    // 获取球队排名信息
    getRankData(matchId, homeAway) {
        return this.dataHttp.post({
            matchId: matchId,
            homeAway: homeAway,
            apiName: 'getBkMatchTeamRankAndCount'
        });
    }

    // 获取交锋战绩
    getBkTeamBoutMatch(matchId, sameHomeAway, sameLeague, partFlag, pageSize) {
        return this.dataHttp.post({
            matchId: matchId,
            sameHomeAway: sameHomeAway,
            sameLeague: sameLeague,
            partFlag: partFlag,
            pageSize: pageSize,
            apiName: 'getBkTeamBoutMatch'
        });
    }

    // 获取近期状态
    getBkTeamRecentMatch(matchId, sameHomeAway, sameLeague, partFlag, pageSize) {
        return this.dataHttp.post({
            matchId: matchId,
            sameHomeAway: sameHomeAway,
            sameLeague: sameLeague,
            partFlag: partFlag,
            pageSize: pageSize,
            apiName: 'getBkTeamRecentMatch'
        });
    }

    // 获取未来赛程
    getBkTeamFutureMatch(matchId, sameHomeAway, sameLeague, partFlag, pageSize) {
        return this.dataHttp.post({
            matchId: matchId,
            sameHomeAway: sameHomeAway,
            sameLeague: sameLeague,
            partFlag: partFlag,
            pageSize: pageSize,
            apiName: 'getBkTeamFutureMatch'
        });
    }

    // 获取技术面技术统计
    getBkMatchAnalyzeStandingData(matchId, stageName, homeAway, isPart, number) {
        return this.dataHttp.post({
            matchId: matchId,
            stageName: stageName,
            homeAway: homeAway,
            isPart: isPart,
            number: number,
            apiName: 'getBkMatchAnalyzeStandingData'
        });
    }

    // 获取比赛阵容
    getBkMatchLineup(matchId) {
        return this.dataHttp.post({
            matchId: matchId,
            apiName: 'getBkMatchLineup'
        });
    }

    // 获取伤停信息
    getBkMatchInjury(matchId) {
        return this.dataHttp.post({
            matchId: matchId,
            apiName: 'getBkMatchInjury'
        });
    }

    // 获取赛事情报信息
    getBkMatchInfomationList(matchId) {
        return this.dataHttp.post({
            matchId: matchId,
            apiName: 'getBkMatchInfomationList'
        });
    }

    getMatchInfomationList(matchId, matchStatus) {
        return this.dataHttp.post({apiName: 'getBkMatchInfomationApi', matchId});
        // return this.appHttp.get('/api/matchcenter/queryIntelligence', {params: {matchId, qiuFlag: 2, matchStatus}});
    }

    // 获取让分赔率
    getBkLetMatchOddds(matchId) {
        return this.dataHttp.post({
            matchId: matchId,
            pageNo: 1,
            pageSize: 100,
            apiName: 'getBkLetMatchOddds'
        });
    }

    // 获取大小赔率
    getBkMatchBigSmallOdds(matchId) {
        return this.dataHttp.post({
            matchId: matchId,
            pageNo: 1,
            pageSize: 100,
            apiName: 'getBkMatchBigSmallOdds'
        });
    }

    // 获取胜负赔率
    getBkMatchEuropeOdds(matchId) {
        return this.dataHttp.post({
            matchId: matchId,
            pageNo: 1,
            pageSize: 100,
            apiName: 'getBkMatchEuropeOdds'
        });
    }

    // 获取直播技术统计
    getBkMatchStanding(matchId) {
        return this.dataHttp.post({
            matchId: matchId,
            apiName: 'getBkMatchStanding'
        });
    }

    // 获取直播文字
    getBkMatchTextLive(matchId) {
        return this.dataHttp.post({
            matchId: matchId,
            apiName: 'getBkMatchTextLive'
        });
    }

    // 处理背靠背
    dealBackToBack(matchVos, match) {
        if (match.leagueName == 'NBA' || match.leagueName == 'WNBA') {
            // 先判断有没有三场背靠背的
            if (matchVos && matchVos.length > 2) {
                for (let i = 0; i < matchVos.length - 2; i++) {
                    if (new Date(matchVos[i].matchDate).getTime() - new Date(matchVos[i + 2].matchDate).getTime() == 48 * 60 * 60 * 1000) {
                        matchVos[i].backToBackThree = true;
                        matchVos[i].backToBackFlag = true;
                        matchVos[i + 1].backToBackFlag = true;
                        matchVos[i + 2].backToBackFlag = true;
                    }
                }
            }
            // 再判断两场的
            if (matchVos && matchVos.length > 1) {
                for (let i = 0; i < matchVos.length - 1; i++) {
                    if ((!matchVos[i].backToBackFlag) && new Date(matchVos[i].matchDate).getTime() - new Date(matchVos[i + 1].matchDate).getTime() == 24 * 60 * 60 * 1000) {
                        matchVos[i].backToBackTwo = true;
                    }
                }
            }
        }
    }
}
