import {Injectable} from '@angular/core';
import {AppConfig} from './app-config.service';

@Injectable()
export class Browser{

    constructor(private appConfig:AppConfig) {
    }

    openUrl(url:string){
      top.open(url);
    }

}
