import {Injectable} from '@angular/core';
import {AppHttp} from "./app-http.service";
import {AuthCodeCenter, CodeParams, SMS_AUTH_TYPE} from "../model/AuthCodeCenter.model";
import {CaptchaValidateService} from "../component/captcha/captcha-validate.service";

/**
 * 短信验证码发送中心
 * 所有短信验证码都需通过这里发送
 *
 * 使用方法：
 * 发送验证码时通过 this.sendAuthCode()发送，如：sendRegisterAutoCode()
 * 验证码倒计时可以使用 getAuthCodeCenter() 获取发送中心，leftTime 为倒计时
 * 或者发送成功后，当前发送送中心会放到 返回值的 res.codeCenter 属性中
 */

@Injectable()
export class PhoneMessageCenterService {

	//调度中心，用来存放发送时间
	private controlCenter = new Map<number,AuthCodeCenter>();

	constructor(
		private appHttp: AppHttp,
		private captchaService: CaptchaValidateService,
	) {

    }
    /**
	 * 获取对应类型验证码中心
     * @param {number} authType 发送类型 @link SMS_AUTH_TYPE
     */
    public getAuthCodeCenter(authType:number):AuthCodeCenter {

    	let codeCenter = this.controlCenter.get(authType);

    	if(!codeCenter) {
            codeCenter = new AuthCodeCenter(this.appHttp,authType);
            this.controlCenter.set(authType,codeCenter);
		}
		return codeCenter;
	}

    /**
	 * 使用对应发送中心发送验证码
     * @param {number} authType @link SMS_AUTH_TYPE
     * @param {string} api 发送验证码的api
     * @param params 携带参数
     */
	public sendAuthCode(authType:number, api:string, params:CodeParams, options?):Promise<any> {

		//校验手机号
		if(!this.checkPhone(params.phone)
				&& authType != SMS_AUTH_TYPE.PHONE_VERIFY	//用户中心校验手机号，可以不传手机号
				&& authType != SMS_AUTH_TYPE.RETRIEVE_PWD	//找回密码，根据找账号发送验证码，传的是账号
		) {
            return Promise.reject({msg:"手机号格式错误",errorCode:'phone_error'});
		}

		return this.captchaService.checkWithCaptcha().then(()=>{
            return this.getAuthCodeCenter(authType).sendAuthCode(api, params);
		},()=>{
            return Promise.reject({msg:"滑块校验失败!",errorCode:'check_error'});
		});

	}

    /**
	 * 检查手机号是否合法
     * @param phone
     * @return {any | boolean}
     */
	public checkPhone(phone) {
		return phone && ((<any>window).userValidate && (<any>window).userValidate.memValidate.isMobile(phone).result == 'success');
	}

    /**
	 * 发送注册短信验证码
     * @param {number} phone
     */
	public sendRegisterAutoCode(phone:string) {
		return this.sendAuthCode(SMS_AUTH_TYPE.USER_REGISTER,'/api/authCode/register/send',{phone})
	}

    /**
     * 手机号绑定\修改
     * @param {number} phone
     */
    public sendPhoneAutoCode(phone:string) {
        return this.sendAuthCode(SMS_AUTH_TYPE.PHONE_VERIFY,'/api/authCode/bind/send',{phone})
    }

    /**
     * 发送登录短信验证码
     * @param {number} phone
     */
    public sendLoginAutoCode(phone:string) {
        return this.sendAuthCode(SMS_AUTH_TYPE.USER_LOGIN_REGISTER,'/api/authCode/login/send',{phone})
    }
 	/**
     * 用户中心手机验证
	 * 需要前置登录
     */
    public sendUcCheckAutoCode() {
        return this.sendAuthCode(SMS_AUTH_TYPE.PHONE_VERIFY,'/api/authCode/uc/check/send',{});
    }
    /**
     * 绑定手机发送验证码
	 * 需要前置登录
     */
    public sendbindCheckAutoCode(phone:string) {
        return this.sendAuthCode(SMS_AUTH_TYPE.PHONE_VERIFY,'/api/authCode/bind/send',{phone});
    }
    /**
     * 找回密码发送验证码
     * @param {number} phone 这个参数为账号
     */
    public sendRetrieveAutoCode(phone:string) {
        return this.sendAuthCode(SMS_AUTH_TYPE.RETRIEVE_PWD,'/api/authCode/retrieve/send',{phone});
    }
}
