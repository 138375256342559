import {CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot} from "@angular/router";
import {Injectable} from "@angular/core";
import {AppConfig} from "./app-config.service";
import {LoginCacheService} from "../template/account/login/login-cache.service";

@Injectable()
export class HomeGuard implements CanActivate {


  constructor(private authService: LoginCacheService, private router: Router, public appConfig:AppConfig) {

  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {


    if (this.appConfig.agentId!=2335110&&this.appConfig.agentId!=2335111) {
      return true;
    }

    this.authService.backUrl = state.url;
    // if(state.url=='/home'){
      if(this.appConfig.agentId==2335110){
        setTimeout(()=> {
          window.location.href = "/#/hd/asian-cup?pay=1&agentId=2335110";
        },100);
      }else if(this.appConfig.agentId==2335111){
        setTimeout(()=> {
          window.location.href = "/#/hd/asian-cup?pay=2&agentId=2335111";
        },100);
      }
    // }
    return false;
  }
}
