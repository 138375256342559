import {Injectable, NgZone} from '@angular/core';
import {AlertService} from './alert.service';

/*
* 这个js作用为：
* 当动态加载js资源，出现错误时，进行动态刷新
* 主要是重新部署服务时，修改过的文件名会变
* 那样如果已经打开过页面，用户不刷新的话就会报错
* */


@Injectable()
export class ResourceLoadErrService {

    constructor(
            private alert: AlertService,
            private zone:NgZone,
            ) {
    }

    public addErrorListen() {

        this.zone.runOutsideAngular(()=>{
            window.addEventListener("error",(e)=> {
                // e.stopImmediatePropagation();
                const srcElement:any = e.srcElement;
                if (srcElement == window) {
                    // 全局错误
                    console.log("全局错误:", e.message);
                } else {
                    console.log("资源加载错误：", srcElement.tagName,srcElement.src);

                    //如果是遇到js资源加载错误
                    if(srcElement.tagName
                            && srcElement.tagName.toLowerCase() === 'script'
                            && /http.*-es\d{1,4}\..{20,20}\.js/.test(srcElement.src)
                    ) {
                        // console.log("重新加载页面");
                        // self.location.reload();
                        this.showError();
                    }

                }
            },true);
        })

    }

    private showError() {
        this.alert.confirm("当前网站已重新进行部署，为了您能正常访问，请刷新页面","立即刷新","稍后手动刷新",false).then(()=>{
            self.location.reload();
        }).catch(()=>{

        });
    }



}
