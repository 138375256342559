import {Injectable} from '@angular/core';
import {Subject} from "rxjs/Subject";

/**
 * 滚动区域观察者服务
 */

@Injectable()
export class ScrollTabAreaSubscribeService {

    public subject = new Subject();

	constructor() {}

	//重置滑动区域的高度
	public resizeTabHeight() {
	    setTimeout(()=>{
            this.subject.next("resizeHeight");
        })
    }

}
