import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from "@angular/router";
import {Injectable} from "@angular/core";
import {LoginCacheService} from "./login-cache.service";
import {AppConfig} from "../../../services/app-config.service";
import {ThirdLoginService} from "./third-login.service";

/**
 * 登录页面的守护路由
 * 登录前的拦截，进行第三方登录的处理
 */
@Injectable()
export class LoginGuard implements CanActivate {

  constructor(
    private loginCache: LoginCacheService,
    public config:AppConfig,
    public thirdLogin: ThirdLoginService,
  ) {

  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let backURL = route.paramMap.get("backUrl");
    if(!backURL)
      backURL = '/home';
    this.loginCache.backUrl = backURL;      //将backUrl存到缓存中

    //如果不是盈球平台 则运行第三方登录代码  && 不是体坛周报合作方华为平台
    if(!this.config.isJcob && !this.config.isTtHw&&!this.config.isVivoYdzx){

      this.thirdLogin.thirdLogin();

      return false;
    }
    return true;
  }
}
