
import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
/**用ModalController实现的弹出层效果,配套:service/alert.service.ts
 * 在content中需要点击事件的元素上添加alert-XXX的class,则会在回调的onDidDismiss方法返回值中带上{type:XXX}
 *       let alert = this.modalCtrl.create('AlertPage',{
            content:`<div class="bBtn mt20">
                            <a  class="bg_green alert-close">确认</a>
                            <a  class="bg_black alert-submit">取消</a>
                      </div>`});
            alert.onDidDismiss((data) => {
                if(data.type == "submit"){
                    //这里是点击确认按钮的逻辑
                }
                if(data.type == "close"{
                    //这里是关闭的逻辑
                }
            });
            alert.present({animate:false});
 */

@Component({
    template: `
        <style>
            .container{
             position:relative;
             width: 100%;
             height: 100%;
             background-color: rgba(0, 0, 0, 0);
            }
            .wrapper{
                position:absolute;
                left:50%;top:30%;
            }
           .content{
              position:relative;left:-50%;top:-50%;
              -webkit-animation-name: superScaleIn;
              animation-name: superScaleIn;
              -webkit-animation-duration: .2s;
              animation-duration: .2s;
              -webkit-animation-timing-function: ease-in-out;
              animation-timing-function: ease-in-out;
              -webkit-animation-fill-mode: both;
              animation-fill-mode: both;
            }
            @keyframes superScaleIn{from{transform:scale(1.2);opacity:0}to{transform:scale(1);opacity:1}}
        </style>
       <div class="container" (click)="close()" tappable>
           <div class="wrapper" [style.top.%]="top">
                <div class="content" [innerHTML] ="content" (click)="clickHandler($event)" tappable></div>
           </div>
       </div>`
})
export class AlertPage{
    @Input() content:String;
    @Input() top = 30;
    constructor(public modalCtrl:ModalController){
    
    }

    clickHandler($event){
        $event.stopPropagation();
        let reg = $event.target.className.match(/alert-(\S+)/);
        if(reg){
            this.modalCtrl.dismiss({type:reg[1]},null);
        }
    }

    close(){
        this.modalCtrl.dismiss({type:'close'},null);
    }

}
