import {CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot} from "@angular/router";
import {Injectable} from "@angular/core";
import {AppConfig} from "./app-config.service";
import {LoginCacheService} from "../template/account/login/login-cache.service";

/**
 * 对不同的平台做特殊的跳转处理
 */

@Injectable()
export class PlatformRedirectGuard implements CanActivate {


  constructor(
    private router: Router,
    public appConfig:AppConfig,
    public loginCache: LoginCacheService,
  ) {

  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if(!this.loginCache.isLogin()&&this.appConfig.isWechat){
      this.loginCache.goLoginPage(state.url);
      return false;
    }

    //如果是京东或推球
    if(this.appConfig.isJD || this.appConfig.isTq || this.appConfig.isCNB) {

      console.log(state.url);
      //进入用户中心 重定向
      if(state.url.indexOf("/tabs/user/center") != -1) {
        this.router.navigate(['/tabs/jd/user-center']);
        return false;
      }

    }


    return true;

  }
}
