import { Injectable }    from '@angular/core';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/timeout';
import {AppHttp} from "./app-http.service";

/**
 * 访问sportdata时用这里面的方法
 */
@Injectable()
export class SportdataHttp{

  constructor(public appHttp:AppHttp
             ) {
  }
  post(body?:any){
      let params:{} = {
          timestamp:new Date().getTime(),
          verifyStr:''
      };
      if(body){
          for(let name in body){
            if((body[name]&&this.getTypeOf(body[name]) != "Array")
              || (this.getTypeOf(body[name]) == "Array" && body[name].length > 0)
              || (body[name] === 0)
              || (body[name] === false)
                ) {
              params[name] = body[name];
            }
          }
      }
    return this.appHttp.sportDataPost('/sportdata/f',params);
  }

  private  getTypeOf(object) {
    switch (Object.prototype.toString.call(object)) {
      case '[object Array]':
        return 'Array';
      case '[object Boolean]':
        return 'Boolean';
      case '[object Function]':
        return 'Function';
      case '[object Null]':
        return 'Null';
      case '[object Number]':
        return 'Number';
      case '[object Object]':
        return 'Object';
      case '[object String]':
        return 'String';
      case '[object Undefined]':
        return 'Undefined';
      default:
        return 'Unknown';
    }
  }

}
