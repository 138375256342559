import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from "@angular/router";
import {AppConfig} from "./app-config.service";

/*
 * 路由守卫，当页面嵌入在iframe中时会跳转空白页
 */
@Injectable()
export class DataGuard implements CanActivate {

  constructor(public appConfig:AppConfig){

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (self != top || window.frames.length!==parent.frames.length){
      window.location.replace('/page/not-data.html');
      return false;
    }
    return true;
  }

}
