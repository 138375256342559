import {NgModule} from '@angular/core';
import {PhotoSwipeComponent} from "./photo-swipe.component";

@NgModule({
    declarations: [
        PhotoSwipeComponent,
    ],
    imports: [
    ],
    exports:[
        PhotoSwipeComponent,
    ]
})
export class PhotoSwipeModule {
}
