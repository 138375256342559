import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';


import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {CoreModule} from './core.module';
import {HttpClientModule} from '@angular/common/http';
import {AlertModule} from '../component/alert/alert.module';
import {PhotoSwipeModule} from '../component/photo-swipe/photo-swipe.module';
import {CaptchaValidateModule} from '../component/captcha/captcha-validate.module';
import {ViewModalComponent} from '../component/view-modal.component';
import {Alert} from '../component/alert.component';
import {Msg} from '../component/msg.component';

@NgModule({
  declarations: [
      AppComponent,
      Alert,
      Msg,
      ViewModalComponent
  ],
  entryComponents: [],
  imports: [

    IonicModule.forRoot({
        mode:'ios',
        animated:false
    }),
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    CoreModule,
    AlertModule,
      CaptchaValidateModule,
    PhotoSwipeModule,     //图片查看器组件
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {provide: LocationStrategy, useClass: HashLocationStrategy},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
