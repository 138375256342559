import {Component} from '@angular/core';
import {SetMealService} from './set-meal.service';
import {NavCtrlService} from '../../services/nav-controller.service';
import {AlertService} from '../../services/alert.service';
import {BaseType, ERROR_MSG, VIP_LEVEL, VipWealPricePrConst} from '../../model/const-config.model';
import {LoginCacheService} from '../account/login/login-cache.service';
import {AppConfig} from '../../services/app-config.service';
import {SessionStorageService} from '../../services/sessionStorage.service';
import {StorageService} from '../../services/storage.service';
import {ActivatedRoute} from '@angular/router';

/*套餐类型常量*/
export const SET_MEAL_SCOPE = {
  jc:new BaseType(1,"竞彩"),
  oldJc:new BaseType(2,"老足彩")
};

@Component({
    templateUrl: 'set-meal-list.html',
})
export class SetMealList {

    //套餐类型
    public scope;
    public SET_MEAL_SCOPE = SET_MEAL_SCOPE;

    // public title;
    // public content;

    //头部广告位
    public headAdImg;

    public mealList: any;      //套餐列表
    public expertList: any;    //专家列表
    public setMealCount: number = 0;   //套餐剩余次数
    public balance: number = 0;      //用户余额

    public params: any = {
        pageNo: 1,
        pageSize: 10,
    };

    public hasNext = false;
    public loading = false;     //加载中标志

    public isPayReminder: boolean = false; //是否显示购买红单会员提示
    public isfisrtClick: boolean = true; //是否是第一次点击订购=3次套餐

    public priceUnit = '金币';    //支付单位

    public redVipValue: number; //新版红单会员总价值
    public vipPrice: number; //新版红单会员价格
    public wealList = []; //权益信息
    public dataVipValue: number = VipWealPricePrConst.getDataVipSumPrice(); //数据会员总价值

    public chargeWay = 3;   //3支付宝wap 4微信wap 5微博weap  0或null金币

    private orderIdCacheKey = 'set_meal_pay_order_id';

    //套餐购买规则str
    public expireRule = '';

    constructor(public navCtrl: NavCtrlService,
                public appConfig: AppConfig,
                public alertService: AlertService,
                public setMealService: SetMealService,
                public loginCache: LoginCacheService,
                public session: SessionStorageService,
                public storage: StorageService,
                public route: ActivatedRoute,
                ) {

        this.scope = this.route.snapshot.paramMap.get("scope") || this.SET_MEAL_SCOPE.jc.index;
    }

    ngOnInit() {

        this.doRefresh();

        this.initChargeType();

    }

    ionViewWillEnter() {
        setTimeout(() => {
            this.checkPayStatus();
        }, 500);
    }

    //初始化支付方式
    initChargeType() {

        if (this.appConfig.isWB) {
            this.chargeWay = null;    //微博 暂时不需要直付
        } else if (this.appConfig.isWechat) {
            this.chargeWay = 4;    //微信，因为支付限额关系，暂时不需要直付
        } else {
            this.chargeWay = 3;
        }
    }

    //获取套餐列表数据
    getMealList() {

        this.loading = true;
        return this.setMealService.getSetMealList(this.scope, this.params.pageNo, this.params.pageSize).then((data) => {

            if (this.params.pageNo == 1)
                this.mealList = [];

            // this.title = data.title;
            // this.content = data.content;

            if (data.isSuccess && data.data) {

                this.mealList.push.apply(this.mealList, data.data);
                this.hasNext = data.hasNext;

            } else {
                this.hasNext = false;
            }
            this.loading = false;
            return data;
        }).catch((err) => {
            console.error(err);
            this.hasNext = false;
            this.loading = false;

            this.alertService.msg(err.msg || err.errorMsg);

            return err;
        });

    }

    //获取专家数据
    getExpertList() {

        return this.setMealService.getExpertList().then((data) => {

            if (data.isSuccess) {
                this.expertList = data.data;
            }

            return data;
        }).catch((err) => {

            return err;
        })

    }

    //获取现有套餐次数
    getSetMealCount(expertId?) {

        return new Promise((rs, rj) => {
            this.setMealService.getSetMealCount(expertId).then((data) => {

                if (data.isSuccess) {
                    if(this.scope == this.SET_MEAL_SCOPE.jc.index) {
                        this.setMealCount = data.jcResult.sum || 0;
                    }else {
                        this.setMealCount = data.sfcResult.sum || 0;
                    }
                    this.balance = data.balance;
                }

                rs(data);
            }).catch((err) => {
                console.error(err);

                rj(err);
            })
        });
    }

    //获取头部广告
    getHeadAd() {
        let adLocation = this.scope == this.SET_MEAL_SCOPE.jc.index ? 52 : 53;
        this.setMealService.getAdImg(adLocation).then((data)=>{
            if(data.isSuccess) {
                this.headAdImg = data.ad;
            }else {
                this.headAdImg = null;
            }
        }).catch(()=>{
            this.headAdImg = null;
        })
    }

    //购买套餐 请求
    payForSetMeal(item) {

        //如果余额不足并且可走直付，直接用直付
        if (this.ifPayWithDirect(item.discountAmount)) {
            this.directyPay(item.id);
            return;
        }

        this.loading = true;
        return this.setMealService.paySetMeal(item.id).then((data) => {

            if (data.isSuccess) {

                this.successTip();

            } else {

                if (data.errorCode == ' wallet.not.enough') {
                    this.balanceTip(data.balance, data.amount);
                } else {
                    this.alertService.msg(data.msg || '购买失败,请重试!');
                }
            }

            this.loading = false;

        }).catch((err) => {

            this.loading = false;

            this.alertService.msg(err.msg || err.errorMsg || '系统异常!');

        })
    }

    //余额不足提醒
    balanceTip(balance, amount) {
        this.failTip(balance, amount).then(() => {

            this.navCtrl.push('ChargePage');

        }).catch(() => {

        });
    }

    private getVipInfo() {
        //获取红单会员信息
        /*this.vipService.getVipWealConfig(VIP_LEVEL.red_vip.index).then((data) => {
            if (data.isSuccess) {
                this.vipPrice = data.vipPrice;
                this.wealList = data.wealList;
                this.redVipValue = VipWealPricePrConst.getSuperVipSumPrice(this.wealList[0].discountPriceSum, this.wealList[0].setMealPriceSum);
            }
        }).catch((res) => {
        });*/
    }

    //购买套餐
    getSetMeal(item) {

        if (this.loginCache.checkAndToLogin(self.location.href)) return;

        if (this.loading) {
            this.alertService.msg('请稍等...');
            return;
        }

        if (this.isfisrtClick && item.lookCount == 3 && this.appConfig.showVip && this.scope == this.SET_MEAL_SCOPE.jc.index) {
            //查询红单会员信息
            this.getVipInfo();

            //弹窗提醒订购红单会员
            this.isPayReminder = true;
            this.isfisrtClick = false; //下次则不是第一次了，不会进来弹窗提示
            return;
        }

        let expertId = null;
        if (item.pkgExpertType == 2) {
            expertId = item.pkgExpertId;
        }

        //先获取次数
        this.getSetMealCount(expertId).then((data) => {

            //余额不足，并且不需要直付，进行提示
            if (this.balance < item.discountAmount && !this.chargeWay) {

                this.balanceTip(this.balance, item.discountAmount);

            } else if (this.setMealCount > 0) {
                //还有次数
                this.hasCountWraning(this.setMealCount).then(() => {

                    setTimeout(() => {
                        this.buyWraning(item).then(() => {

                            this.payForSetMeal(item);

                        }).catch(() => {
                        });

                    }, 50);

                }).catch(() => {
                })

            } else {

                this.buyWraning(item).then(() => {

                    this.payForSetMeal(item);

                }).catch(() => {
                });

            }


        }).catch((err) => {

            if (err.errorCode != 'no_login')
                this.alertService.msg(err.msg || err.errorMsg);

        });

    }

    //是否使用直付
    ifPayWithDirect(price) {

        //微信支付 超过500的金额直接金币支付
        if (price > 500 && this.chargeWay == 4) return false;

        return this.balance < price && this.chargeWay;
    }

    //直接支付
    directyPay(pkgId) {
        //提交请求
        this.setMealService.preDualSetMealPay(this.chargeWay, pkgId, self.location.href).then((data) => {

            if (data.isSuccess) {

                //保存订单id，方便支付成功回来后查询订单状态
                if (data.id) {
                    this.session.set(this.orderIdCacheKey, data.id);

                    //微博存放在localStorage，因为支付成功回来后session没了
                    if (this.appConfig.isWB) {
                        this.storage.set(this.orderIdCacheKey, data.id);
                    }
                }

                //微博支付不同跳转
                if (this.chargeWay == 5) {
                    window.location.replace('sinaweibo://browser?url=' + encodeURIComponent(data.key));
                } else {
                    //跳转h5页面支付
                    self.location.replace(data.key);
                }
            } else {
                this.alertService.msg(data.msg || ERROR_MSG);
            }

        }).catch((res) => {
            console.log(res);
            this.alertService.msg(res.msg || ERROR_MSG);
        })

    }

    //检查支付状态
    checkPayStatus() {

        let id = this.session.get(this.orderIdCacheKey) || this.storage.get(this.orderIdCacheKey);
        if (id) {

            let status = this.route.snapshot.queryParamMap.get('status');
            //支付成功
            if (status == '2' || status == 'true' || !status) {

                this.setMealService.getPayStatus(id).then((payStatus) => {
                    //支付成功
                    if (payStatus == 2) {

                        this.successTip();
                        this.session.remove(this.orderIdCacheKey);
                        this.storage.remove(this.orderIdCacheKey);

                    } else {
                        this.chargeError();
                    }
                })

            } else {
                let errMsg = this.route.snapshot.queryParamMap.get('errMsg') || '支付异常，请联系客服！';
                this.alertService.msg(errMsg, 2000);
            }
        }

    }

    //成功提示
    successTip() {

        let content = `
     <div class="popupWrap">
        <div class="popupCon pt40 pb30 pl15 pr15">
          <p>恭喜您订购专家套餐成功</p>
          <div class="fs12 c999 mt15">赶紧去看专家解读吧</div>
        </div>
        <div class="popupBtn">
          <a class="border bdt bdr bdc_ddd alert-close">我知道了</a>
          <a class="border bdt bdr bdc_ddd orange alert-submit">去看解读</a>
        </div>
      </div>
    `;

        this.showWarning(content).then(() => {
            let params = {type:0};
            if(this.scope == this.SET_MEAL_SCOPE.oldJc.index) {
                params.type = 3;
            }
            this.navCtrl.push('JieduListPage',params);
        }).catch(() => {
        });
    }

    //支付查询中提示
    chargeError() {
        this.alertService.confirm('支付结果查询中，如您已支付成功，请过几分钟再来查看结果', '不再提示', '我知道了').then(() => {
            this.session.remove(this.orderIdCacheKey);
            this.storage.remove(this.orderIdCacheKey);
        });
    }

    //余额不足提示
    failTip(balance, amount) {

        let balanceTip = `<p class="mt10">您账户余额：<span class="red">${balance}</span>金币</p>`;
        if (!balance)            //特殊情况没有返回余额
            balanceTip = ``;

        let content = `
           <div class="popupWrap">
              <div class="popupCon pt30 pb30 pl15 pr15">
                <p>订购套餐需：<span class="red">${amount}</span>金币</p>
                ${balanceTip}
                <p class="mt15">金币余额不足，请先购买金币</p>
              </div>
              <div class="popupBtn">
                <a class="border bdt bdr bdc_ddd alert-close">取消</a>
                <a class="border bdt bdr bdc_ddd orange alert-submit">去买金币</a>
              </div>
            </div>
    `;

        return this.showWarning(content);
    }

    //购买套餐提示
    buyWraning(item) {

        let expireTime = '3个月';

        if (item.expireDays > 30) {

            let month = Math.floor(item.expireDays / 30);
            expireTime = `${month}个月`;
        } else
            expireTime = `${item.expireDays}天`;

        /*处理直付提示*/
        let payButText = '确认支付';
        let directPayTip = '';
        if (this.ifPayWithDirect(item.discountAmount)) {
            directPayTip = `<div class="fs12 c999 mt15">金币不足，请支付<span class="red">${item.discountAmount}</span>元，1元=1金币</div>`;
            if (this.chargeWay == 4) {
                payButText = '微信支付';
            } else if (this.chargeWay == 5) {
                payButText = '微博支付';
            } else {
                payButText = '支付宝支付';
            }
        }

        let content = `
        <div class="popupWrap">
          <div class="popupCon pt40 pb30 pl15 pr15">
            <p>您将支付<span class="red">${item.discountAmount}</span>金币订购${item.name}</p>
            <div class="fs12 c999 mt15">提示：本套餐自订购之日起<em class="red">${expireTime}内</em>有效</div>
            ${directPayTip}
          </div>
          <div class="popupBtn">
            <a class="border bdt bdr bdc_ddd alert-close">取消</a>
            <a class="border bdt bdr bdc_ddd orange alert-submit">${payButText}</a>
          </div>
        </div>
        `;

        return this.showWarning(content);
    }

    //已有套餐提示
    hasCountWraning(count) {

        let content = `
          <div class="popupWrap">
            <div class="popupCon pt30 pb30 pl15 pr15">
              <p>您订购的${this.scopeDesc}专家套餐，<br>还可查看<span class="red">${count}</span>次专家解读</p>
              <div class="fs12 c999 mt15">是否需要继续订购？</div>
            </div>
            <div class="popupBtn">
              <a class="border bdt bdr bdc_ddd alert-close">取消</a>
              <a class="border bdt bdr bdc_ddd orange alert-submit">继续订购</a>
            </div>
          </div>
        `;

        return this.showWarning(content);
    }

    //提示
    showWarning(content) {

        return new Promise((rs, rj) => {
            this.alertService.show(content).then((data: any) => {

                if (data.type == 'submit') {
                    rs();
                } else {
                    rj();
                }

            });
        })
    }

    //刷新
    doRefresh(e?,queryExpert=true) {

        this.params.pageNo = 1;
        this.hasNext = false;

        setTimeout(() => {      //使用setTimeout，防止请求在很短时间返回，从而导致页面闪烁

            if (this.loading) {
                this.alertService.loading(5000);
            }
        }, 300);

        this.getHeadAd();

        this.getMealList().then(() => {

            this.alertService.hideLoading();
            e && e.target.complete();

        }).catch(() => {

            e && e.target.complete();

            this.alertService.hideLoading();
        });

        queryExpert && this.getExpertList();

    }


    //加载更多
    loadMore(infiniteScroll) {

        this.params.pageNo++;

        this.getMealList().then(() => {
            infiniteScroll && infiniteScroll.target.complete();
        }).catch(() => {
            infiniteScroll && infiniteScroll.target.complete();
        })

    }

    //跳专家主页
    toExpertHome(id) {
        this.navCtrl.push('ExpertHomePage', {expertId: id});
    }

    //切换套餐类型
    public changeScope(scope) {

        if(this.scope == scope) return;

        this.scope = scope;
        this.doRefresh(null,false);
    }

    //获取专家套餐类型名
    get scopeDesc() {
        return this.scope == SET_MEAL_SCOPE.jc.index ? '足篮球' : '十四场';
    }
}
