import {Injectable} from '@angular/core';
import {AppHttp} from "../../services/app-http.service";
import {AlertService} from "../../services/alert.service";
import {RootService} from "../../services/root.service";
import {NavCtrlService} from "../../services/nav-controller.service";
import {PageRouterService} from "../../services/page-router.service";
import {MessageService} from "../message/shared/message.service";

/**
 * Generated class for the MsgSettingPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Injectable()
export class MsgService {
  public msgTick:any;
  public tickMessageTimer:any = 60000;
  messageNum=0;
  constructor(public http:AppHttp,public alert:AlertService,public stateService:PageRouterService,
              public root:RootService,
              public messageService: MessageService,
  ) {
  }

  private getMessageNum(){
    this.messageService.getMessageNum().then((data) => {
      // this.messageNum = data.commentNum + data.likeNum + data.followNum;
        this.messageNum = data.followNum;
    }).catch(()=>{
      this.messageNum = 0;
    });
  }

  tickMessageAjax() {

    this.getMessageNum();

    let that = this;
    if(!that.root.get('existMsg') && location.pathname.indexOf('home') !=-1){
      this.existNewMsgPoint().then((data)=>{

        let count = (data.count || 0) + this.messageNum;

        this.root.getRoot()['msgCount'] = count;
        this.root.getRoot()["existMsg"] = count > 0;

      }).catch((res)=>{});
    }
  }

  removeMsgTick(){
    if(this.msgTick){
      clearInterval(this.msgTick);
      this.msgTick = null;
    }
  }


  setMsgAjaxTick(){
    let that = this;
    if(that.msgTick){
      return;
    }
    that.msgTick = setInterval(()=>{
      that.tickMessageAjax();
    },that.tickMessageTimer);

    setTimeout(()=>{
      that.tickMessageAjax();
    },1);
  }

  existNewMsgPoint () {
    let msgTimeModule = JSON.parse(localStorage.getItem("msgTimeModule")) || {};
    let timeArray = [];
    for (let i in msgTimeModule) {
      timeArray.push(msgTimeModule[i]);
    }
    if(timeArray.length<6){
      let current =new Date().getTime();
      msgTimeModule['3']=current;
      msgTimeModule['4']=current;
      msgTimeModule['5']=current;
      msgTimeModule['6']=current;
      msgTimeModule['7']=current;
      msgTimeModule['8']=current;
      localStorage.setItem("msgTimeModule",JSON.stringify(msgTimeModule));
    }
    let ajax_array=[];
    for (let j in msgTimeModule) {
      ajax_array.push({id: j, timestamp: msgTimeModule[j]});
    }
    /*let req = {
      method: 'POST',
      url: ,
      data:ajax_array
    };*/
    return this.http.post('/api/msg/existNewMsg',ajax_array);
  }

  cancelAttention($params) {
    let that = this;
    return this.http.get('/api/msg/attention',$params).then(()=>{
      localStorage.setItem("newFollowUser",'1');
    }).catch((e)=>{
      if(e.errorCode!="no_token" && e.errorCode!='uuid_error'){
        that.alert.msg(e.errorMsg,1500);
      }
    });
  }
  existNewMsg(miniMsgTime) {
    return this.http.get('/api/msg/existNewMsg/' + miniMsgTime);
  }
  queryMsgCount(data) {
    return this.http.post('/api/msg/queryMsgCount',data);
  }
  queryMsg(commSubType, pageNo) {
    return this.http.get('/api/msg/queryMsg/' + commSubType + "/" + pageNo);
  }
  batchDisableMsg(data) {

    return this.http.post('/api/msg/batchDisableMsg',data);
  }
  queryMsgConfig() {
    return this.http.get('/api/msg/queryMsgConfig');
  }
  modifyMsgConfig(data) {
    return this.http.post('/api/msg/modifyMsgConfig',data);
  }
  updateSubMsgTime(subType, lastTimeinMills, forceUpdate) {
    let msgTimeModule = JSON.parse(localStorage.getItem("msgTimeModule")) || {};
    if (forceUpdate) {
      msgTimeModule[subType] = lastTimeinMills;
    } else {
      if (!msgTimeModule[subType]) {
        msgTimeModule[subType] = lastTimeinMills;
      }
    }
    localStorage.setItem('msgTimeModule', JSON.stringify(msgTimeModule));
  }
  updateMsgTime(obj) {
    localStorage.setItem('msgTimeModule', obj);
  }
  queryMsgTime() {
    return JSON.parse(localStorage.getItem("msgTimeModule")) || {};
  }
  /*queryMiniMsgTime() {
    let msgTimeModule = JSON.parse(localStorage.getItem("msgTimeModule")) || {};
    let timeArray = [];
    for (let i in msgTimeModule) {
      timeArray.push(msgTimeModule[i]);
    }
    return Math.min.apply(null, timeArray);
  }
  querySubMsgTime(subType) {
    let msgTimeModule = this.queryMsgTime();
    return msgTimeModule[subType];
  }*/
  getData($scope, pageNo,type,event) {
    let that = this;
    that.queryMsg($scope.commSubType, pageNo).then( (data)=> {
      event?event.target.complete():'';
      $scope.pageData = data.pageData;
      $scope.resHeader = data.resHeader;
      if(type ==1){
        $scope.msgList = data.pageData.dataList;
      }else{
        Array.prototype.push.apply($scope.msgList,data.pageData.dataList);
      }
      if (!$scope.msgList || $scope.msgList.length == 0) {
        setTimeout( ()=> {
          $scope.emptyMsg = true;
        }, 800);
      } else {
        if($scope.commSubType ===7){
          $scope.msgList.forEach( (e)=> {
            e.level = JSON.parse(e.content)['likeLevel'];
          });
        }
        $scope.emptyMsg = false;
      }
      that.updateSubMsgTime($scope.commSubType, data.lastTime, true);
    }).catch( (data)=> {
      event?event.target.complete():'';
      $scope.pageData.hasNext = false;
      that.alert.msg(data.errorMsg || '系统异常，请稍后再试!',1500);
    })
    /*.finally(function () {
      $scope.$broadcast('scroll.infiniteScrollComplete');
      $scope.$broadcast('scroll.refreshComplete');
    });*/
  }
  removeMsgByDeleteId($scope, _arrayDeleteId) {
    for (let j = 0; j < _arrayDeleteId.length; j++) {
      for (let z = 0; z < $scope.msgList.length; z++) {
        if ($scope.msgList[z].id == _arrayDeleteId[j]) {
          $scope.msgList.splice(z, 1);
        }
      }
    }
  }
  ajaxDelete($scope, _arrayDeleteId) {
    this.removeMsgByDeleteId($scope,_arrayDeleteId)
    this.batchDisableMsg(_arrayDeleteId).then( ()=> {
      _arrayDeleteId = [];
      $scope.deleteBean = {};
    }).catch(()=>{

    });
  }
  asynchronousAddMsgInfo(webSitId) {
    return this.http.get('/api/msg/asynchronousAddMsgInfo/'+webSitId);
  }
  redirectMsg(navCtrl:NavCtrlService,type,value,id){
    let that = this;
    if (!value)return;
    that.asynchronousAddMsgInfo(id);
    that.stateService.stateGo(navCtrl,type,value,value);
  }
  comfirmDelete($scope) {
    let that = this;
    let _arrayDeleteId = [];
    for (let i in $scope.deleteBean) {
      if ($scope.deleteBean[i] == true) {
        _arrayDeleteId.push(i);
      }
    }
    if (_arrayDeleteId.length == 0) {
      this.alert.msg('请选择需要删除的选项！',800);
      return;
    }
      this.alert.confirm("确认删除消息吗?").then( (res)=>{
      $scope.showDelete = false;
      that.ajaxDelete($scope, _arrayDeleteId);
    }).catch(()=>{

      })
  }


}
