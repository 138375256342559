import {Component} from '@angular/core';
import {AppConfig} from '../../services/app-config.service';
import {NavController} from '@ionic/angular';

/*
 * 首页适配
 * 因为推球和京东端，和其他端首页不同
 * 所以在这里做适配跳转到对应首页
 * */
@Component({
    template: ' ',
})
export class HomeFit {

    constructor(public navCtrl: NavController,
                public appConfig: AppConfig,
    ) {

    }

    ngOnInit() {
        let url = location.href;

        if (this.appConfig.isJD || this.appConfig.isTq) {

            url = url.replace('#/home', '#/home-jd');

        } else if(this.appConfig.isCNB){
            // url = url.replace('#/home','#/home/jd');
            url = url.replace('#/home', '#/tabs/plan');
        } else {
            url = url.replace('#/home', '#/tabs/home');
        }
        location.replace(url);
    }
}
