/**
 * Created by hh on 2017/7/29.
 */
import {Injectable} from '@angular/core';
import {RootService} from "../../../services/root.service";
import {AppHttp} from "../../../services/app-http.service";

declare var Jcob:any;
declare var encryptPwdUtil:any;

@Injectable()
export class UserMoneyService {

    constructor(public http: AppHttp,private rootService:RootService) {

    }//查询充值说明信息
  queryChargeInfo(){
    return this.http.get("/api/charge/expenseInfo/");
  }
  /*cbChargeInfo(){
    return this.http.get("/api/charge/cb/expenseInfo/");
  }*/
  //移除充值绑定
  removeMemberChargeWay(id){
    return this.http.get("/api/user/charge/removeMemberChargeWay/",{params:{id:id}});
  }

  //查询提款用户信息
  queryMemberBank(type=1){
    return this.http.get("/api/user/queryMemberBank/"+type);
  }
  //查询用户金币余额
  queryMemberHuoyanWallet(){
    return this.http.get("/api/user/queryMemberHuoyanWallet");
  }
  //查询城市
  findCitysByProvinceId(provinceId){
    return this.http.get("/api/findCitysByProvinceId/",{params:{provinceId:provinceId}});
  }
  //查询支行
  findParkBank(cityId,bankId){
    return this.http.get("/api/findParkBank/",{params:{cityId:cityId,bankId:bankId}});
  }
  //提款短信通知
/*  sendMessager(){
    return this.http.get("/api/user/drawMoney/sendMessager/");
  }*/
  //新卡提款
  newDrawMoney(bankCode,bank,bankCard,province,city,partBank,amount,code,drawMoneyType=1){
    return this.http.post("/api/user/memberDrawMoney/",
      {bankCode:bankCode,bank:bank,bankCard:bankCard,province:province,city:city,partBank:partBank,amount:amount,code:code,drawMoneyType});
  }
  //已有卡提款
  drawMoney(drawMoneyWayId,amount,code,drawMoneyType=1){
    return this.http.post("/api/user/memberDrawMoney/",{drawMoneyWayId:drawMoneyWayId,amount:amount,code:code,drawMoneyType});
  }
  //解除绑定
  deleteMemberDrawMoneyWay(id){
    return this.http.get("/api/user/deleteMemberDrawMoneyWay/"+id);
  }
  //提款明细
  queryDrawMoneyLogDetail(id){
    return this.http.get("/api/user/queryDrawMoneyLogDetail/"+id);
  }
  //钱包列表
  queryMemberWalletList(day,pageNo){
    return this.http.get("/api/user/queryMemberMoneyList/"+day+"/"+pageNo);
  }
  //火眼列表
  queryHuoyanWalletList(day,pageNo){
    return this.http.get("/api/user/queryHuoyanWalletList/"+day+"/"+pageNo);
  }
  //通过解读id查询解读类型
  queryJdTypeById(id) {
      return this.http.get("/api/user/queryJdTypeById/"+id);
  }
  //猜贝列表
  /*queryCaibeiWalletList(day,pageNo){
    return this.http.get("/api/user/queryCaibeiWalletList/"+day+"/"+pageNo);
  }
  //支付宝H5充值请求
  wapAlipayCharge(productId,amount,amountType,chargeCouponId?){
    return this.http.post("/api/user/wapAlipayCharge", {productId:productId,amount:amount,amountType:amountType,chargeCouponId});
  }
  //微信充值功能
  wapWechatCharge(productId,amount,amountType,chargeCouponId?){
    return this.http.post("/api/user/wapWechatCharge/",{productId:productId,amount:amount,amountType:amountType,chargeCouponId});
  }
  //yee微信充值功能
  yeeWechatCharge(productId,amount,amountType,chargeCouponId?){
    return this.http.post("/api/user/yeeCharge/",{productId:productId,amount:amount,amountType:amountType,chargeCouponId});
  }
  //微信wap-h5支付
  h5wechatCharge(productId,amount,chargeCouponId?){
    return this.http.post("/api/user/h5WechatCharge",{productId:productId,amount:amount,chargeCouponId});
  }*/
  //连连充值请求
  wapLlCharge(productId,amount,bankCard,amountType,chargeCouponId?){
    return this.http.post("/api/user/wapLlCharge/",{productId:productId,amount:amount,bankCard:bankCard,amountType:amountType,chargeCouponId});
  }
  //查询免费券
  queryCouponList(pageNo,status){
    return this.http.get("/api/query/couponList",{params:{pageNo:pageNo,status:status}});
  }
  //直付查单
  payStatus(playType, planNo){
    return this.http.post("/api/checkPayComplete/",{playType:playType,planNo:planNo});
  }

  //查询免费券
  queryCouponNum(){
    return this.http.get("/api/query/couponNum");
  }

  //微博充值请求
  wapWeiboCharge(productId,amount,amountType,chargeCouponId?){
    return this.http.post("/api/user/wapWeiboCharge/",{productId:productId,amount:amount,amountType:amountType,chargeCouponId});
  }

  //微博直付功能
  weiboDerectPay(playType, planId, planNo,feeId?){
    let returnUrl = encodeURIComponent(location.href.split("?")[0]);
    return this.http.post("/api/preDualWeiboPay/",{playType:playType,planId:planId,planNo:planNo,returnUrl:returnUrl,feeId});
  }
  //京东直付功能 currency 币种 默认是1
 /* jdDerectPay(playType, planId, planNo,currency=1){
    let returnUrl = encodeURIComponent(location.href.split("?")[0]);
    return this.http.post("/api/preDualJdPay/",{playType,planId,planNo,returnUrl,currency});
  }*/

  //支付宝支付
  h5DerectPay(playType, planId, planNo,feeId?){
    let returnUrl = encodeURIComponent(location.href.split("?")[0]);
    return this.http.post("/api/preDualH5Pay/",{playType:playType,planId:planId,planNo:planNo,returnUrl:returnUrl,feeId});
  }


  //查询推球余额
 /* checkTqBalance(amount){
    return this.http.get("/api/tq/checkBalance?amount="+amount);
  }

  //查询推球支付方式
  queryTqPayType(){
    return this.http.get("/api/tq/queryPayType");
  }

  //推球余额支付
  tqBalanceDerectPay(playType, planId, planNo,feeId?){
    return this.http.post("/api/tqBalancePreDualPay",{playType:playType,planId:planId,planNo:planNo,feeId});
  }
  //推球支付
  tqDerectPay(playType, planId, planNo,feeId?,tqChargeType?){
    let returnUrl = encodeURIComponent(location.href.split("?")[0]);
    return this.http.post("/api/tqPreDualPay",{playType:playType,planId:planId,planNo:planNo,returnUrl:returnUrl,feeId,tqChargeType:tqChargeType})
  }*/

  //查询充值方式配置
  queryChargeType(){
    return this.http.get("/api/user/queryChargeConfig");
  }

  //充值请求
  newCharge(chargeWay,productId,amount,amountType,chargeCouponId,bankCard?){
    return this.http.post("/api/user/new/charge",{chargeWayIndex:chargeWay,productId:productId,amount:amount,bankCard:bankCard,amountType:amountType,chargeCouponId});
  }

}
