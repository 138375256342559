import {Injectable} from '@angular/core';
import {AppHttp} from "../../services/app-http.service";
import {EncryptService} from "../../services/encrypt.service";

@Injectable()
export class LoginRegisterService {


    constructor(public http: AppHttp, public encrypt: EncryptService,) {

    }

    login(account, password) {
        return this.http.post("/api/h5/login/", {account: account, password: this.encrypt.encrypt(password)});
    }

    //6.7.0新加手机号登录
    //shareId 合伙人分享id，用户合伙人推荐
    phoneLogin(phone, code, share?) {
        return this.http.post("/api/phone/login/", {phone, code, share});
    }

    wbLogin(callbackUrl, share?) {
        let url = "/api/weibo/h5/login?callbackUrl=" + encodeURIComponent(callbackUrl);

        if (share)
            url += '&share=' + share;

        return this.http.get(url);
    }

    /*register(registerType, phone, password, code, nickName, share?) {
        return this.http.post("/api/register/", {
            registerType: registerType,
            phone: phone,
            password: this.encrypt.encrypt(password),
            code: code,
            nickName: nickName,
            share
        });
    }*/
    newRegister(registerType,phone,password,code,share?){
        return this.http.post("/api/register/",{registerType:registerType,phone:phone,password:this.encrypt.encrypt(password),code:code,share});
    }

    retrieveValidCode(phone, code, account) {
        return this.http.post("/api/retrieve/validCode/", {phone: phone, code: code, account});
    }

    retrievePassword(account, phone, password, code) {
        return this.http.post("/api/retrieve/resetPassword/", {
            account: account,
            phone: phone,
            password: this.encrypt.encrypt(password),
            code: code
        });
    }

    tqLogin(uid, sign) {
        return this.http.post("/api/tq/login", {uid: uid, sign: sign});
    }

    getTqLoginUrl() {
        let url = window.location.href;
        url = url.replace("uid=", "");
        url = url.replace("sign=", "");
        return this.http.get("/api/tq/getLoginUrl?redirectUrl=" + encodeURIComponent(url));
    }

    //获取登录验证code
    getValidateCode() {
        return this.http.post("/api/ajaxAddLoginCode", {});
    }

    /**
     * 判断是否登录
     * 访问interface，防止token过期
     * @returns {boolean}
     */
     async checkLogin() {
         return await this.http.get("/api/login/check");
     }
}
